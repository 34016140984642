import React from "react";
import { Input } from "../../hooks/Input";

export const TimerDialog = ({ unitId, waterOffTm, waterOffTmMin, waterOnTm, waterOnTmMin }) => {
    return <div>
        <div className="ph-dialog" draggable="false">
            <div className="row-ph-dialog ph-level-row">
                <p>Полив (мин.)</p>
                <Input
                    value={waterOnTm}
                    min={waterOnTmMin}
                    mode={1}
                    deviceId={unitId}
                    ckey={'waterOnTm'}

                />
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>Пауза (чч:мм)</p>
                <Input
                    value={waterOffTm}
                    min={waterOffTmMin}
                    mode={1}
                    deviceId={unitId}
                    ckey={'waterOffTm'}
                    time={true}
                />
            </div>
        </div>
    </div>
}
