import React from 'react';
import { ReactComponent as DialSvg } from '../../../../resources/images/dial.svg';
import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';

export const TimerButton = ({ handleClick, timerPageTitle }) => {

    return <div
        style={{
            color: '#fff',
            fontSize: '55px',
        }}
        onClick={handleClick}
        className='widget-button'
    >
        <Tooltip
            placement="top"
            title={timerPageTitle?.title}
        >
            <Icon component={DialSvg} />
        </Tooltip>
    </div>
}