import React, {useEffect, useState} from 'react';
import {Input} from "../../hooks/Input";

const TimerCO2 = ({unitId, timeStart, timeStop}) => {

    const [disabled, setDisabled] = useState(false)
    const [update, setUpdate] = useState(true)

    useEffect(() => {
        setDisabled(false)
        setUpdate(!update)
    }, [timeStart, timeStop])


    return (
        <div className="ph-dialog" draggable="false">
            <div className="row-ph-dialog ph-level-row">
                <p>Начало подачи</p>
                <Input value={timeStart} min={0} max={86340} mode={60}
                       disabled={disabled} format={"HH:MM"} setDisabled={setDisabled}
                       deviceId={unitId} ckey={'timeStart'} time={true} update={update} secondValue={timeStop}/>
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>Конец подачи</p>
                <Input value={timeStop} min={0} max={86340} mode={60} secondValue={timeStart}
                       deviceId={unitId} ckey={'timeStop'} disabled={disabled} setDisabled={setDisabled} update={update}
                       time={true} format={"HH:MM"}/>
            </div>
            <div className="row-ph-dialog ph-level-row">

            </div>
        </div>
    );
};

export default TimerCO2;