import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { DesktopOrTabletScreen, MobileScreen } from './Responsive';
import { buildMobileButtons } from "./Utils";

const { Search } = Input;


const ModuleHeader = (props) => {
    // const showBackButton = props.showBackButton;
    const showButtonsInMobile = props.showButtonsInMobile;

    return (
        <div className="mod-header">
            <h2 className="mod-title">{props.title}</h2>
            <div style={{ marginLeft: 'auto' }}></div>
            <div>{props.children}</div>
            {props.search ? <Search placeholder="Поиск..." allowClear onSearch={props.onSearch} className="search" /> : null}
            <DesktopOrTabletScreen>
                <div className="mod-buttons">
                    {props.buttons}
                </div>
            </DesktopOrTabletScreen>
            {showButtonsInMobile &&
                <MobileScreen>
                    <div className="mod-buttons">
                        {buildMobileButtons(props.buttons, true)}
                    </div>
                </MobileScreen>
            }
        </div>
    );
}

ModuleHeader.propTypes = {
    search: PropTypes.bool,
    //showBackButton: PropTypes.bool,
    showButtonsInMobile: PropTypes.bool
}

ModuleHeader.defaultProps = {
    search: true,
    //showBackButton: true,
    showButtonsInMobile: false
}

export default ModuleHeader;