import React from 'react';
import { ReactComponent as ASvg } from '../../../../resources/images/regulatorStateA.svg';
import { ReactComponent as OnSvg } from '../../../../resources/images/onWithoutColor.svg';
import { ReactComponent as OffSvg } from '../../../../resources/images/offWithoutColor.svg';
import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';

export const RegulatorStateButton = ({ handleClick, phIsOn, trueCrashes, accidentCodes, phIsOnTitle }) => {
    const [isAlarm, setIsAlarm] = React.useState(false)

    React.useEffect(() => {
        setIsAlarm(defAlarm())
    }, [trueCrashes])


    function defAlarm() {
        for (let i = 0; i < trueCrashes.length; i++) {
            if (accidentCodes.includes(trueCrashes[i].name)) {
                return true
            }
        }
        return false
    }

    return <div
        style={{
            color: isAlarm ? '#FF0000' : '#666',
            fontSize: '40px',
        }}
        onClick={handleClick}
        className='widget-button'
    >
        <Tooltip
            placement="topLeft"
            title={phIsOnTitle?.title}
        >
            {
                isAlarm
                    ? <Icon component={ASvg} />
                    : phIsOn == true
                        ? <Icon component={OnSvg} />
                        : phIsOn == false
                            ? <Icon component={OffSvg} />
                            : null
            }
        </Tooltip>
    </div>
}