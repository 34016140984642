import arrow from "../../../../../resources/images/Arrow-right.svg";
import React from "react";
import {ShowDialog} from "../ShowDialog";
import {PhLevel} from "./PhLevel";
import {Input} from "../../../hooks/Input";
import {HookSwitch} from "../../../hooks/HookSwitch";

export const PhDialog = ({
                             deviceId, widgetName, phLower, isAlarm,
                             phUpper, phIsProtected, phCycleTm,
                             phIsOn, phCycleTmMin, phCycleTmMax,
                             phLowerMax, phLowerMin, phUpperMax, phUpperMin, unitId
                         }) => {


    const [dialogs, changeDialog] = React.useState({
        phLevel: false
    });

    // useEffect(() => {
    //     if(phIsOn !== valuePhIsOn){
    //         setValuePhIsOn(phIsOn)
    //     }
    //     if(phIsProtected !== valuePhIsProtected){
    //         setValuePhIsProtected(phIsProtected)
    //     }
    // }, [phIsProtected, phIsOn])

    const changePage = (value, option) => {

        switch (value) {
            case "phLevel":
                changeDialog({...dialogs, phLevel: option})
                break
            default:
                changeDialog({
                    mode: false
                })
        }

    }

    return (
        <div className="ph-dialog" draggable="false">
            <div className="row-ph-dialog ph-level-row">
                <p>Пауза дозирования</p>
                <Input value={phCycleTm} min={phCycleTmMin} max={phCycleTmMax}
                       mode={1} deviceId={unitId} ckey={'phCycleTm'}/>
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>Регулятор рН</p>
                <HookSwitch value={phIsOn} unitId={unitId} name={'phIsOn'}/>
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>Защита</p>
                <HookSwitch value={phIsProtected} unitId={unitId} name={'phIsProtected'}/>
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>рН раствора</p>
                <div className="level-ph">
                    <p>
                        {`${Math.floor((phLower) * 10) / 10 || "-"} - ${Math.floor((phUpper) * 10) / 10 || "-"}`}
                    </p>
                    <button className="button-arrow">
                        <div className="logo-widget">
                            <img onClick={() => changePage('phLevel', true)} src={arrow} alt="arrow"/>
                        </div>
                    </button>
                </div>
            </div>
            {dialogs.phLevel ?
                <ShowDialog
                    isAlarm={isAlarm}
                    title={"рН раствора"}
                    number={deviceId}
                    widgetName={widgetName}
                    value={"phLevel"}
                    changePage={changePage}
                    component={<PhLevel phLower={phLower}
                                        phUpper={phUpper}
                                        phLowerMax={phLowerMax}
                                        phLowerMin={phLowerMin}
                                        phUpperMax={phUpperMax}
                                        phUpperMin={phUpperMin}
                                        unitId={unitId}
                    />}/>
                : null}

        </div>
    )
}