import { Tooltip } from 'antd';
import React from 'react';

const WATER_MODES = {
    0: "ВЫКЛ",
    1: "ИДЁТ",
    2: "ПАУЗА",
    4: "ИДЁТ",
}

export const WateringButton = ({ handleClick, waterMode, alarmE102, waterModeTitle }) => {
    let color = "#FFF";

    // на основании waterMode и alarmE102 выбирается цвет текста. Ошибок может быть больше
    if (waterMode == 0
        || (alarmE102 && [1, 2, 4].includes(waterMode))
        || !WATER_MODES[waterMode]) {
        color = "#FFFF00";
    }
    else if (!alarmE102 && [1, 4].includes(waterMode)) {
        color = "#7F7F7F";
    }
    else if (!alarmE102 && waterMode == 2) {
        color = "#00FA9E";
    }

    return <div
        onClick={handleClick}
        className="record-box"
        style={{
            justifyContent: 'center',
            padding: 0,
            alignItems: 'center',
            height: '100%',
        }}
    >
        <Tooltip
            placement="topRight"
            title={waterModeTitle?.title}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <p
                    style={{
                        fontSize: 40,
                        fontWeight: 'bold',
                        margin: 0,
                        lineHeight: '47px',
                    }}
                >
                    ПОЛИВ
                </p>
                <p
                    style={{
                        fontSize: 36,
                        fontWeight: 'bold',
                        margin: 0,
                        lineHeight: '47px',
                        color: color,
                    }}
                >
                    {WATER_MODES[waterMode] ?? "АВАРИЯ"}
                </p>
            </div>
        </Tooltip>
    </div>
}