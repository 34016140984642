import React, { useEffect, useState } from 'react'
import { Tooltip } from "antd";
import grayBottle from "../../../resources/images/gray_bottle.svg";
import greenBottle from "../../../resources/images/green_bottle.svg";
import redBottle from "../../../resources/images/red_bottle.svg";

const DEFAULT_BOTTLE_HEIGHT = 55;
const DEFAULT_BOTTLE_WIDTH = 26;

export const Beaker = ({ canvasName, value, text, curState, title, bottleHeight, bottleWidth }) => {

    const [image, setImage] = useState(null)

    useEffect(() => {
        createCanvas()
    }, [curState])

    function createCanvas() {

        let color;
        switch (curState) {
            case "010":
            case "000":
            case "110":
                color = "#666666"
                value = 100
                setImage(
                    <img
                        style={{ width: bottleWidth ?? DEFAULT_BOTTLE_WIDTH, height: bottleHeight ?? DEFAULT_BOTTLE_HEIGHT }}
                        src={grayBottle}
                    />
                )
                break
            case "111":
            case "101":
            case "011":
            case "001":
                color = "#00FA9E"
                value = 100
                setImage(
                    <img
                        style={{ width: bottleWidth ?? DEFAULT_BOTTLE_WIDTH, height: bottleHeight ?? DEFAULT_BOTTLE_HEIGHT }}
                        src={greenBottle}
                    />
                )
                break

            default:
                color = "#FF0000"
                value = 20
                setImage(
                    <img
                        style={{ width: bottleWidth ?? DEFAULT_BOTTLE_WIDTH, height: bottleHeight ?? DEFAULT_BOTTLE_HEIGHT }}
                        src={redBottle}
                    />
                )
                break
        }


        //     function draw() {
        //
        //         ctx.strokeStyle = color
        //         // мензурка
        //         ctx.beginPath()
        //         ctx.moveTo(1, 15)
        //         ctx.lineTo(1,54)
        //         ctx.lineTo(25,54)
        //         ctx.lineTo(25,15)
        //         ctx.lineTo(18,7)
        //         ctx.lineTo(9,7)
        //         ctx.closePath()
        //         ctx.lineWidth = 2
        //         ctx.stroke()
        //
        //         // крышка
        //         ctx.beginPath()
        //         ctx.moveTo(8, 1)
        //         ctx.lineTo(19, 1)
        //         ctx.lineTo(19, 6)
        //         ctx.lineTo(8, 6)
        //         ctx.closePath()
        //         ctx.fillStyle = color
        //         ctx.fill()
        //         ctx.stroke()
        //     }
        //     function fillValue() {
        //         let y = 51 - 0.34 * value
        //         let height = value * 0.34
        //         ctx.fillStyle = color
        //         ctx.fillRect(4, y, 18, height)
        //         ctx.stroke()
        //     }
        //     draw();
        //     fillValue()
        // }
    }

    return (
        <div>
            <Tooltip placement="top" title={title}>
                <div>
                    {image}
                    {/*<canvas id={canvasName} width="26" height="55"/>*/}
                </div>
                <p className="beaker-text">{text}</p>
            </Tooltip>
        </div>
    )
}
