import React, {useEffect, useState} from 'react';
import {LineChart, Line, XAxis, YAxis, Tooltip} from 'recharts';
import {CloseOutlined, CloseSquareOutlined} from "@ant-design/icons";
import {notification, Select, Spin, Tooltip as AntdTooltip} from "antd";
import requestToAPI from "../../lib/Request";

import {
    MUT_UPD_SETTINGS_WIDGET,
    QUERY_GET_HISTORY,
    QUERY_GET_SETTINGS_WIDGET,
    QUERY_GET_DEVICE_MONITOR
} from "../../lib/Query";
import {MSG_REQUEST_ERROR} from "../../lib/Const";
import moment from "moment";
import {add, format, differenceInCalendarDays, differenceInHours} from "date-fns";
import CustomTooltip from "./CustomTooltip";
import {colors} from "./Signals";
import {debounce} from "../Debounce";
import {tagRender} from "./TagRender";
import {subscribe} from "../../lib/RequestWS";
import {DataChange} from "./dialogsStatWidget/DataChange";
import {ShowDialogStatWidget} from "./dialogsStatWidget/ShowDialogStatWidget";


// максимальное значение счетчика обновления графика
const MAX_WS_UPDATE_COUNTER = 10;
const UPDATE_TIMEOUT = 60000; // 1 min
const DEFAULT_TIME_MODE = 72; // 72 часа, 3 дня
const TIME_MODES = [
    {
        id: 1,
        label: "1 час",
        description: "Начиная с минус 1 час от текущего момента"
    },
    {
        id: 3,
        label: "3 часа",
        description: "Начиная с минус 3 часа от текущего момента"
    },
    {
        id: 6,
        label: "6 часов",
        description: "Начиная с минус 6 часов от текущего момента"
    },
    {
        id: 12,
        label: "12 часов",
        description: "Начиная с минус 12 часов от текущего момента"
    },
    {
        id: 24,
        label: "1 сутки",
        description: "Начиная с минус 24 часов от текущего момента"
    },
    {
        id: 72,
        label: "3 суток",
        description: "Начиная с минус 3 суток от текущего момента"
    },
    {
        id: 168,
        label: "1 неделя",
        description: "Начиная с минус 7 суток от текущего момента"
    },
    {
        id: 336,
        label: "2 недели",
        description: "Начиная с минус 14 суток от текущего момента"
    },
    {
        id: 720,
        label: "1 месяц",
        description: "Начиная с минус 30 суток от текущего момента"
    }
]
// разница времени между точками в секундах, которую принимаем за обрыв соединения с прибором
const DEVICE_DOWNTIME = 15

export const StatWidget = ({widgetId, deviceName, deviceId, closeWidget, widget, signals}) => {
    
    const [dataGraphics, setDataGraphics] = useState([])
    const [ticks, setTicks] = useState([])
    const [domain, setDomain] = useState(['auto', 'auto'])
    const [contextParams] = React.useState({})
    const [optionsSelected, setOptionsSelected] = useState([]);
    const [date, setDate] = useState([])

    const [isFetching, setIsFething] = useState(false)
    const [allData, setAllData] = useState([])

    const [timeMode] = React.useState({ mode: DEFAULT_TIME_MODE })


    const [dialogs, changeDialog] = React.useState({
        data: false,
    });

    const changePage = (value, option) => {
        switch (value) {
            case "data":
                changeDialog({...dialogs, data: option})
                break
            default:
                changeDialog({
                    data: false
                })
        }

    }
    

    // прямой запрос данных
    const refreshData = ()=>{
        console.log("timeout refresh data")

        if (TIME_MODES.some(tm => tm.id === timeMode.mode)){
            const now = new Date()
            let startDate = new Date(now)
            startDate = new Date(startDate.setHours(startDate.getHours()-timeMode.mode))
            setDate([moment(startDate).format('yyyy-MM-DDTHH:mm:ss'), moment(now).format('yyyy-MM-DDTHH:mm:ss')])
        }

        requestToAPI.post(QUERY_GET_DEVICE_MONITOR, {
            id: deviceId,
        }).then((res) => {
            updateData(res.data.record)
        });    
    }
    // устанавливаем таймер в первый раз
    if(!contextParams.updateTimerId) {
        contextParams.updateTimerId = setTimeout(refreshData,UPDATE_TIMEOUT)
    }
    // обновленеи данных, счетчиков и таймера
    const updateData = (new_data) => {
        contextParams.wscounter = 0;
        if(contextParams.updateTimerId) {
            clearTimeout(contextParams.updateTimerId);
            delete contextParams.updateTimerId;
        }
        if(TIME_MODES.some(tm => tm.id === timeMode.mode)) {
            const now = new Date();
            now.setMilliseconds(0);
            const startPeriod = new Date(now);
            startPeriod.setHours(now.getHours() - timeMode.mode);
            setDate([startPeriod.toISOString(), now.toISOString()]);
        }
        contextParams.updateTimerId = setTimeout(refreshData,UPDATE_TIMEOUT)
        setAllData(new_data.monitor);        
    }

    useEffect(() => {
        contextParams.mountFlag = true;
        requestToAPI.post(QUERY_GET_SETTINGS_WIDGET, {
            id: widgetId,
        }).then((res) => {

            let options = res.data.dashboardWidgetByPk.setting.filters
            setOptionsSelected(options)

            let firstDate;
            let secondDate;

            if (res.data.dashboardWidgetByPk.setting.end && res.data.dashboardWidgetByPk.setting.beg) {
                firstDate = res.data.dashboardWidgetByPk.setting.beg
                secondDate = res.data.dashboardWidgetByPk.setting.end
                const diff = moment(secondDate).diff(moment(firstDate)) / (1000 * 60 * 60)
                if (TIME_MODES.some(tm => tm.id === diff)){
                    timeMode.mode = diff
                    const now = new Date()
                    secondDate = new Date(now)
                    firstDate = new Date(now.setHours(now.getHours()-diff))
                }
                else {
                    timeMode.mode = -1
                }
            } else {
                firstDate = moment().subtract(DEFAULT_TIME_MODE, 'hours')
                secondDate = moment()
                timeMode.mode = DEFAULT_TIME_MODE
            }
            setDate([firstDate, secondDate])
            getData([firstDate, secondDate], options)

            setIsFething(true)

        }).catch(error => {
            console.error(error);
            throw error;
        })


    }, [])

    useEffect(() => {
        // Вебсокет отправляющий сигналы об изменении данных
        if (requestToAPI.authToken) {
            subscribe(
                `subscription { 
                    deviceUnitByPk(id: ${deviceId}) { 
                        monitor { 
                            updated
                            key
                            value 
                        }
                        model {
                          metadata
                        }
                    } 
                }`,
                (payload) => {
                    const new_data = payload.data.deviceUnitByPk;
                    /* Правило обновления:
                       - Либо через каждые MAX_WS_UPDATE_COUNTER раз 
                       - Либо по истечении UPDATE_TIMEOT сек
                       - либо первый раз (!contextParams.wscounter===undefined)
                    */
                    if (new_data && (contextParams.wscounter===undefined || contextParams.wscounter>=MAX_WS_UPDATE_COUNTER)) {
                        console.log("counter refresh data",contextParams.wscounter)
                        updateData(new_data);
                    };
                    // счетчик запросов
                    contextParams.wscounter++;
                }
            )
        }
    }, [])

    useEffect(() => {
        if (TIME_MODES.some(tm => tm.id === timeMode.mode) || (date[1] && date[1] > new Date())){
            getData([date[0], date[1]], optionsSelected)
        }
    }, [allData])


    function setFilterSettings(beg, end, filters) {
        requestToAPI.post(MUT_UPD_SETTINGS_WIDGET, {
            id: widgetId,
            beg: beg,
            end: end,
            filters: filters,
            pos: widget.setting.pos
        }).then((res) => {
        }).catch(error => {
            console.error(error);
            throw error;
        })
    }

    function resetDate() {
        timeMode.mode = DEFAULT_TIME_MODE
        setDate([moment().subtract(DEFAULT_TIME_MODE, 'hours'), moment()])
        setFilterSettings('', '', optionsSelected)
        getData([moment().subtract(DEFAULT_TIME_MODE, 'hours'), moment()])
    }

    function applyDataChange(firstDate, secondDate, newMode) {
        timeMode.mode = newMode
        setDate([firstDate, secondDate])
        setFilterSettings(firstDate, secondDate, optionsSelected)
        getData([firstDate, secondDate])
    }

    async function getData(data, options = optionsSelected) {

        if (data[0] === '' || data[1] === '' || options.length === 0) {
            return
        }
        let firstDate = new Date(data[0])
        let secondDate = new Date(data[1])
        const requests = options.concat('heartBeat');

        await Promise.all(requests.map(item => {
            return requestToAPI.post(QUERY_GET_HISTORY, {
                unit: deviceId,
                beg: firstDate.toISOString(),
                end: secondDate.toISOString(),
                key: item
            })
        })).then((result) => {
            changeData(result, firstDate, secondDate, options)
        }).catch((error) => {
            notification.error({
                message: MSG_REQUEST_ERROR,
                description: error.message
            })
        })
    }

    function changeData(data, firstDate, endDate, options) {

        let newDataBody = []
        let newDataBefore = []
        let newDataAfter = []
        let heartBeat = data[data.length - 1].data.before
            .concat(data[data.length - 1].data.body)
            .concat(data[data.length - 1].data.after);
        if(data.length > 0) {
            data.pop();
        }

        for (let i = 0; i < data.length; i++) {
            newDataAfter = [...newDataAfter, ...data[i].data.after]
            newDataBefore = [...newDataBefore, ...data[i].data.before]
            newDataBody = [...newDataBody, ...data[i].data.body]
        }

        let allBodyValues = []
        let offset = new Date().getTimezoneOffset() * 60000
        let dataBody = {}
        let dataBefore = {}
        let dataAfter = {}

        if (options.length === 0) return
        for (let i = 0; i < options.length; i++) {
            dataBody[options[i]] = []
            dataBefore[options[i]] = []
            dataAfter[options[i]] = []
        }

        if (newDataBody) {
            for (let i = 0; i < newDataBody.length; i++) {
                if (dataBody[newDataBody[i].attr.key] !== undefined) {
                    allBodyValues.push(Date.parse(newDataBody[i].ts) - offset)
                    dataBody[newDataBody[i].attr.key].push({
                            id: i + 1,
                            key: newDataBody[i].attr.key,
                            value: Number(newDataBody[i].value),
                            time: Date.parse(newDataBody[i].ts) - offset
                        }
                    )
                }
            }
        }
        if (newDataBefore) {
            for (let i = 0; i < newDataBefore.length; i++) {
                if (dataBefore[newDataBefore[i].attr.key] !== undefined) {
                    dataBefore[newDataBefore[i].attr.key].push({
                            id: i + 1,
                            key: newDataBefore[i].attr.key,
                            value: Number(newDataBefore[i].value),
                            time: Date.parse(newDataBefore[i].ts) - offset
                        }
                    )
                }

            }
        }
        if (newDataAfter) {
            for (let i = 0; i < newDataAfter.length; i++) {
                if (dataAfter[newDataAfter[i].attr.key] !== undefined) {
                    dataAfter[newDataAfter[i].attr.key].push({
                            id: i + 1,
                            key: newDataAfter[i].attr.key,
                            value: Number(newDataAfter[i].value),
                            time: Date.parse(newDataAfter[i].ts) - offset
                        }
                    )
                }
            }
        }
        allBodyValues = [Date.parse(firstDate), ...allBodyValues, Date.parse(endDate)].sort((a, b) => a - b)
        // created ticks
        let mode = 0
        let count = 6
        if (differenceInCalendarDays(firstDate, endDate) > 0) {
            mode = 1
            count = 5
        }
        let ticks = getTicks(firstDate, endDate, count, mode);

        const domain = [dataMin => dataMin, () => Date.parse(endDate)];
        setTicks(ticks)
        setDomain(domain)

        let newData = []
        let currentValueElements = {}
        let nullAfterElements = {}

        for (let p = 0; p < options.length; p++) {
            let label = signals[options[p]].label
            currentValueElements[label] = {value: null, date: null}
            nullAfterElements[label] = {isAfter: false, index: null}
        }


        for (let i = 0; i < allBodyValues.length; i++) {

            let elements = {}
            for (let k = 0; k < options.length; k++) {
                elements[signals[options[k]].label] = null
            }

            for (let j = 0; j < options.length; j++) {

                let currentLabel = signals[options[j]].label
                let filterData = dataBody[options[j]].filter(obj => obj.time === allBodyValues[i])
                if (filterData.length !== 0) {
                    currentValueElements[currentLabel].value = filterData[0].value
                    currentValueElements[currentLabel].date = filterData[0].time
                    elements[currentLabel] = filterData[0].value
                    nullAfterElements[currentLabel].isAfter = false
                }

                if (filterData.length === 0 && (dataAfter[options[j]].length === 0)) {
                    if (!nullAfterElements[currentLabel].isAfter) {
                        nullAfterElements[currentLabel].isAfter = true
                        nullAfterElements[currentLabel].index = i
                    }
                }

                if (currentValueElements[currentLabel].value === null) {
                    if (dataBefore[options[j]].length > 0) {
                        elements[currentLabel] = dataBefore[options[j]][0].value
                        currentValueElements[currentLabel].date = dataBefore[options[j]][0].time
                        currentValueElements[currentLabel].value = dataBefore[options[j]][0].value
                    }
                } else {
                    elements[currentLabel] = currentValueElements[currentLabel].value
                }
            }
            newData.push({
                date: allBodyValues[i],
                ...elements
            })
        }

        let keysNullAfterElements = Object.keys(nullAfterElements)
        for (let i = 0; i < keysNullAfterElements.length; i++) {
            if (nullAfterElements[keysNullAfterElements[i]].isAfter) {
                for (let j = nullAfterElements[keysNullAfterElements[i]].index; j < newData.length; j++) {
                    if (j !== 0) {
                        newData[j][keysNullAfterElements[i]] = null
                    }
                }
            }
        }

        newData = addGraphBreaks(newData, heartBeat, offset)
        setDataGraphics(newData)
    }

    const getTicks = (startDate, endDate, num, mode) => {
        let diff;
        if (mode === 1) {
            diff = differenceInCalendarDays(endDate, startDate);
        } else {
            diff = differenceInHours(endDate, startDate);
        }

        let current = startDate,
            velocity = Math.round(diff / (num - 1));
        const ticks = [startDate.getTime()];
        for (let i = 1; i < num - 1; i++) {
            if (mode === 1) {
                ticks.push(add(current, {days: i * velocity}).getTime());
            } else {
                ticks.push(add(current, {hours: i * velocity}).getTime());
            }

        }
        ticks.push(endDate.getTime());
        return ticks;
    };


    const dateFormatter = date => {
        if (dataGraphics.length === 0) {
            return
        }
        return moment(date).format('DD MMM HH:mm'); 
    }

    function handleChange(value) {
        setFilterSettings(date[0], date[1], value)
        setOptionsSelected(value)
        getData([date[0], date[1]], value)

    }

    function formatYAxis(value) {
        if (String(value).length < 6) {
            return value
        }
        return value.toExponential(0)
    }

    function addGraphBreaks(graphPoints, heartBeat, offset) {
        let startDate = graphPoints[0].date;
        let endDate = graphPoints[graphPoints.length - 1].date;
        let graphPointsWithBreaks = [...graphPoints];

        // шаблон пустой точки
        let emptyPoint = {...graphPoints[0]}
        Object.keys(emptyPoint).forEach(propertyName => {
            emptyPoint[propertyName] = null;
        })

        if(heartBeat.length === 0) {
            const startPeriodDate = { date: graphPointsWithBreaks[0].date };
            const endPeriodDate = { date: graphPointsWithBreaks[graphPointsWithBreaks.length - 1].date }
            return [startPeriodDate, endPeriodDate];
        }
        if(heartBeat.length === 1) {
            const emptyStartPeriod = { date: graphPointsWithBreaks[0].date };
            const emptyEndPeriod = { date: graphPointsWithBreaks[graphPointsWithBreaks.length - 1].date }
            let heartBeatDate = Date.parse(heartBeat[0].ts) - offset;
            const points = graphPointsWithBreaks.filter(p => p.date === heartBeatDate);
            if(points.length === 0) {
                return [emptyStartPeriod, emptyEndPeriod]
            }
            if(points[0].date === emptyStartPeriod.date) {
                return points.concat([emptyEndPeriod]);
            }
            if(points[0].date === emptyEndPeriod.date) {
                return [emptyStartPeriod].concat(points);
            }
            return [emptyStartPeriod].concat(points).concat([emptyEndPeriod]);
        }

        for(let i = heartBeat.length - 2; i >= 0; i--){
            const startPeriodDate = Date.parse(heartBeat[i].ts) - offset;
            const endPeriodDate = Date.parse(heartBeat[i + 1].ts) - offset;
            const diffTime = (endPeriodDate - startPeriodDate) / 1000;
            if(diffTime > DEVICE_DOWNTIME) {
                // если начало периода
                if(startPeriodDate < startDate && startDate < endPeriodDate) {
                    let point = {...emptyPoint, date: graphPoints[0].date};
                    // убираем точки на graphPoints между точками heartBeat
                    graphPointsWithBreaks = graphPointsWithBreaks.filter(p => !(startPeriodDate < p.date && p.date < endPeriodDate));
                    graphPointsWithBreaks = [point].concat(graphPointsWithBreaks);
                }
                // если конец периода
                if(startPeriodDate < endDate && endDate < endPeriodDate) {
                    let point = {...emptyPoint, date: graphPoints[graphPoints.length - 1].date};
                    // убираем точки на graphPoints между точками heartBeat
                    graphPointsWithBreaks = graphPointsWithBreaks.filter(p => !(startPeriodDate < p.date && p.date < endPeriodDate));
                    graphPointsWithBreaks = graphPointsWithBreaks.concat(point);
                }
                // если середина периода
                if(startDate <= startPeriodDate && endPeriodDate <= endDate) {
                    // убираем точки на graphPoints между точками heartBeat
                    graphPointsWithBreaks = graphPointsWithBreaks.filter(p => !(startPeriodDate < p.date && p.date < endPeriodDate));
                    let leftGraphPointPos, rightGraphPointPos;
                    // оптимизация: выбираем с какого конца начинаем поиск
                    if(graphPointsWithBreaks[Math.floor(graphPointsWithBreaks.length / 2)].date > startPeriodDate) {
                        // начинаем поиск сначала
                        rightGraphPointPos = 0;
                        while(graphPointsWithBreaks[rightGraphPointPos].date < endPeriodDate) {
                            rightGraphPointPos++;
                        }
                        leftGraphPointPos = rightGraphPointPos - 1;
                    } else {
                        // начинаем поиск с конца
                        leftGraphPointPos = graphPointsWithBreaks.length - 1;
                        while(graphPointsWithBreaks[leftGraphPointPos].date > startPeriodDate) {
                            leftGraphPointPos--;
                        }
                        rightGraphPointPos = leftGraphPointPos + 1;

                    }
                    const graphDiffTime = Math.floor((graphPointsWithBreaks[rightGraphPointPos].date - graphPointsWithBreaks[leftGraphPointPos].date) / 2);
                    graphPointsWithBreaks.splice(rightGraphPointPos, 0, {...emptyPoint, date: graphPoints[leftGraphPointPos].date + graphDiffTime});
                }
            }
        }

        return graphPointsWithBreaks
    }

    const debounceHandleChange = debounce(handleChange, 400)

    return (
        <React.Fragment>
            {!isFetching
                ? <div className="stat-widget">
                    <div draggable="true" className="header-widget">
                        <div/>
                        <div className="main-widget-title title-stat-widget">
                            {`${deviceName}`}
                        </div>
                        <div className="area-widget-close" onClick={() => {
                            closeWidget(widgetId)
                        }}>
                            <CloseOutlined className="widget-close"/>
                        </div>
                    </div>
                    <div>
                        <Spin className="fetching-stat"/>
                    </div>
                </div>

                : <div className="stat-widget">
                    <div draggable="true" className="header-widget">
                        <div/>
                        <div className="main-widget-title title-stat-widget">
                            {`График временных рядов: ${deviceName}`}
                        </div>
                        <div className="area-widget-close" onClick={() => {
                            closeWidget(widgetId)
                        }}>
                            <CloseOutlined className="widget-close"/>
                        </div>
                    </div>
                    <LineChart
                        width={945} height={225} data={dataGraphics}
                        margin={optionsSelected.length <= 1
                            ? {top: 15, right: 75, left: 50, bottom: 0} : {top: 15, right: 30, left: 15, bottom: 0}
                        }
                        style={{overflow: 'hidden', fontSize: 14}}
                    >
                        {optionsSelected.length !== 0
                            ? <React.Fragment>
                                <XAxis type='number' tickFormatter={dateFormatter}
                                       domain={domain} ticks={ticks}
                                       hasTick dataKey="date"/>
                                <YAxis yAxisId="left" width={85} tickFormatter={formatYAxis} label={{
                                    value: signals[optionsSelected[0]].extendedLabel,
                                    angle: -90,
                                    position: 'insideLeft',
                                    fill: 'white',
                                    offset: 24,
                                    style: {textAnchor: 'middle'}
                                }}/>
                                <Tooltip content={<CustomTooltip/>}/>
                                <Line yAxisId="left" type="stepAfter"
                                      dataKey={signals[optionsSelected[0]].label}
                                      tooltipItemColor={colors[0]}
                                      stroke={colors[0]}
                                      dot={{ r: 1 }}
                                      activeDot={{ r: 3}}
                                      isAnimationActive={false}/>
                                {optionsSelected.length > 1
                                    ? <React.Fragment>
                                        <YAxis yAxisId={'r1'} width={85} orientation="right" tickFormatter={formatYAxis} label={{
                                            value: signals[optionsSelected[1]].extendedLabel,
                                            angle: 90,
                                            position: 'insideRight',
                                            fill: 'white',
                                            offset: 24,
                                            style: {textAnchor: 'middle'}
                                        }}/>
                                        <Line yAxisId={'r1'} type="stepAfter"
                                              dataKey={signals[optionsSelected[1]].label}
                                              tooltipItemColor={colors[1]}
                                              stroke={colors[1]}
                                              dot={{ r: 1 }}
                                              activeDot={{ r: 3}}
                                              isAnimationActive={false}/>
                                    </React.Fragment>
                                    : null}
                                {optionsSelected.length > 2
                                    ? <React.Fragment>
                                        <YAxis yAxisId={'r2'} width={85} orientation="right"  tickFormatter={formatYAxis} label={{
                                            value: signals[optionsSelected[2]].extendedLabel,
                                            angle: 90,
                                            position: 'insideRight',
                                            fill: 'white',
                                            offset: 24,
                                            style: {textAnchor: 'middle'}
                                        }}/>
                                        <Line yAxisId={'r2'} type="stepAfter"
                                              dataKey={signals[optionsSelected[2]].label}
                                              tooltipItemColor={colors[2]}
                                              stroke={colors[2]}
                                              dot={{ r: 1 }}
                                              activeDot={{ r: 3}}
                                              isAnimationActive={false}/>
                                    </React.Fragment>
                                    : null}
                                {optionsSelected.length > 3
                                    ? <React.Fragment>
                                        <YAxis yAxisId={'r3'} width={85} orientation="right" tickFormatter={formatYAxis} label={{
                                            value: signals[optionsSelected[3]].extendedLabel,
                                            angle: 90,
                                            position: 'insideRight',
                                            fill: 'white',
                                            offset: 24,
                                            style: {textAnchor: 'middle'}
                                        }}/>
                                        <Line yAxisId={'r3'} type="stepAfter"
                                              dataKey={signals[optionsSelected[3]].label}
                                              tooltipItemColor={colors[3]}
                                              stroke={colors[3]}
                                              dot={{ r: 1 }}
                                              activeDot={{ r: 3}}
                                              isAnimationActive={false}/>
                                    </React.Fragment>
                                    : null}
                                {optionsSelected.length > 4
                                    ? <React.Fragment>
                                        <YAxis yAxisId={'r4'} width={85} orientation="left" tickFormatter={formatYAxis} label={{
                                            value: signals[optionsSelected[4]].extendedLabel,
                                            angle: -90,
                                            position: 'insideLeft',
                                            fill: 'white',
                                            offset: 24,
                                            style: {textAnchor: 'middle'}
                                        }}/>
                                        <Line yAxisId={'r4'} type="stepAfter"
                                              dataKey={signals[optionsSelected[4]].label}
                                              tooltipItemColor={colors[4]}
                                              stroke={colors[4]}
                                              dot={{ r: 1 }}
                                              activeDot={{ r: 3}}
                                              isAnimationActive={false}/>
                                    </React.Fragment>
                                    : null}
                            </React.Fragment>
                            : <XAxis type='number' tickFormatter={dateFormatter}
                                     domain={domain} ticks={ticks}
                                     hasTick dataKey="date"/>}
                    </LineChart>
                    <div className="wrapper">
                        <div/>
                        <Select className='select-signals'
                                mode="multiple"
                                style={{width: 450}}
                                defaultValue={optionsSelected.map((obj) => {
                                    return obj
                                })}
                                size="small"
                                placeholder="Выберите сигналы"
                                tagRender={tagRender}
                                optionLabelProp="label"
                                onChange={(value) => debounceHandleChange(value)}
                        >
                            {Object.keys(signals).map((item) => {
                                const colorIdx = optionsSelected.indexOf(item);
                                return <Select.Option
                                    value={item}
                                    label={{
                                        label: signals[item].label,
                                        fullName: signals[item].fullLabel,
                                        color:colorIdx>=0?colors[colorIdx]:'black'
                                    }}
                                    disabled={
                                        optionsSelected.length > 4
                                            ? optionsSelected.includes(item)
                                                ? false
                                                : true
                                            : false
                                    }
                                    key={item}
                                >
                                    {signals[item].fullLabel}
                                </Select.Option>
                            })}
                        </Select>
                        <div className="box-button">
                            <div className="reset-buttons">
                                <button style={{fontSize: 14}} onClick={() => changePage('data', true)}>
                                    {
                                        TIME_MODES.some(tm => tm.id === timeMode.mode)
                                        ? `Последни[й/е]: ${TIME_MODES.find(tm => tm.id === timeMode.mode).label}`
                                        : `с ${moment(new Date(date[0])).format('DD.MM.yyyy HH:mm')} по ${moment(new Date(date[1])).format('DD.MM.yyyy HH:mm')}`
                                    }
                                </button>
                            </div>

                            {timeMode.mode !== DEFAULT_TIME_MODE
                                ? <AntdTooltip placement="top" title="Сброс выбранной даты">
                                    <CloseSquareOutlined onClick={() => resetDate()} style={{fontSize: 22}}/>
                                </AntdTooltip>
                                : null}

                        </div>
                    </div>
                </div>

            }
            {dialogs.data ?
                <ShowDialogStatWidget
                    title={"Выбор интервала"}
                    number={deviceId}
                    widgetName={deviceName}
                    value={"data"}
                    changePage={changePage}
                    component={<DataChange widget={widget} value={"data"}
                                           applyDataChange={applyDataChange}
                                           changePage={changePage}
                                           curTimeMode={timeMode.mode}
                                           timeModes = {TIME_MODES}
                                           date={!TIME_MODES.some(tm => tm.id === timeMode.mode) ? date : null}/>}/>
                : null}
        </React.Fragment>
    )
}
