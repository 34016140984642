import React, {useEffect, useState} from "react";
import {ShowDialog} from "../ShowDialog";
import {CrashView} from "./CrashView";
import {updateInputValue} from "../../../Debounce";


export const ModeChange = ({deviceId, widgetName, crashes, isAlarm, sysState, unitId}) => {

    const [dialogs, changeDialog] = React.useState({
        crashView: false,
    });
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        setIsDisabled(false)
    }, [sysState])

    const changePage = (value, option) => {

        switch (value) {
            case "crashView":
                changeDialog({...dialogs, crashView: option})
                break
            default:
                changeDialog({
                    mode: false
                })
        }

    }
    function changeSysState(key, value) {
        if (value !== sysState && !isDisabled) {
            updateInputValue(unitId, key, value)
            setIsDisabled(true)
        }
    }

    return (
        <div className="mode-change" draggable="false">
            <div className="mode-change-row">
                <div onClick={() => changeSysState('sysState', 2)}
                     className={isDisabled ?
                         sysState === 2 ? "mode-change-box disabled-box" : "mode-change-box disabled-box"
                        : sysState === 2 ? "mode-change-box is-active" : "mode-change-box"}>
                    <p>Включить</p>
                </div>
                <div onClick={() => changeSysState('sysState', 0)}
                     className={isDisabled ?
                         sysState === 0 ? "mode-change-box disabled-box" : "mode-change-box disabled-box"
                         : sysState === 0 ? "mode-change-box is-active" : "mode-change-box"}>
                    <p>Выключить</p>
                </div>
                <div onClick={() => changeSysState('sysState', 3)}
                     className={isDisabled ?
                         sysState === 3 ? "mode-change-box disabled-box" : "mode-change-box disabled-box"
                         : sysState === 3 ? "mode-change-box is-active" : "mode-change-box"}>
                    <p>СЛИВ БАКА</p>
                </div>
            </div>
            <div className="mode-change-row">
                <div onClick={() => changeSysState('sysState', 4)}
                     className={isDisabled ?
                         sysState === 4 ? "mode-change-box disabled-box" : "mode-change-box disabled-box"
                         : sysState === 4 ? "mode-change-box is-active" : "mode-change-box"}>
                    <p>СЛИВ КАНАЛОВ</p>
                </div>
                <div onClick={() => changeSysState('sysState', 5)}
                     className={isDisabled ?
                         sysState === 5 ? "mode-change-box disabled-box" : "mode-change-box disabled-box"
                         : sysState === 5 ? "mode-change-box is-active" : "mode-change-box"}>
                    <p>СЛИВ СИСТЕМЫ</p>
                </div>
                <div onClick={() => changePage('crashView', true)} className={isAlarm ? "mode-change-box is-alarm" : "mode-change-box"}>
                    <p>ПРОСМОТР АВАРИЙ</p>
                </div>
            </div>
            {dialogs.crashView ?
                <ShowDialog
                    isAlarm={isAlarm}
                    title={"Просмотр аварий"}
                    number={deviceId}
                    widgetName={widgetName}
                    value={"crashView"}
                    changePage={changePage}
                    component={<CrashView crashes={crashes}/>}/>
                : null}

        </div>

    )
}

