import React, {useEffect, useState} from 'react';
import {Input} from "../../hooks/Input";


const RegulationRange = ({unitId, ppmLow, ppmHigh}) => {

    const [disabled, setDisabled] = useState(false)
    const [update, setUpdate] = useState(true)

    useEffect(() => {
        setDisabled(false)
        setUpdate(!update)
    }, [ppmLow, ppmHigh])



    return (<div className="ph-dialog" draggable="false">
                <div className="row-ph-dialog ph-level-row">
                    <p>Включить</p>
                    <Input value={ppmLow}
                           min={ppmLow > ppmHigh ? ppmHigh + 150 : 400}
                           max={ppmLow > ppmHigh ? 2000 : ppmHigh - 150} mode={1}
                           deviceId={unitId}
                           ckey={'ppmLow'} setDisabled={setDisabled} disabled={disabled} update={update}/>
                </div>
                <div className="row-ph-dialog ph-level-row">
                    <p>Выключить</p>
                    <Input value={ppmHigh}
                           min={ppmHigh > ppmLow ? ppmLow + 150 : 400}
                           max={ppmHigh > ppmLow ? 2000 : ppmLow - 150} mode={1} ckey={'ppmHigh'}
                           deviceId={unitId} disabled={disabled} setDisabled={setDisabled} update={update}/>
                </div>
                <div className="row-ph-dialog ph-level-row">

                </div>
            </div>);
};

export default RegulationRange;