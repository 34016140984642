import arrow from "../../../../../resources/images/Arrow-right.svg";
import React from "react";
import {ShowDialog} from "../ShowDialog";
import {ProgramSelection} from "./Selection/ProgramSelection";
import {Input} from "../../../hooks/Input";
import {HookSwitch} from "../../../hooks/HookSwitch";

export const ECDialog = ({
                             isAlarm, unitId, phCycleTmMax, phCycleTmMin,
                             deviceId, widgetName, controlECProg, ecProgId,
                             ecIsOn, ecIsProtected, ecSp,
                             ecCycleTm, ecA, ecB, ecC
                         }) => {

    const [dialogs, changeDialog] = React.useState({
        programSelection: false,
    });

    const changePage = (value, option) => {

        switch (value) {
            case "program-selection":
                changeDialog({...dialogs, programSelection: option})
                break
            default:
                changeDialog({
                    mode: false
                })
        }
    }

    return <div>
        <div className="ph-dialog" draggable="false">
            <div className="row-ph-dialog ph-level-row">
                <p>Пауза дозирования</p>
                <Input value={ecCycleTm} min={phCycleTmMin} max={phCycleTmMax} mode={1} deviceId={unitId} ckey={'ecCycleTm'}/>
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>Регулятор EC</p>
                <HookSwitch unitId={unitId} value={ecIsOn} name={'ecIsOn'}/>
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>Защита</p>
                <HookSwitch unitId={unitId} value={ecIsProtected} name={'ecIsProtected'}/>
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>Уровень EC</p>
                <div className="level-ph">
                    <p>{Math.round((ecSp) * 100) / 100}</p>
                    <button className="button-arrow">
                        <div className="logo-widget">
                            <img onClick={() => changePage('program-selection', true)} src={arrow} alt="arrow"/>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        {dialogs.programSelection ?
            <ShowDialog
                isAlarm={isAlarm}
                title={"Выбор программы"}
                number={deviceId}
                widgetName={widgetName}
                value={"program-selection"}
                changePage={changePage}
                component={<ProgramSelection deviceId={deviceId} widgetName={widgetName} isAlarm={isAlarm}
                                             ecSp={ecSp} ecA={ecA} ecB={ecB} ecC={ecC}
                                              unitId={unitId} controlECProg={controlECProg} ecProgId={ecProgId}/>}/>
            : null}
    </div>
}
