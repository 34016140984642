import React from 'react';
import {Form, Table, notification, Button} from 'antd';
import requestToAPI from "../lib/Request";
import {QUERY_GET_LIST_DEVICE, MUT_INS_DEVICE, QUERY_GET_DEVICE} from "../lib/Query";
import {MSG_REQUEST_ERROR, MSG_SELECT_WIDGETS_ERROR} from "../lib/Const";
import {drawDateAndTimeIncSec} from "../lib/Utils";
import {responsiveMobileColumn} from '../lib/Responsive';
import {ColumnTypes} from "../lib/DataTable";
import moment from "moment";
import {ShowModal} from "../lib/EditForm";
import DeviceRegisterForm from "./device/DeviceRegisterForm";
import {WidgetsListForm} from "./WidgetsListForm";
import {useHistory} from "react-router-dom";


export const DevicesListForm = (props) => {
    const [firstInputRef] = React.useState(React.useRef(null));
    const [devicesData, setDevicesData] = React.useState([]);
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = React.useState(false)
    const history = useHistory()
    React.useEffect(() => {
        setTimeout(() => {
            if (firstInputRef.current) {
                firstInputRef.current.focus({
                    cursor: 'end',
                });
            }
        }, 200);
    });

    React.useEffect(() => {
        requestToAPI.post(QUERY_GET_LIST_DEVICE).then(response => {
            const dataSource = response.data.records
            let new_data = dataSource.reduce((acc, item) => {
                acc.push({
                    ...item,
                    id: item.id,
                    number: item.number,
                    notes: item.notes[0].note,
                    model: item.model.name,
                    manufacturedInSeconds: item.manufacturedInSeconds
                })
                return acc
            }, [])
            setDevicesData(new_data);
            setIsLoading(true)
        }).catch((error) => {
            console.log(error);
            notification.error({
                message: MSG_REQUEST_ERROR,
                description: error.message
            })
        })
    }, []);

    const openWidgetSelect = (record) => {
        getWidgetsList(record)
    }
    const COLUMNS = [
        {
            title: 'Серийный номер',
            dataIndex: 'number',
            ellipsis: true,
        },
        {
            title: 'Описание владельца',
            dataIndex: 'notes',
            ellipsis: true,
            responsive: responsiveMobileColumn(),
        },
        {
            title: 'Тип прибора',
            dataIndex: 'model',
            ellipsis: true,
            responsive: responsiveMobileColumn(),
        },
        {
            title: 'Произведен',
            dataIndex: 'manufacturedInSeconds',
            dataType: ColumnTypes.DATETIME,
            ellipsis: true,
            defaultSortOrder: 'ascend',
            render: drawDateAndTimeIncSec,
            renderForFilter: (data) => moment(data * 1000).format("DD.MM.YYYY HH:mm")
        }
    ]

    function getLastPos() {
        let max = 0
        for (let i = 0; i < props.widgets.length; i++) {
            if (max < props.widgets[i].setting.pos) {
                max = props.widgets[i].setting.pos
            }
        }
        return max
    }

    const getWidgetsList = (curDevice) => {
        const dialog = ShowModal({
            editorContext: {
                uriForInsert: (dlgId) => {
                    return new Promise(async function (resolve, reject) {
                        let data = form.getFieldValue().rows
                        let lastPos = getLastPos()
                        if (data) {
                            await props.addWidget(data, lastPos)
                            await props.getDashboard()
                            resolve('done');
                            reject('error')
                        } else {
                            notification.info({
                                message: MSG_SELECT_WIDGETS_ERROR
                            })
                            form.resetFields();
                            props.setTopLayer([...props.topLayer.filter(c => c.props.id != dlgId)]);
                        }
                    })
                },
                uriForGetOne: QUERY_GET_DEVICE
            },
            form: form,
            dialogId: "modal-devices123",
            title: "Выберите виджеты",
            saveButtonTitle: "Выбрать",
            content: <WidgetsListForm widgets={props.widgets} topLayer={props.topLayer}
                                      setTopLayer={props.setTopLayer} curDevice={curDevice}/>,
            idName: "id",
            width: "720px",
            destroyDialog: (dlgId) => {
                form.resetFields();
                props.setTopLayer([...props.topLayer.filter(c => c.props.id != dlgId)]);
            },
        });
        // вставляем Modal в top layer
        props.setTopLayer([dialog])
    }

    const changeRegForm = () => {
        const config = {
            form: form,
            dialogId: "modal-regdevice2",
            editorContext: {
                uriForInsert: MUT_INS_DEVICE,
                uriForGetOne: QUERY_GET_DEVICE
            },
            destroyDialog: (dlgId) => {
                form.resetFields();
                props.setTopLayer([...props.topLayer.filter(c => c.props.id != dlgId)]);
            },
        }
        // формируем диалог
        const dialog = ShowModal({
            ...config,
            title: "Регистрация прибора",
            content: <DeviceRegisterForm/>,
            visible: true,
            idName: "id",
            width: "520px",
            afterSave: (values) => {
                // const record = values.data.record;
                // setNewPin(record.unit.number, record.unit.model.name);
                history.push('/device')
            }
        });
        // вставляем Modal в top layer
        props.setTopLayer([dialog])
    }

    return <Form
        form={props.form}
        layout="vertical"
        name="devices-frm"
        initialValues={props.initialValues}
    >
        <div tabIndex="0" ref={firstInputRef}/>
        <Table className="device-table" rowKey="id"
               style={{height: '385px'}}
               locale={{
                   emptyText: isLoading ? <p className="register-text">Нет зарегистрированных приборов</p> : ''
               }}
               pagination={{pageSize: 5, showSizeChanger: false}}
               size={"small"}
               columns={COLUMNS}
               dataSource={devicesData}
               rowClassName="table-editable-row"
               onRow={(record, rowIndex) => {
                   return {
                       onClick: () => openWidgetSelect(record)
                   }
               }}
        />
        {devicesData.length === 0 && isLoading
            ? <Button id="ok-btn" type={"primary"} onClick={changeRegForm}>Зарегистрировать прибор</Button>
            : null}
    </Form>
}
