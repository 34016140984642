import arrow from '../../../../resources/images/arrow.svg'
import React from "react";
import {Tooltip} from "antd";



export const ShowDialog = ({widgetName, title, changePage, component, isAlarm, value}) => {
    return (
        <div className="main-widget-dialog" draggable={true}>
            <div className={isAlarm ? "header-widget background-red" : "header-widget"}>
                <div className="burger-widget" onClick={() => changePage(value, false)}>
                    <button className="button-arrow">
                        <img className="button-arrow-header" src={arrow} alt="arrow"/>
                    </button>
                </div>
                <Tooltip placement="top" title={<p className='wrap-text'>{widgetName}</p>}>
                    <div className={isAlarm ? "main-widget-title background-red" : "main-widget-title"}>
                        {title}: {widgetName}
                    </div>
                </Tooltip>
            </div>
            {component}
        </div>
    )
}
