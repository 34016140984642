import React from "react";
import {Input} from "../../../hooks/Input";
import {HookSwitch} from "../../../hooks/HookSwitch";


export const SolutionTemperature = ({thermIsOn, unitId,
                                        thermUpper, thermLower}) => {


    return (
        <div className="ph-dialog" draggable="false">
            <div className="row-ph-dialog ph-level-row">
                <p>Термостат</p>
                <HookSwitch name={'thermIsOn'} value={thermIsOn} unitId={unitId}/>
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>Нижняя точка, °С</p>
                <Input value={thermLower} min={10} max={16}
                       mode={1} deviceId={unitId} ckey={'thermLower'}/>
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>Верхняя точка, °С</p>
                <Input value={thermUpper} min={12} max={18}
                       mode={1} deviceId={unitId} ckey={'thermUpper'}/>
            </div>
            <div className="row-ph-dialog ph-level-row">

            </div>
        </div>
    )
}
