import React from "react";
import { Input } from "../../hooks/Input";

export const ParameterPHDialog = ({ unitId, tankEC, tankECMax, tankECMin, phCycleTm, phCycleTmMin }) => {
    return <div>
        <div className="ph-dialog" draggable="false">
            <div className="row-ph-dialog ph-level-row">
                <p>EC раствора</p>
                <Input
                    value={tankEC}
                    min={tankECMin}
                    max={tankECMax}
                    mode={0.01}
                    round={100}
                    deviceId={unitId}
                    ckey={'tankEC'}
                />
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>Пауза доз. (мин)</p>
                <Input
                    value={phCycleTm}
                    min={phCycleTmMin}
                    mode={1}
                    deviceId={unitId}
                    ckey={'phCycleTm'}

                />
            </div>
        </div>
    </div>
}
