import React from 'react';
import { ReactComponent as SunSvg } from '../../../../resources/images/sun.svg';
import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';

const LIGHTING_MODE = {
    0: "перед инициализацией автомата управления светом",
    1: "ночь",
    2: "рассвет",
    3: "день",
    4: "закат",
    5: "принудительный переход в ночь",
    6: "принудительный переход в день"
}

export const LightingModeButton = ({ handleClick, lightState, lightStateTitle }) => {
    const [isDisabled, setIsDisabled] = React.useState(false);
    let color = [2, 3, 4, 6].includes(lightState) && !isDisabled ? '#FFFF00' : '#7F7F7F';

    React.useEffect(() => {
        setIsDisabled(false)
    }, [lightState])

    function onClick() {
        if (!isDisabled) {
            handleClick();
            setIsDisabled(true)
        }
    }

    return <div
        style={{
            color: color,
            fontSize: '55px',
            cursor: isDisabled ? 'default' : 'pointer'
        }}
        onClick={onClick}
        className='widget-button'
    >
        <Tooltip
            placement="topLeft"
            title={lightState ? lightStateTitle?.title + ` «${LIGHTING_MODE[lightState]}»` : lightStateTitle?.noneStateTitle}
        >
            <Icon component={SunSvg} />
        </Tooltip>
    </div>
}