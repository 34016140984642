import React from "react";
import {ShowDialog} from "../ShowDialog";
import {SettingEC} from "./SettingEC";
import arrow from "../../../../../resources/images/Arrow-right.svg";
import {HookDosing} from "../../../hooks/HookDosing";
import {loadPreference, savePreference} from "../../../../lib/PreferenceStorage";


export const ManualControlEC = ({
                                    taskEcAControl, taskEcBControl,
                                    taskEcCControl, unitId, tankEc,
                                    deviceId, isAlarm, widgetName, ecSensUsedA, ecSensUsedB, ecSensUsedC,
                                    ecNutrUsedA, ecNutrUsedB, ecNutrUsedC
                                }) => {

    const [dialogs, changeDialog] = React.useState({
        setting: false,
    });
    const changePage = (value, option) => {

        switch (value) {
            case "setting":
                changeDialog({...dialogs, setting: option})
                break
            default:
                changeDialog({
                    mode: false
                })
        }

    }

    const savePrefEcDose = (prefix,val)=>{
        const userPref  = loadPreference(deviceId) || {};
        userPref.manualControlEC = {...userPref.manualControlEC,["taskEc"+prefix]:val}
        savePreference(deviceId,userPref);
    }


    return (
        <div className="ph-dialog" draggable="false">
            <div className="row-ph-dialog ph-level-row">
                <div className="ph-value">
                    <p>EC</p>
                    <p className="wrap-text">{(Math.round((tankEc) * 100) / 100).toFixed(2)}</p>
                </div>
                <div className="level-ph setting-arrow">
                    <p>Настройка</p>
                    <button className="button-arrow">
                        <div className="logo-widget">
                            <img onClick={() => changePage('setting', true)} src={arrow} alt="arrow"/>
                        </div>
                    </button>
                </div>
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>Удобрение A</p>
                <HookDosing unitId={unitId} used={ecNutrUsedA} taskEC='taskEcA'
                                valueTaskEcMin={1} valueTaskEcMax={taskEcAControl.max} mode={1} afterSetValue={(v)=>savePrefEcDose('A',v)}/>
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>Удобрение B</p>
                <HookDosing unitId={unitId} used={ecNutrUsedB} taskEC='taskEcB'
                            valueTaskEcMin={1} valueTaskEcMax={taskEcBControl.max} mode={1}  afterSetValue={(v)=>savePrefEcDose('B',v)}/>
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>Удобрение C</p>
                <HookDosing unitId={unitId} used={ecNutrUsedC} taskEC='taskEcC'
                            valueTaskEcMin={1} valueTaskEcMax={taskEcCControl.max} mode={1}  afterSetValue={(v)=>savePrefEcDose('C',v)}/>
            </div>
            {dialogs.setting ?
                <ShowDialog
                    isAlarm={isAlarm}
                    title={"Настройка подачи удобрений"}
                    number={deviceId}
                    widgetName={widgetName}
                    value={"setting"}
                    changePage={changePage}
                    component={<SettingEC unitId={unitId}
                                          ecSensUsedA={ecSensUsedA}
                                          ecSensUsedB={ecSensUsedB}
                                          ecSensUsedC={ecSensUsedC}/>}/>
                : null}
        </div>
    )
}
