import React from 'react';
import { ReactComponent as GraphicSvg } from '../../../../resources/images/graphic.svg';
import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';

export const LightingButton = ({ handleClick, lightingPageTitle }) => {

    return <div
        style={{
            color: '#fff',
            fontSize: '55px',
        }}
        onClick={handleClick}
        className='widget-button'
    >
        <Tooltip
            placement="top"
            title={lightingPageTitle?.title}
        >
            <Icon component={GraphicSvg} />
        </Tooltip>
    </div>
}