import React from 'react';
import { CloseOutlined } from "@ant-design/icons";
import { Col, Row, Spin } from "antd";
import { ShowDialog } from "../dialogs/ShowDialog";
import requestToAPI from "../../../lib/Request";
import { subscribe } from "../../../lib/RequestWS";
import { TimeButton } from './TimeButton';
import { WateringButton } from './WateringButton';
import { TimerButton } from './TimerButton';
import { LightingButton } from './LightingButton';
import { LightingModeButton } from './LightingModeButton';
import { WateringDialog } from './WateringDialog';
import { updateInputValue } from '../../Debounce';
import { TimerDialog } from './TimerDialog';
import { LightingDialog } from './LightingDialog';

const MAIN_PAGE = 1;
const WATERING_PAGE = 2;
const LIGHTING_PAGE = 3;
const TIMER_PAGE = 4;

const PonicsDefaultMonitor = {}

export const Zenith = ({ widgetId, deviceName, deviceId, closeWidget }) => {
    const [page, setPage] = React.useState(MAIN_PAGE);

    const [monitor, setMonitor] = React.useState(PonicsDefaultMonitor);
    const [crashes, setCrashes] = React.useState([]);
    const [contextParams] = React.useState({});
    const [isFetching, setIsFetching] = React.useState(true);
    const [dataMonitor, setDataMonitor] = React.useState([]);

    React.useEffect(() => {
        contextParams.mountFlag = true;
        if (requestToAPI.authToken) {
            if (!contextParams.mountFlag) return;
            subscribe(
                `subscription { 
                    deviceUnitByPk(id: ${deviceId}) { 
                        monitor { 
                            key
                            value 
                        }
                        model {
                          metadata
                        }
                    } 
                }`,
                (payload) => {
                    if (!contextParams.mountFlag) return;
                    let new_data = payload.data.deviceUnitByPk
                    if (new_data) {
                        var data = new_data.monitor.reduce(function (map, obj) {
                            map[obj.key] = obj.value;
                            return map;
                        }, {});
                        setMonitor(data)

                        let metadata = new_data.model.metadata && new_data.model.metadata.monitor ? new_data.model.metadata.monitor : {};
                        setDataMonitor(metadata);

                        let crash = []
                        for (let key in data) {
                            let obj = {}
                            if (key.substr(0, 5) === 'alarm') {
                                obj["state"] = data[key]
                                obj["name"] = key.substr(5, 4)
                                obj["meta"] = metadata[key]

                                crash.push(obj)
                            }
                        }
                        setCrashes(crash)
                        setIsFetching(false)
                    } else {
                        setPage(MAIN_PAGE);
                    }
                }
            )
        }
        return () => {
            contextParams.mountFlag = false
        }
    }, [])

    return (
        <div className="main-widget">
            <div draggable="true" className={monitor.waterMode == 3 ? "header-widget background-red" : "header-widget"}>
                <div />
                <div className={monitor.waterMode == 3 ? "main-widget-title background-red" : "main-widget-title"}>
                    {`${deviceName}`}
                </div>
                <div className="area-widget-close" onClick={() => {
                    closeWidget(widgetId)
                }}>
                    <CloseOutlined
                        className={monitor.waterMode == 3 ? "widget-close-white widget-close" : "widget-close-main"}
                    />
                </div>
            </div>
            {
                (() => {
                    if (isFetching) {
                        return <Spin className="fetching-stat" />
                    }
                    switch (page) {
                        case WATERING_PAGE:
                            return <ShowDialog
                                title={"Полив"}
                                isAlarm={monitor.waterMode == 3}
                                widgetName={deviceName}
                                number={widgetId}
                                value={""}
                                changePage={() => setPage(MAIN_PAGE)}
                                component={<WateringDialog
                                    isAlarm={monitor.waterMode == 3}
                                    deviceId={widgetId}
                                    widgetName={deviceName}
                                    crashes={crashes}
                                    waterMode={monitor.waterMode}
                                    unitId={deviceId}
                                />}
                            />
                        case LIGHTING_PAGE:
                            return <ShowDialog
                                title={"Свет"}
                                widgetName={deviceName}
                                isAlarm={monitor.waterMode == 3}
                                number={widgetId}
                                value={""}
                                changePage={() => setPage(MAIN_PAGE)}
                                component={<LightingDialog
                                    unitId={deviceId}
                                    lightIsOn={monitor.lightIsOn}
                                    lightStart={monitor.lightStart}
                                    lightDay={monitor.lightDay}
                                    lightStartMin={0}
                                    lightDayMin={0}
                                    lightDayMax={18}
                                />}
                            />
                        case TIMER_PAGE:
                            return <ShowDialog
                                title={"Таймер полива"}
                                isAlarm={monitor.waterMode == 3}
                                widgetName={deviceName}
                                number={widgetId}
                                value={""}
                                changePage={() => setPage(MAIN_PAGE)}
                                component={
                                    <TimerDialog
                                        unitId={deviceId}
                                        waterOnTm={monitor.waterOnTm}
                                        waterOnTmMin={0}
                                        waterOffTm={monitor.waterOffTm}
                                        waterOffTmMin={0}
                                    />}
                            />
                        default:
                            return <div>
                                <Row draggable="false" className='border-down'>
                                    <Col span={12} className="left-side-widget">
                                        <TimeButton
                                            dayTime={monitor.dayTime}
                                            dayTimeTitle={dataMonitor.dayTimeTitle}
                                        />
                                    </Col>
                                    <Col span={12} className="right-side-widget">
                                        <WateringButton
                                            waterMode={monitor.waterMode}
                                            alarmE102={monitor.alarmE102}
                                            handleClick={() => setPage(WATERING_PAGE)}
                                            waterModeTitle={dataMonitor.waterModeTitle}
                                        />
                                    </Col>
                                </Row>
                                <Row draggable="false" style={{ height: 112.5 }}>
                                    <Col span={6} className="right-border-2px">
                                        <LightingModeButton
                                            lightState={monitor.lightState}
                                            handleClick={() => {
                                                updateInputValue(deviceId, 'lightState', [2, 3, 4].includes(monitor.lightState) ? 5 : 6);
                                            }}
                                            lightStateTitle={dataMonitor.lightStateTitle}
                                        />
                                    </Col>
                                    <Col span={6} className="right-border-2px">
                                        <LightingButton
                                            handleClick={() => setPage(LIGHTING_PAGE)}
                                            lightingPageTitle={dataMonitor.lightingPageTitle}
                                        />
                                    </Col>
                                    <Col span={6} className="right-border-2px">
                                        <TimerButton
                                            handleClick={() => setPage(TIMER_PAGE)}
                                            timerPageTitle={dataMonitor.timerPageTitle}
                                        />
                                    </Col>
                                </Row>
                            </div>
                    }
                })()
            }
        </div >
    )
}