import React from "react";
import { format } from "date-fns";

const style = {
    padding: 6,
    backgroundColor: "#fff",
    color: 'black',
    border: "1px solid #ccc"
};

const CustomTooltip = props => {
    const { active, payload } = props;
    if (active && payload) {
        const currData = payload && payload.length ? payload[0].payload : null;
        return (
            <div className="area-chart-tooltip" style={style}>
                <p>
                    {currData ? format(new Date(currData.date), "dd.MM.yyyy HH:mm.ss") : " -- "}
                </p>
                {payload.map((obj, i) => {
                    if (Object.keys(obj.payload).includes(obj.dataKey)){
                        return <p key={i} className="custom-tooltip-p">
                            <span style={{color: obj.color, fontWeight: 800}}>{obj.dataKey}: </span>
                            <em>{currData ? currData[obj.dataKey] : " -- "}</em>
                        </p>
                    }
                })}
            </div>
        );
    }

    return null;
};

export default CustomTooltip;
