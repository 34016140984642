import React from 'react';
import { ReactComponent as OkInShieldSvg } from '../../../../resources/images/okInShield.svg';
import Icon from '@ant-design/icons';
import { updateInputValue } from "../../Debounce";
import { Tooltip } from 'antd';

export const RegulatorProtectButton = ({ phIsProtected, unitId, phIsProtectedTitle }) => {
    const [isProtected, setIsProtected] = React.useState(phIsProtected);

    return <div
        style={{
            color: isProtected ? '#fff' : '#666',
            fontSize: '50px',
        }}
        onClick={() => {
            setIsProtected((isProtected) => !isProtected);
            updateInputValue(unitId, 'phIsProtected', !isProtected);
        }}
        className='widget-button'
    >
        <Tooltip
            placement="top"
            title={phIsProtectedTitle?.title}
        >
            <Icon component={OkInShieldSvg} />
        </Tooltip>
    </div>
}