import {Tag, Tooltip} from "antd";


export const tagRender = (props) => {
    const {label, closable, onClose, value} = props;

    const onPreventMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
            <Tag
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                color={label.color}
                size={'small'}
                style={{
                    marginRight: 3,
                    paddingBottom: 1,
                    height: 18,
                    fontSize: 12,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Tooltip title={label.fullName}>
                <span style={{maxWidth: 52, overflow: 'hidden'}}>
                    {label.label}
                </span>
                </Tooltip>


            </Tag>
    );
};
