import React from "react";


export const Prog = ({id, name, setProgram, ecProgId, className}) => {
    return (
        <div className={className}>
            <p onClick={() => setProgram(Number(id), name)}
               className={ecProgId === Number(id) ? "row-p-value upper-letter" : "row-p-white"}>{name}</p>
        </div>
    )
}