import React from 'react';
import {Form, Table, notification} from 'antd';
import requestToAPI from "../lib/Request";
import {
    QUERY_GET_LIST_DEVICE_WIDGET
} from "../lib/Query";
import {MSG_REQUEST_ERROR} from "../lib/Const";
import {drawDateAndTimeIncSec} from "../lib/Utils";
import {responsiveMobileColumn} from '../lib/Responsive';


export const WidgetsListForm = (props) => {
    const [firstInputRef] = React.useState(React.useRef(null));
    const [devicesData, setDevicesData] = React.useState([]);
    const [dataTableMessage, setDataTableMessage] = React.useState("У данного прибора нет виджетов")
    const [isLoading, setIsLoading] = React.useState(false)
    const [data, setData] = React.useState(props.widgets.map(widget => {
        return {
            widgetTypeId: widget.type.id,
            deviceId: widget.unitId
        }
    }))


    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            props.form.setFieldsValue({ rows: selectedRows });
        },
    };

    React.useEffect(() => {
        setTimeout(() => {
            if (firstInputRef.current) {
                firstInputRef.current.focus({
                    cursor: 'end',
                });
            }
        }, 200);
    });


    React.useEffect(() => {
        let deviceId = props.curDevice.id
        requestToAPI.post(QUERY_GET_LIST_DEVICE_WIDGET)
            .then(response => {
                const dataSource = response.data.records
                let curDevice = dataSource.find(item => item.id === props.curDevice.id)
                let widgets = curDevice.model.widgets
                let curWidgets = []
                for (let i = 0; i < widgets.length; i++) {
                    let flag = false
                    if (data.length == 0) flag = true
                    else{
                        for (let j = 0; j < data.length; j++) {
                            if (deviceId === data[j].deviceId) {
                                if (!(data[j].widgetTypeId == widgets[i].type.id)) flag = true
                                else {
                                    flag = false
                                    break
                                }
                            }
                            else flag = true
                        }
                    }

                    if(flag) curWidgets.push({
                        ...curDevice,
                        id: `${widgets[i].type.id} + ${curDevice.id}`,
                        unitId: curDevice.id,
                        widget: widgets[i].type,
                        name: curDevice.model.name,
                        widgetName: widgets[i].type.note,
                        notes: curDevice.notes[0].note

                    })
                }
                if (curWidgets.length === 0 && widgets.length !== 0) {
                    setDataTableMessage("Все виджеты прибора уже добавлены на Главную страницу")
                }
                setIsLoading(true)
                setDevicesData(curWidgets)

            })
            .catch((error) => {
                console.log(error);
                notification.error({
                    message: MSG_REQUEST_ERROR,
                    description: error.message
                })
            })
    }, []);

    const COLUMNS = [
        {
            title: 'Имя виджета',
            dataIndex: 'widgetName',
            ellipsis: true,
            responsive: responsiveMobileColumn(),
        },
        {
            title: 'Серийный номер',
            dataIndex: 'number',
            ellipsis: true,
        },
        {
            title: 'Описание владельца',
            dataIndex: 'notes',
            ellipsis: true,
            responsive: responsiveMobileColumn(),
        },
        {
            title: 'Тип прибора',
            dataIndex: 'name',
            ellipsis: true,
            responsive: responsiveMobileColumn(),
        },
        {
            title: 'Произведен',
            dataIndex: 'manufacturedInSeconds',
            defaultSortOrder: 'ascend',
            render: drawDateAndTimeIncSec
        }
    ]

    return <Form
            form={props.form}
            layout="vertical"
            name="devices-frm"
            initialValues={props.initialValues}
        >
            <div tabIndex="0" ref={firstInputRef}/>
            <Table className="device-table" rowKey="id"
                   style={{ height: '400px' }}
                   locale={{
                       emptyText: isLoading ? <p className="register-text-2">{dataTableMessage}</p> : '-'
                   }}
                   pagination={{pageSize: 5, showSizeChanger: false}}
                   size={"small"}
                   columns={COLUMNS}
                   dataSource={devicesData}
                   rowSelection={rowSelection}
            />
        </Form>
}
