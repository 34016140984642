import React from 'react'
import { Beaker } from '../Beaker'

export const ManualControlPHButton = ({ handleClick, outPhDown, outPhUp }) => {
    const bottlesStyle = {
        padding: 8,
    };

    return (
        <div
            className='canvas-bottles'
            onClick={handleClick}
            style={{
                height: '100%',
                width: '100%',
                cursor: 'pointer',
                justifyContent: 'center',
            }}>
            <div
                style={bottlesStyle}
            >
                <Beaker
                    title="Компонент pH-"
                    text='pH-'
                    curState={
                        "11"
                        + String(Number(outPhDown))
                    }
                    bottleHeight={68}
                    bottleWidth={32}
                />
            </div>
            <div
                style={bottlesStyle}
            >
                <Beaker
                    text='pH+'
                    title="Компонент pH+"
                    curState={
                        "11"
                        + String(Number(outPhUp))
                    }
                    bottleHeight={68}
                    bottleWidth={32}
                />
            </div>

        </div>
    )
}
