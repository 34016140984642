import arrow from "../../../../resources/images/arrow.svg";
import React from "react";

export const ShowDialogStatWidget = ({widgetName, changePage, component, value, title}) => {
    return (
        <div className="main-stat-widget" draggable={true}>
            <div draggable="true" className="header-widget">
                <div className="burger-widget" onClick={() => changePage(value, false)}>
                    <button className="button-arrow">
                        <img className="button-arrow-header" src={arrow} alt="arrow"/>
                    </button>
                </div>
                <div className="main-widget-title title-stat-widget">
                    {`${title}: ${widgetName}`}
                </div>
            </div>
            {component}
        </div>
    )
}
