import React, {useState} from "react";
import {Switch} from "antd";
import {updateInputValue} from "../Debounce";
import {REPLY_TIMEOUT} from "../widget/Const"


export const HookSwitch = ({value, name, unitId}) => {

    const [timeoutId, setTimeoutId] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)

    React.useEffect(() => {
        setIsDisabled(false)
        stop()
    }, [value])


    function start() {
        if (timeoutId) {
            return;
        }
        setTimeoutId(setTimeout(() => {
            setIsDisabled(false)
            setTimeoutId(null)
        }, REPLY_TIMEOUT))
    }

    function stop() {
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(null)
        }
    }

    async function changeSwitch(key, value) {
        await updateInputValue(unitId, key, (value === 1 || value === 0) ? Number(!value) : !value)
        setIsDisabled(true)
        start()
    }

    return <Switch checked={value}
                   disabled={isDisabled}
                   onChange={() => changeSwitch(name, value)}
                   className="switch-ph"/>
}

