import React from 'react';
import {CloseOutlined} from "@ant-design/icons";
import logoFull from '../../../resources/images/logo-full.svg'
import logoTime from '../../../resources/images/time.svg'
import {Col, Row, Spin, Tooltip} from "antd";
import {Beaker} from "./Beaker";
import {Tank} from "./Tank";
import {ModeChange} from "./dialogs/mode/ModeChange";
import {ShowDialog} from "./dialogs/ShowDialog";
import {PhDialog} from "./dialogs/pH/PhDialog";
import {Timer} from "./dialogs/Timer";
import {ECDialog} from "./dialogs/EC/ECDialog";
import {TankDialog} from "./dialogs/tankIndicators/TankDialog";
import {SolutionTemperature} from "./dialogs/SolutionTemperature/SolutionTemperature";
import {Channel} from "./dialogs/tankIndicators/Channel";
import {ManualControlPH} from "./dialogs/manualControlPH/ManualControlPH";
import {ManualControlEC} from "./dialogs/manualControlEC/ManualControlEC";
import requestToAPI from "../../lib/Request";
import {subscribe} from "../../lib/RequestWS";
import {RecordIndicator} from "./dialogs/RecordIndicator";
import {loadPreference} from "../../lib/PreferenceStorage";

const selectedMode = {
    0: "Выключен",
    1: "Авария",
    2: "Включен",
    3: "Слив бака",
    4: "Слив каналов",
    5: "Слив системы"
}

const PonicsDefaultMonitor = {}

export const PonicsWidget = ({widgetId, deviceName, deviceId, closeWidget}) => {
    const [dialogs, changeDialog] = React.useState({
        mode: false, pH: false, timer: false,
        EC: false, tank: false, solution: false,
        channel: false, sampler: false, manualControl: false,
        manualControlEC: false
    });

    const [monitor, setMonitor] = React.useState(PonicsDefaultMonitor)
    const [dataMonitor, setDataMonitor] = React.useState([])
    const [control, setControl] = React.useState([])
    const [crashes, setCrashes] = React.useState([])
    const [contextParams] = React.useState({})
    const [trueCrashes, setTrueCrashes] = React.useState([])
    const [isFetching, setIsFetching] = React.useState(true)
    const userPref = loadPreference(widgetId);

    React.useEffect(() => {
        contextParams.mountFlag = true;
        if (requestToAPI.authToken) {
            if (!contextParams.mountFlag) return;
            subscribe(
                `subscription { 
                    deviceUnitByPk(id: ${deviceId}) { 
                        monitor { 
                            key
                            value 
                        }
                        model {
                          metadata
                        }
                    } 
                }`,
                (payload) => {
                    if (!contextParams.mountFlag) return;
                    let new_data = payload.data.deviceUnitByPk
                    if (new_data) {
                        var data = new_data.monitor.reduce(function (map, obj) {
                            map[obj.key] = obj.value;
                            return map;
                        }, {});
                        setMonitor(data)
                        setControl((new_data.model.metadata && new_data.model.metadata.control) ? new_data.model.metadata.control : [])

                        let metadata = new_data.model.metadata && new_data.model.metadata.monitor ? new_data.model.metadata.monitor : {};
                        setDataMonitor(metadata)

                        let crash = []
                        let trueCrash = []
                        for (let key in data) {
                            let obj = {}
                            if (key.substr(0, 5) === 'alarm') {
                                obj["state"] = data[key]
                                obj["name"] = key.substr(5, 4)
                                obj["meta"] = metadata[key]

                                crash.push(obj)
                                if (obj.state) {
                                    trueCrash.push(obj)
                                }
                            }
                        }
                        setTrueCrashes(trueCrash)
                        setCrashes(crash)
                        setIsFetching(false)
                    } else {
                        changeDialog({
                            mode: false, pH: false, timer: false,
                            EC: false, tank: false, solution: false,
                            channel: false, sampler: false, manualControl: false,
                            manualControlEC: false
                        })
                    }
                }
            )
        }
        return () => {
            contextParams.mountFlag = false
        }
    }, [])

    function random() {
        return Math.floor(Math.random() * 100000)
    }

    const changePage = (value, option) => {
        if (control.length === 0 || monitor.length === 0) {
            return
        }
        switch (value) {
            case "mode":
                changeDialog({...dialogs, mode: option})
                break
            case "pH":
                changeDialog({...dialogs, pH: option})
                break
            case "timer":
                changeDialog({...dialogs, timer: option})
                break
            case "EC":
                changeDialog({...dialogs, EC: option})
                break
            case "tank":
                changeDialog({...dialogs, tank: option})
                break
            case "solution":
                changeDialog({...dialogs, solution: option})
                break
            case "channel":
                changeDialog({...dialogs, channel: option})
                break
            case "sampler":
                changeDialog({...dialogs, sampler: option})
                break
            case "manualControl":
                changeDialog({...dialogs, manualControl: option})
                break
            case "manualControlEC":
                changeDialog({...dialogs, manualControlEC: option})
                break
            default:
                changeDialog({
                    mode: false, pH: false, timer: false,
                    EC: false, tank: false, solution: false,
                    channel: false, sampler: false, manualControl: false,
                    manualControlEC: false
                })
        }

    }

    return (
        <div className="main-widget">
            <div draggable="true" className={monitor.sysState === 1 ? "header-widget background-red" : "header-widget"}>
                <div/>
                <div className={monitor.sysState === 1 ? "main-widget-title background-red" : "main-widget-title"}>
                    {`${deviceName}`}
                </div>
                <div className="area-widget-close" onClick={() => {
                    closeWidget(widgetId)
                }}>
                    <CloseOutlined
                        className={monitor.sysState === 1 ? "widget-close-white widget-close" : "widget-close-main"}
                    />
                </div>

            </div>
            {
                isFetching
                    ? <Spin className="fetching-stat"/>
                    : <>
                        <Row draggable="false">
                            <Col span={12} className="left-side-widget">
                                <div onClick={() => changePage('pH', true)} className="record-box">

                                    <RecordIndicator massCurAlarm={['E501', 'E502',
                                        'E503', 'E600', 'E601']}
                                                     isOn={monitor.phIsOn}
                                                     massAlarm={trueCrashes}
                                                     name={'pH'}
                                    />
                                    <Tooltip placement="topRight" title={dataMonitor.tankPh
                                        ? `${(Math.round((monitor.tankPh) * 100) / 100).toFixed(1)} - ${dataMonitor.tankPh.title}`
                                        : 'Нет метаданных'}>
                                        <p className="num-record wrap-text">
                                            {monitor.tankPh == 0 || monitor.tankPh
                                                ? (Math.round((monitor.tankPh) * 100) / 100).toFixed(1)
                                                : "-"
                                            }
                                        </p>
                                    </Tooltip>
                                </div>
                                <div onClick={() => changePage('EC', true)} className="record-box">
                                    <RecordIndicator massCurAlarm={['E301', 'E302',
                                        'E304', 'E303', 'E305', 'E400', 'E401', 'E402']}
                                                     isOn={monitor.ecIsOn}
                                                     massAlarm={trueCrashes}
                                                     name={'EC'}
                                    />
                                    <Tooltip placement="topRight" title={dataMonitor.tankEc
                                        ? `${(Math.round((monitor.tankEc) * 100) / 100).toFixed(2)} мС/см - ${dataMonitor.tankEc.title}`
                                        : 'Нет метаданных'}>
                                        <p className="num-record wrap-text">
                                            {monitor.tankEc == 0 || monitor.tankEc
                                                ? (Math.round((monitor.tankEc) * 100) / 100).toFixed(2)
                                                : "-"
                                            }
                                        </p>
                                    </Tooltip>
                                </div>
                                <div onClick={() => changePage('solution', true)} className="record-box">
                                    <RecordIndicator massCurAlarm={['E700', 'E701']}
                                                     isOn={monitor.thermIsOn}
                                                     massAlarm={trueCrashes}
                                                     name={'°C'}
                                    />
                                    <Tooltip placement="topRight" title={dataMonitor.tankTemp
                                        ? `${(Math.round((monitor.tankTemp) * 100) / 100).toFixed(1)}°C - ${dataMonitor.tankTemp.title}`
                                        : 'Нет метаданных'}>
                                        <p className="num-record wrap-text">
                                            {monitor.tankTemp == 0 || monitor.tankTemp
                                                ? (Math.round((monitor.tankTemp) * 100) / 100).toFixed(1)
                                                : "-"
                                            }
                                        </p>
                                    </Tooltip>
                                </div>
                                <div className="mode-widget">
                                    <div className={monitor.sysState === 1 ? "mode-red" : null}
                                         onClick={() => changePage('mode', true)}>
                                        <div className={monitor.sysState === 0 ? "mode-grey" : null}>
                                            {monitor.length !== 0
                                                ? selectedMode[monitor.sysState] || <img src={logoFull} alt="Logotype"/>
                                                : <Spin/>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={12} className="right-side-widget">
                                <div className="right-indicators">
                                    <div className="indicator">
                                    </div>
                                    <div className="indicator">
                                        <Tooltip placement="top" title="Таймер полива">
                                            <img src={logoTime} alt="" className="table-editable-row"
                                                 onClick={() => changePage('timer', true)}/>
                                        </Tooltip>

                                    </div>
                                    <div className="indicator">
                                    </div>

                                </div>
                                <div className="canvas-indicators">
                                    <div className="table-editable-row tank" onClick={() => changePage('tank', true)}>
                                        <Tank key={random()} canvasName={`canvas-${random()}`}
                                              value={0} name="TANK" title='Бак'
                                              flag={contextParams.mountFlag}
                                              waterState={monitor.waterFSMState}
                                              curState={
                                                  String(Number(monitor.sysHasTank))
                                                  + String(Number(monitor.alarmE103))
                                                  + String(Number(monitor.alarmE100))
                                                  + String(Number(monitor.alarmE101))
                                                  + String(monitor.outTankIn)
                                                  + String(monitor.outTankOut)
                                                  + String(monitor.dinTankLower)
                                                  + String(monitor.dinTankUpper)
                                                  + String(monitor.dinTankAlarm)
                                                  + String(monitor.sysWait ? Number(monitor.sysWait) : 0)
                                              }
                                        />
                                    </div>
                                    <div className="table-editable-row tank" onClick={() => changePage('channel', true)}>
                                        <Tank key={random()} canvasName={`canvas-${random()}`}
                                              value={0} name="CHAN" title='Каналы'
                                              flag={contextParams.mountFlag}
                                              waterState={monitor.waterFSMState}
                                              curState={
                                                  String(Number(monitor.alarmE103))
                                                  + String(Number(monitor.sysHasChanLeft) && monitor.dinChanL)
                                                  + String(Number(monitor.sysHasChanRigth) && monitor.dinChanR)
                                                  + String(monitor.outWater)
                                                  + String(monitor.outChanOut)
                                                  + String(monitor.sysWait ? Number(monitor.sysWait) : 0)
                                              }
                                        />
                                    </div>
                                    <div className="table-editable-row tank">
                                        <Tank key={random()} canvasName={`canvas-${random()}`}
                                              value={100} name="PROB" title='Пробоотборник'
                                              flag={contextParams.mountFlag}
                                              waterState={monitor.waterFSMState}
                                              dinState={
                                                  {
                                                      alarmE103: monitor.alarmE103,
                                                      alarmE102: monitor.alarmE102,
                                                      dinSampler: monitor.dinSampler,
                                                      outSampler: monitor.outSampler,
                                                  }
                                              }
                                        />
                                    </div>

                                </div>
                                <div className="canvas-main-bottles">
                                    <div className="canvas-bottles">
                                        <div className="table-editable-row bottle"
                                             onClick={() => changePage('manualControl', true)}>
                                            <Beaker key={random()} canvasName={`canvas-${random()}`}
                                                    value={0} title="Компонент pH-"
                                                    text='pH-'
                                                    flag={contextParams.mountFlag}
                                                    curState={
                                                        String(Number(monitor.phSensUsedDown))
                                                        + String(Number(monitor.dinPhRdyDown))
                                                        + String(Number(monitor.outPhDown))
                                                    }/>
                                        </div>
                                        <div className="table-editable-row bottle"
                                             onClick={() => changePage('manualControl', true)}>
                                            <Beaker key={random()} canvasName={`canvas-${random()}`}
                                                    value={0}
                                                    text='pH+' title="Компонент pH+"
                                                    flag={contextParams.mountFlag}
                                                    curState={
                                                        String(Number(monitor.phSensUsedUp))
                                                        + String(Number(monitor.dinPhRdyUp))
                                                        + String(Number(monitor.outPhUp))
                                                    }/>
                                        </div>

                                    </div>
                                    <div className="canvas-bottles">
                                        <div className="table-editable-row bottle"
                                             onClick={() => changePage('manualControlEC', true)}>
                                            <Beaker key={random()} canvasName={`canvas-${random()}`}
                                                    value={0} title="Компонент удобрения A"
                                                    text='A'
                                                    flag={contextParams.mountFlag}
                                                    curState={
                                                        String(Number(monitor.ecSensUsedA))
                                                        + String(Number(monitor.dinEcRdyA))
                                                        + String(Number(monitor.outEcA))
                                                    }/>
                                        </div>
                                        <div className="table-editable-row bottle"
                                             onClick={() => changePage('manualControlEC', true)}>
                                            <Beaker key={random()} canvasName={`canvas-${random()}`}
                                                    value={0} title="Компонент удобрения B"
                                                    text='B'
                                                    flag={contextParams.mountFlag}
                                                    curState={
                                                        String(Number(monitor.ecSensUsedB))
                                                        + String(Number(monitor.dinEcRdyB))
                                                        + String(Number(monitor.outEcB))
                                                    }/>
                                        </div>
                                        <div className="table-editable-row bottle"
                                             onClick={() => changePage('manualControlEC', true)}>
                                            <Beaker key={random()} canvasName={`canvas-${random()}`}
                                                    value={0} title="Компонент удобрения C"
                                                    text='C'
                                                    flag={contextParams.mountFlag}
                                                    curState={
                                                        String(Number(monitor.ecSensUsedC))
                                                        + String(Number(monitor.dinEcRdyC))
                                                        + String(Number(monitor.outEcC))
                                                    }/>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {dialogs.pH ?
                            <ShowDialog
                                title={"pH"}
                                widgetName={deviceName}
                                isAlarm={monitor.sysState === 1}
                                number={widgetId}
                                value={"pH"}
                                changePage={changePage}
                                component={<PhDialog deviceId={widgetId} widgetName={deviceName} unitId={deviceId}
                                                     phCycleTm={monitor.phCycleTm} phIsOn={monitor.phIsOn}
                                                     phIsProtected={monitor.phIsProtected} phLower={monitor.phLower}
                                                     phUpper={monitor.phUpper} isAlarm={monitor.sysState === 1}
                                                     phCycleTmMin={control.phCycleTm.min}
                                                     phCycleTmMax={control.phCycleTm.max}
                                                     phLowerMax={control.phLower.max} phLowerMin={control.phLower.min}
                                                     phUpperMax={control.phUpper.max} phUpperMin={control.phUpper.min}/>
                                }
                            />
                            : null}
                        {dialogs.mode ?
                            <ShowDialog
                                title={"Режим E-MODE"}
                                isAlarm={monitor.sysState === 1}
                                widgetName={deviceName}
                                number={widgetId}
                                value={"mode"}
                                changePage={changePage}
                                component={<ModeChange isAlarm={monitor.sysState === 1}
                                                       deviceId={widgetId}
                                                       widgetName={deviceName}
                                                       crashes={crashes}
                                                       sysState={monitor.sysState}
                                                       unitId={deviceId}
                                />}/>
                            : null}
                        {dialogs.timer ?
                            <ShowDialog
                                title={"Таймер"}
                                isAlarm={monitor.sysState === 1}
                                widgetName={deviceName}
                                number={widgetId}
                                value={"timer"}
                                changePage={changePage}
                                component={<Timer sysPauseTm={monitor.sysPauseTm} sysWaterTm={monitor.sysWaterTm}
                                                  sysPauseTmControl={control.sysPauseTm} unitId={deviceId}
                                                  sysWaterTmControl={control.sysWaterTm}
                                                  sysWaterOnPrep={monitor.sysWaterOnPrep}
                                />}/>
                            : null}
                        {dialogs.EC ?
                            <ShowDialog
                                title={"EC"}
                                isAlarm={monitor.sysState === 1}
                                widgetName={deviceName}
                                number={widgetId}
                                value={"EC"}
                                changePage={changePage}
                                component={<ECDialog isAlarm={monitor.sysState === 1} unitId={deviceId}
                                                     phCycleTmMin={control.phCycleTm.min}
                                                     phCycleTmMax={control.phCycleTm.max}
                                                     deviceId={widgetId} widgetName={deviceName}
                                                     ecIsOn={monitor.ecIsOn} ecIsProtected={monitor.ecIsProtected}
                                                     ecCycleTm={monitor.ecCycleTm} ecSp={monitor.ecSp}
                                                     controlECProg={control.ecProgId.values} ecProgId={monitor.ecProgId}
                                                     ecA={monitor.ecA} ecB={monitor.ecB} ecC={monitor.ecC}/>}
                            />
                            : null}
                        {dialogs.tank ?
                            <ShowDialog
                                title={"Бак"}
                                isAlarm={monitor.sysState === 1}
                                widgetName={deviceName}
                                number={widgetId}
                                value={"tank"}
                                changePage={changePage}
                                component={<TankDialog sysHasTank={monitor.sysHasTank} unitId={deviceId}/>}/>
                            : null}
                        {dialogs.solution ?
                            <ShowDialog
                                title={"Т. раствора"}
                                isAlarm={monitor.sysState === 1}
                                widgetName={deviceName}
                                number={widgetId}
                                value={"solution"}
                                changePage={changePage}
                                component={<SolutionTemperature widgetName={deviceName}
                                                                isAlarm={monitor.sysState === 1}
                                                                deviceId={widgetId}
                                                                unitId={deviceId}
                                                                thermIsOn={monitor.thermIsOn}
                                                                thermLower={monitor.thermLower}
                                                                thermUpper={monitor.thermUpper}/>}/>
                            : null}
                        {dialogs.channel ?
                            <ShowDialog
                                title={"Каналы"}
                                isAlarm={monitor.sysState === 1}
                                widgetName={deviceName}
                                number={widgetId}
                                value={"channel"}
                                changePage={changePage}
                                component={<Channel unitId={deviceId} sysHasChanRigth={monitor.sysHasChanRigth}
                                                    sysHasChanLeft={monitor.sysHasChanLeft}/>}/>
                            : null}
                        {/*{dialogs.sampler ?*/}
                        {/*    <ShowDialog*/}
                        {/*        title={"Пробоотборник"}*/}
                        {/*        isAlarm={monitor.sysState === 1}*/}
                        {/*        widgetName={deviceName}*/}
                        {/*        number={widgetId}*/}
                        {/*        value={"sampler"}*/}
                        {/*        changePage={changePage}*/}
                        {/*        component={<Sampler unitId={deviceId} tankTemp={monitor.tankTemp}/>}/>*/}
                        {/*    : null}*/}
                        {dialogs.manualControl ?
                            <ShowDialog
                                title={"Ручное управление pH"}
                                isAlarm={monitor.sysState === 1}
                                widgetName={deviceName}
                                number={widgetId}
                                value={"manualControl"}
                                changePage={changePage}
                                component={<ManualControlPH deviceId={widgetId} widgetName={deviceName}
                                                            taskPhDown={monitor.taskPhDown} unitId={deviceId}
                                                            taskPhUp={monitor.taskPhUp} tankPh={monitor.tankPh}
                                                            taskPhDownControl={control.taskPhDown}
                                                            taskPhUpControl={control.taskPhUp}
                                                            isAlarm={monitor.sysState === 1}
                                                            phSensUsedUp={monitor.phSensUsedUp}
                                                            phSensUsedDown={monitor.phSensUsedDown}
                                                            phAgentUsedUp={monitor.phAgentUsedUp}
                                                            phAgentUsedDown={monitor.phAgentUsedDown}
                                />}/>
                            : null}
                        {dialogs.manualControlEC ?
                            <ShowDialog
                                title={"Ручное управление EC"}
                                isAlarm={monitor.sysState === 1}
                                widgetName={deviceName}
                                number={widgetId}
                                value={"manualControlEC"}
                                changePage={changePage}
                                component={<ManualControlEC
                                    deviceId={widgetId}
                                    taskEcA={monitor.taskEcA} taskEcB={monitor.taskEcB} unitId={deviceId}
                                    taskEcC={monitor.taskEcC} taskEcAControl={control.taskEcA}
                                    taskEcBControl={control.taskEcB}
                                    taskEcCControl={control.taskEcC} tankEc={monitor.tankEc}
                                    isAlarm={monitor.sysState === 1}
                                    widgetName={deviceName}
                                    ecSensUsedA={monitor.ecSensUsedA}
                                    ecSensUsedB={monitor.ecSensUsedB}
                                    ecSensUsedC={monitor.ecSensUsedC}
                                    ecNutrUsedA={userPref && userPref.manualControlEC ? userPref.manualControlEC.taskEcA : monitor.taskEcA.min}
                                    ecNutrUsedB={userPref && userPref.manualControlEC ? userPref.manualControlEC.taskEcB : monitor.taskEcB.min}
                                    ecNutrUsedC={userPref && userPref.manualControlEC ? userPref.manualControlEC.taskEcC : monitor.taskEcC.min}
                                />}/>
                            : null}
                    </>
            }
        </div>
    )
}
