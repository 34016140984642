import arrow from "../../../../../../resources/images/Arrow-right.svg";
import React, {useState} from "react";
import {ShowDialog} from "../../ShowDialog";
import {SettingSelection} from "./SettingSelection";
import {Prog} from "./Prog";
import {updateInputValue} from "../../../../Debounce";


export const ProgramSelection = ({ isAlarm, unitId, ecA, ecB, ecC,
                                     deviceId, deviceName, ecSp, ecProgId, controlECProg
                                 }) => {

    const [value, setValue] = useState(controlECProg[ecProgId])
    const [dialogs, changeDialog] = React.useState({
        setting: false,
    });

    function setProgram(value, name) {
        setValue(name)
        updateInputValue(unitId, 'ecProgId', value)
    }

    const changePage = (value, option) => {

        switch (value) {
            case "setting":
                changeDialog({...dialogs, setting: option})
                break
            default:
                changeDialog({
                    mode: false
                })
        }

    }
    return (
        <div className="program-selection">
            <div className="menu-selection-box">
                {Object.entries(controlECProg).map((prog, index) => {
                return <Prog key={index} name={prog[1]} id={prog[0]} className={"row-ph-dialog"}
                             setProgram={setProgram} ecProgId={ecProgId}/>
            })}
                {/*<div className="row-ph-dialog">*/}
                {/*    <p onClick={() => setValue('Рассада')}*/}
                {/*       className={value === 'Рассада' ? "row-p-value" : "row-p-white"}>Рассада</p>*/}
                {/*</div>*/}
                {/*<div className="row-ph-dialog">*/}
                {/*    <p onClick={() => setValue('Урожай')}*/}
                {/*       className={value === 'Урожай' ? "row-p-value" : "row-p-white"}>Урожай</p>*/}
                {/*</div>*/}
                {/*<div className="row-ph-dialog">*/}
                {/*    <p onClick={() => setValue('Вегетация')}*/}
                {/*       className={value === 'Вегетация' ? "row-p-value" : "row-p-white"}>Вегетация</p>*/}
                {/*</div>*/}
                {/*<div className="row-ph-dialog">*/}
                {/*    <p onClick={() => setValue('Четвертая')}*/}
                {/*       className={value === 'Четвертая' ? "row-p-value" : "row-p-white"}>Четвертая</p>*/}
                {/*</div>*/}
                {/*<div className="row-ph-dialog">*/}
                {/*    <p onClick={() => setValue('Пятая')}*/}
                {/*       className={value === 'Пятая' ? "row-p-value" : "row-p-white"}>Пятая</p>*/}
                {/*</div>*/}
            </div>
            <div className="row-selection">
                <div className="row-ph-dialog">
                    <p>Настройка</p>
                    <button className="button-arrow">
                        <div className="logo-widget">
                            <img onClick={() => changePage('setting', true)} src={arrow} alt="arrow"/>
                        </div>
                    </button>
                </div>
                <div className="row-ph-dialog">
                    <p>Уровень ЕС</p>
                    <p className="row-p-value">{Math.round((ecSp) * 100) / 100}</p>
                </div>
                <div className="row-ph-dialog">
                    <p>Удобрение A</p>
                    <p className="row-p-value">{ecA || 0}</p>
                </div>
                <div className="row-ph-dialog">
                    <p>Удобрение B</p>
                    <p className="row-p-value">{ecB || 0}</p>
                </div>
                <div className="row-ph-dialog">
                    <p>Удобрение C</p>
                    <p className="row-p-value">{ecC || 0}</p>
                </div>
            </div>
            {dialogs.setting ?
                <ShowDialog
                    isAlarm={isAlarm}
                    title={`Настройка: ${value}`}
                    number={deviceId}
                    widgetName={deviceName}
                    value={"setting"}
                    changePage={changePage}
                    component={<SettingSelection ecSp={ecSp} ecA={ecA} ecB={ecB} ecC={ecC} unitId={unitId}/>}/>
                : null}
        </div>

    )
}