export const QUERY_GET_TOKEN = `
    mutation sysUserAuth($userLogin: String!, $password: String!) {
        sysUserPasswdAuth(login: $userLogin, password: $password) {
            id
            userLogin
            userName
            authToken
        }
    }
`
export const QUERY_GET_TOKEN_DEVUSER = `
    mutation devUserAuth($userLogin: String!, $password: String!) {
        devUserPasswdAuth(login: $userLogin, password: $password) {
            id
            userLogin
            userName
            authToken
        }
    }
`

export const MUT_REGISTER_DEV_USER = `
    mutation registerDevUser($login: String!, $password: String!, $email: String ) {
        return:registerDevUserWithPasswd(email: $email, login: $login, password: $password) {
            userLogin
            userName
            authToken
            id
        }
    }
`

export const QUERY_RENEW_TOKEN_DEVUSER = `
    mutation authTokenRenew {
    authTokenRenew {
      authToken
    }
  }
`


export const QUERY_GET_DEVUSER = `
    query ($id: bigint!) {
        record: devUserByPk(id: $id) {
            login
            id
            registered_in_seconds
            patronymic
            lastName
            firstName
            ident {
                devUser {
                    email
                    phone
                }
            }
        }
    }
`

export const QUERY_GET_LIST_DEVUSER = `
    query DevUser($order_by: [DevUser_order_by!] = {}, $limit: Int = 10, $offset: Int = 0, $where: DevUser_bool_exp = {}) {
        records:devUser(order_by: $order_by, limit: $limit, offset: $offset, where: $where) {
            firstName
            id
            lastName
            login
            patronymic
            registered_in_seconds
            ident {
                blocked
                devUser {
                    email
                    phone
                }
            }
        }
        recordCount:devUserAggregate(where: $where) {
            aggregate {
                count
            }        
        }
    }
`

export const QUERY_GET_LIST_LOG = `
    query LogEvent($order_by: [LogEvent_order_by!] = {}, $limit: Int = 10, $offset: Int = 0, $where: LogEvent_bool_exp = {}) {
        records:logEvent(order_by: $order_by, limit: $limit, offset: $offset, where: $where) {
            id
            note
            level
            type {
                id
                name
            }
            tsInSeconds
            ident {
                id
                sysUser {
                   login
                }
                devUser {
                   login
                }
              }
        }
        recordCount:logEventAggregate(where: $where) {
            aggregate {
                count
            }        
        }
        
    }
`

export const QUERY_GET_LOG = `
    query LogEvent($id: bigint!) {
        record:logEventByPk(id: $id) {
            id
            level
            note
            type {
                name
                id
            }
            ts_in_seconds
            ident {
                sysUser {
                    login
                }
                devUser {
                    login
                }
            }
        }
    }
`

export const QUERY_GET_LIST_SYSUSER = `
    query SysUser($order_by: [SysUser_order_by!] = {}, $limit: Int = 10, $offset: Int = 0, $where: SysUser_bool_exp = {}) {
        records:sysUser(order_by: $order_by, limit: $limit, offset: $offset, where: $where) {
            firstName
            id
            lastName
            login
            patronymic
            registered_in_seconds
            ident {
              blocked
              sysUser {
                email
                phone
              }
            }
        }
        recordCount:sysUserAggregate(where: $where) {
            aggregate {
              count
            }        
        }
    }
`

export const QUERY_GET_SYSUSER =  `
    query SysUser($id: bigint!) {
        record:sysUserByPk(id: $id) {
            login
            registered_in_seconds
            patronymic
            lastName
            id
            firstName
            ident {
                blocked
                sysUser {
                    email
                    phone
                  }
                }
        }
    }

`

export const MUT_INS_SYSUSER =  `
    mutation InsertSysUser($login: String!, $firstName: String!, $lastName: String!, $patronymic: String!, $ident_sysUser_email: String!, $ident_sysUser_phone: String!) {
        record:insertSysUserOne(object: {login: $login, firstName: $firstName, lastName: $lastName, patronymic: $patronymic, email: $ident_sysUser_email, phone: $ident_sysUser_phone}) {
            id
            login
            firstName
            lastName
            patronymic
            ident {
                blocked
                sysUser {
                    email
                    phone
                }
            }
            registeredInSeconds
        }
    }
`
//Внимание! Точки заменяются на подчеркивания, поэтому ident.sysUser.email становится ident_sysUser_email
export const MUT_UPD_SYSUSER =  `
    mutation updateSysUser($id:bigint!, $ident_sysUser_email: String, $firstName: String, $lastName: String, $patronymic: String, $ident_sysUser_phone: String, $ident_blocked: Boolean) {

        updateAccessIdentByPk(pk_columns:{id:$id},_set: {blocked: $ident_blocked}) {
            id
        }

        record: updateSysUserByPk(pk_columns:{id:$id}, _set: {email: $ident_sysUser_email, firstName: $firstName, lastName: $lastName, patronymic: $patronymic, phone: $ident_sysUser_phone}) {
            id
            firstName
            lastName
            login
            patronymic
            registered_in_seconds
            ident {
                blocked
                sysUser {
                    email
                    phone
                }
            }
        }
        
    }
    
`
export const MUT_UPD_SYSUSER_WITHOUT_BLOCK =  `
    mutation updateSysUser($id:bigint!, $ident_sysUser_email: String, $firstName: String, $lastName: String, $patronymic: String, $ident_sysUser_phone: String) {
        record: updateSysUserByPk(pk_columns:{id:$id}, _set: {email: $ident_sysUser_email, firstName: $firstName, lastName: $lastName, patronymic: $patronymic, phone: $ident_sysUser_phone}) {
            id
            firstName
            lastName
            login
            patronymic
            registered_in_seconds
            ident {
                blocked
                sysUser {
                    email
                    phone
                }
            }
        }
        
    }
    
`

export const MUT_UPD_DEVUSER_WITHOUT_BLOCK =  `
    mutation ($id:bigint!, $firstName: String, $lastName: String, $patronymic: String) {
        record: updateDevUserByPk(pk_columns:{id:$id}, _set: {firstName: $firstName, lastName: $lastName, patronymic: $patronymic}) {
            id
            firstName
            lastName
            login
            patronymic
            registered_in_seconds,
                ident {
                    devUser {
                        phone
                        email
                    }
                }
            }
    }    
`

export const MUT_SET_SYSUSER_PASSWORD = `
    mutation ($id: bigint!, $newPassword: String!) {
        setSysUserPasswd(password: $newPassword, userId: $id) {
            changed
        }
    }
`

export const MUT_SET_DEVUSER_PASSWORD = `
    mutation ($newPassword: String!) {
        setDevUserPasswd(password: $newPassword) {
            changed
        }
    }
`

export const MUT_INS_DEVUSER =  `
    mutation InsertDevUser($login: String!, $firstName: String!, $lastName: String!, $patronymic: String!, $ident_devUser_email: String!, $ident_devUser_phone: String!) {
        records: insertDevUserOne(object: {login: $login, firstName: $firstName, lastName: $lastName, patronymic: $patronymic, email: $ident_devUser_email, phone: $ident_devUser_phone}) {
            id
            login
            firstName
            lastName
            patronymic
            registeredInSeconds
            ident {
                devUser {
                    email
                    phone
                }
            }
        }
    }
`

export const MUT_UPD_DEVUSER = `
    mutation updateDevUser($id:bigint!, $ident_devUser_email: String, $firstName: String, $lastName: String, $patronymic: String, $ident_devUser_phone: String, $ident_blocked: Boolean) {
        updateAccessIdentByPk(pk_columns:{id: $id}, _set: {blocked: $ident_blocked}) {
            id
        }
        record: updateDevUserByPk(pk_columns:{id:$id}, _set: {email: $ident_devUser_email, firstName: $firstName, lastName: $lastName, patronymic: $patronymic, phone: $ident_devUser_phone}) {
            id
            firstName
            lastName
            login
            patronymic
            registered_in_seconds
            ident {
                blocked
                devUser {
                    email
                    phone
                }
            }
        }
    }
`

export const QUERY_GET_AUDIT_TYPE = `
    query{
        records:logEventType {
            id
            name
        }
    }
`

export const QUERY_GET_LIST_ALLUSER = `
    query ($search: String!) {
        records:accessIdent(where: {
            blocked: {_eq: false}, 
            _or: [{
                    sysUser: {allText: {_like: $search}},
                }, {
                    devUser:{allText:{_like:$search}}
                }
            ]
        }) 
        {
            id
            sysUser {
                login
                firstName
                lastName      
                email
            }
            devUser {
                login
                firstName
                lastName
                email
            }
        }
    }
`

export const QUERY_GET_LIST_MESSAGE = `
    query MessageTemplate($order_by: [MessageTemplate_order_by!] = {}, $limit: Int = 10, $offset: Int = 0, $where: MessageTemplate_bool_exp = {}) {
        records: messageTemplate(order_by: $order_by, limit: $limit, offset: $offset, where: $where) {
            id
            code
            title
            text
        }
        recordCount:messageTemplateAggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`

export const QUERY_GET_MESSAGE = `
    query MessageTemplate($id: bigint!) {
        record: messageTemplateByPk(id: $id) {
            code
            id
            text
            title
        }
    }
`

export const MUT_INS_MESSAGE = `
    mutation InsertMessageTemplateOne($text: String!, $title: String!, $code: String!) {
        records:insertMessageTemplateOne(object: {text: $text, title: $title, code: $code}) {
            id
            text
            title
            code
        }
    }
`

export const MUT_UPD_MESSAGE = `
    mutation UpdateMessageTemplate($id: bigint!, $code: String!, $text: String!, $title: String!) {
        records: updateMessageTemplateByPk(pk_columns: {id: $id}, _set: {code: $code, text: $text, title: $title}) {
            id
            code
            text
            title
        }
    }
`

export const MUT_DEL_MESSAGE = `
    mutation ($ids:[bigint!]){
        delete:deleteMessageTemplate(where: {id: {_in: $ids}}) {
            returning {
                id
            }
        }
    }
`

export const SESSION_DEL = `
     mutation ($ids: [String!]!) {
      revokeAuthTokens(tokenIds: $ids) {
        tokenIds
      }
    }
`

export const MUT_SET_SYSUSER_SETTING = `
    mutation ($id: bigint!, $newPassword: String!) {
        setSysUserPasswd(password: $newPassword, userId: $id) {
            changed
        }
    }
`
export const MUT_SET_DEVUSER_SETTING = `
    mutation ($id: bigint!, $newPassword: String!) {
        setDevUserPasswd(password: $newPassword, userId: $id) {
            changed
        }
    }
`

export const MUT_DEVUSER_SET_EMAIL =`
    mutation ($email: String!) {
        setDevUserEmail(email: $email) {
            emailToReplace
            emailToSet
        }
    }
`

export const QUERY_GET_LIST_DEVICE = `
    query ($order_by: [DeviceUnit_order_by!] = {}, $limit: Int = 10, $offset: Int = 0, $where: DeviceUnit_bool_exp = {}) {
        records: deviceUnit(order_by: $order_by, limit: $limit, offset: $offset, where: $where) {
            id
            number
            manufacturedInSeconds
            model {
                id
                name
                note
                docs {
                    id
                    note
                    version
                    type {
                        id
                        code
                    }
                    file {
                        id
                        mimeType
                        name
                        size
                        url
                    }
                }
            }
            notes {
                note
                id
            }
        }
        recordCount: deviceUnitAggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`

export const QUERY_GET_DEVICE = `
    query ($id: bigint!) {
        record: deviceUnitByPk(id: $id) {
            id
            number
            owner {
                login
            }
            manufacturedInSeconds
            model {
                name
                id
                note
                docs {
                    version
                    note
                    id
                    file {
                        mimeType
                        name
                        size
                        url
                    }
                    type {
                        id
                        code
                    }
                }
            }
            notes {
                note
                id
            }
        }
    }  
`

export const MUT_INS_DEVICE = `
    mutation ($pin: String!) {
        record: activateUnitByPin(pin: $pin) {
            unit {
                id
                number
                model {
                    id
                    name
                }
                manufacturedInSeconds
                owner {
                    login
                }
                notes {
                    id
                    note
                }
            }
        }
    }
`

export const MUT_UPD_DEVICE = `
    mutation ($id: bigint!, $notes_note: String) {
        record: updateDeviceUnitUserNoteByPk(pk_columns: {id: $id}, _set: {note: $notes_note}) {
            unit {
                number
                manufacturedInSeconds
                notes {
                    note
                    id
                }
                model {
                    name
                }
                id
            }
        }
    }
`

export const QUERY_DETACH_DEVICE =`
    mutation ($ids: [bigint!]!) {
      detachUnits(unitIds: $ids) {
        unitIds
      }
    }
`


export const QUERY_GET_SESSION_LIST = `
    query ($id: bigint!) {
        records: accessIdentToken(order_by: [{accessInSeconds:desc},{agent:asc}], 
                 where: {ident:{devUser:{id:{_eq:$id}}}}) {
            id
            agent
            addr
            ident {
                devUser {
                    login
                    id
                }
            }
            accessInSeconds
        }
    }
`
export const MUT_REVOKE_TOKEN = `
    mutation {
        revokeAuthToken {
            code
        }
    } 
`

export const MUT_INS_TYPEDEVICE = `
    mutation ($name: String!, $note:String) {
        record: insertDeviceModelOne(object: {name: $name, note: $note}) {
            id
            name
            docs {
                file {
                  url
                  name
                  mimeType
                }
                type {
                    code
                }
            }
        }
    }
`

export const MUT_INS_TYPEDEVICE_DOC = `
    mutation ($insfiles_obj:[DeviceDoc_insert_input!]!) {
        records:insertDeviceDoc(objects: $insfiles_obj) {
            returning {
                id
                file {
                    mimeType
                    name
                    url
                }
                type {
                    code
                }
            }
        }
    }
`


export const QUERY_GET_LIST_DEVICE_WIDGET = `
    query ($order_by: [DeviceUnit_order_by!] = {}, $limit: Int = 10, $offset: Int = 0, $where: DeviceUnit_bool_exp = {}) {
        records: deviceUnit {
            id
            manufacturedInSeconds
            number
            note
            model {
                name
                widgets {
                    type {
                        id
                        code
                        note
                    }
                }
            }
            notes {
              note
            }
        }
    }
`

export const QUERY_GET_SETTINGS_WIDGET = `
    query ($id: bigint!) {
        dashboardWidgetByPk(id: $id) {
            setting
        }
    } 
`


export const QUERY_GET_DASHBOARD_WIDGETS = `
    query ($id: bigint!) {
        records: dashboardWidget(where: {userId: {_eq: $id}}) {
            id
            setting
            type {
                code
                id
                note
            }
            unit {
                note
                number
                manufacturedInSeconds
                notes {
                    note
                }
            }
            typeId
            unitId
        }
    }
    
`

export const MUT_INS_DASHBOARD_WIDGET = `
    mutation ($pos: bigint!, $filters: [String!]!, $beg: timestamp, $end: timestamp, $typeId: bigint!, $unitId: bigint!) {
        insertDashboardWidgetOne(object: {setting: {filters: $filters, beg: $beg, end: $end, pos: $pos}, typeId: $typeId, unitId: $unitId}) {
            id
        }
    }
`

export const MUT_DEL_WIDGET = `
    mutation ($id: bigint!) {
    deleteDashboardWidgetByPk(id: $id) {
        unitId
        }
    }

`


// export const MUT_UPD_POS_WIDGET = `
//     mutation ($id: bigint!, $pos: bigint!) {
//         record: updateDashboardWidgetByPk(pk_columns: {id: $id}, _set: {setting: {pos: $pos}}) {
//             id
//             setting
//         }
//     }
// `

export const MUT_UPD_SETTINGS_WIDGET = `
    mutation ($id: bigint!, $filters: [String!]!, $beg: String, $end: String, $pos: bigint!) {
        record: updateDashboardWidgetByPk(pk_columns: {id: $id}, _set: {setting: {filters: $filters, beg: $beg, end: $end, pos: $pos}}) {
            id
            setting
        }
    }
`

export const MUT_UPD_VALUE_WIDGET = `
    mutation ($unitId: bigint!, $key: String!, $value: json!) {
        setUnitControlAttr(key: $key, unitId: $unitId, value: $value) {
            value
        }
    }
`
export const QUERY_GET_HISTORY =  `
    query GetHistory($unit: bigint!, $beg: timestamp!, $end: timestamp!, $key: String!) {
      before: deviceUnitMonitorAttrHistory(where: {attr: {unitId: {_eq: $unit}, key: {_eq: $key}}, ts: {_lt: $beg}}, order_by: {ts: desc}, limit: 1) {
        value
        ts
        attr {
            key
        }
      }
      body: deviceUnitMonitorAttrHistory(where: {attr: {unitId: {_eq: $unit}, key: {_eq: $key}}, ts: {_gte: $beg, _lt: $end}}, order_by: {ts: asc}) {
        value
        ts
        attr {
          key
        }
      }
      after: deviceUnitMonitorAttrHistory(where: {attr: {unitId: {_eq: $unit}, key: {_eq: $key}}, ts: {_gte: $end}}, order_by: {ts: asc}, limit: 1) {
        value
        ts
        attr {
          key
        }
      }
    }

`

// export const MUT_UPD_VALUE_WIDGET = `
//     mutation ($unitId: bigint!, $key: String!, $value: jsonb!) {
//         updateDeviceUnitMonitorAttr(where: {key: {_eq: $key}, unitId: {_eq: $unitId}}, _set: {value: $value}) {
//             returning {
//                 value
//             }
//         }
//     }
// `

// mutation insertDeviceUnitMonitor($attrId: bigint!, $id: bigint!, $ts: timestamp!, $value: jsonb!) {
//     insertDeviceUnitMonitorAttrHistoryOne(object: {attrId: $attrId, id: $id, ts: $ts, value: $value}) {
//         attrId
//         id
//         ts
//         tsInSeconds
//         value
//     }
// }
// {
//     "ts": "2022-05-04T14:33:54.107501",
//     "attrId": 1731,
//     "value": 2.5,
//     "id": 1

// }
export const QUERY_GET_WIDGET_INFO = `
    query getWidgetInfo($id: bigint!) {
        dashboardWidgetByPk(id: $id) {
            setting
            id
        }
    }
`

export const QUERY_GET_DEVICE_MONITOR = `
    query ($id: bigint!) {
        record: deviceUnitByPk(id: $id) {
            monitor { 
                updated
                key
                value 
            }
        }    
    }  
`
export const MUT_ASK_DEV_USER_PASSWD_RESET = `
    mutation ($email: String!) {
        askDevUserPasswdReset(email: $email) {
            email
        }
    }
`

export const MUT_RESET_DEV_USER_PASSWD = `
    mutation ($code: String!, $password: String!) {
        resetDevUserPasswd(code: $code, password: $password) {
            login
        }
    }
`
export const QUERY_GET_LIST_USER_LOG = `
    query ($order_by: [DevUserLogEvent_order_by!] = {}, $limit: Int = 10, $offset: Int = 0, $where: DevUserLogEvent_bool_exp = {}) {
        records: devUserLogEvent(order_by: $order_by, limit: $limit, offset: $offset, where: $where) {
            id
            created
            createdInSeconds
            level
            type
            data
            unit {
              id
              number
              notes {
                note
              }
            }
            note
        }
    }
`
export const QUERY_GET_USER_LOG = `
    query ($id: bigint!) {
        record:devUserLogEventByPk(id: $id) {
            id
            created
            createdInSeconds
            level
            type
            data
            unit {
                id
                number
            }
            note
        }
    }
`
export const MUT_UPD_USER_LOG = `
    mutation ($id: bigint!, $note: jsonb!) {
        record: updateDevUserLogEventByPk(pk_columns: {id: $id}, _set: {note: $note}) {
            id
            createdInSeconds
            type
            unit {
                id
                number
            }
            note
        }
    }
`