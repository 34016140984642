import React from 'react';
import { Button, Form, Input } from 'antd';
import { resq$ } from 'resq'
import requestToAPI from "./Request";
import { MUT_RESET_DEV_USER_PASSWD } from "./Query"

let _activeValidator;

export const ResetDevUserPasswd = (props) => {
    const [form] = Form.useForm();
    const firstInputRef = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [controlCase, setControlCase] = React.useState(false);

    const handleKeyDown = (ev) => {
        if (ev.which == 13) {
            let root = document.getElementById("root");
            const okBtn = resq$('Button', root).byProps({ type: "primary" });
            okBtn.props.onClick(okBtn);
        }
    }

    _activeValidator = form;
    const change = () => {
        _activeValidator.setFieldsValue({ error: undefined });
        return _activeValidator.validateFields()
            .then((values) => {
                setLoading(true);
                if (values.newPassword !== values.confirmNewPassword) {
                    _activeValidator.error = "Подтверждение пароля не совпадает с новым паролем";
                    _activeValidator.setFieldsValue({ error: _activeValidator.error });
                    throw _activeValidator.error;
                }
                return requestToAPI.post(MUT_RESET_DEV_USER_PASSWD, {
                    code: props.code, 
                    password: values.newPassword
                })
            })
            .then(response => {
                setLoading(false);
                setControlCase(true);
            })
            .catch((error) => {
                setLoading(false);
                if (error.message) {
                    _activeValidator.setFieldsValue({ error: error.message });
                }
                throw error;
            });
    }

    switch (controlCase) {
        case true:
            return <React.Fragment>
                    <div className='formForgetPassword successfulForgetPassword'>
                        <h1 className="h1ForgetPassword">ВОССТАНОВЛЕНИЕ ПАРОЛЯ</h1>
                        <div className='contentForgetPassword'>
                            <span className="email-pass-recovery">Новый пароль пользователя успешно установлен</span>
                            <Button type="primary" onClick={() => document.location.href = '/'} >Продолжить</Button>
                        </div>
                    </div>
        </React.Fragment>
        default:
            return  <React.Fragment>
                    <div onKeyDown={handleKeyDown} className='formForgetPassword'>
                    <h1 className="h1ForgetPassword">ВОССТАНОВЛЕНИЕ ПАРОЛЯ</h1>
                        <div className='contentForgetPassword'>
                            <Form
                                layout={"vertical"}
                                form={form}
                                name="formCreateNewPassword"
                                style={{ padding: 20 }}
                                initialValues={{}}>
                                
                                <Form.Item
                                    name="newPassword"
                                    label="Задайте новый пароль для пользователя"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Новый пароль не может быть пустым",
                                        }
                                    ]}>
                                    <Input.Password ref={firstInputRef} placeholder="Пароль"/>
                                </Form.Item>
                                
                                <Form.Item
                                    name="confirmNewPassword"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Подтверждение пароля не может быть пустым",
                                        }
                                    ]}>
                                    <Input.Password ref={firstInputRef} placeholder="Повторите пароль"/>
                                </Form.Item>

                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => prevValues.error !== currentValues.error}>
                                    {
                                        ({ getFieldValue }) =>
                                            getFieldValue('error') ? (
                                                <div className="ant-form-item ant-form-item-explain ant-form-item-explain-error">{getFieldValue('error')}</div>
                                            ) : null
                                    }
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" onClick={change} loading={loading}>Задать пароль</Button>
                                </Form.Item>
                                </Form>
                        </div>
                    </div>
                    </React.Fragment>
    }
}