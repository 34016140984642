import {PonicsWidget} from "./PonicsWidget";
import {SensiroomH} from "./SensiroomH";
import {SensiroomCO2} from "./SensiroomCO2/SensiroomCO2";
import {ClassStat} from "./PonicPilotStat/ClassStat";
import {WidgetPHESP} from "./WidgetPHESP/WidgetPHESP";
import { Zenith } from "./Zenith/Zenith";

// Связывание кодов виджетов с компонентами
const WIDGETS = {
    'ponics_pilot_basic': PonicsWidget,
    'ponics_pilot_test': PonicsWidget,
    'ponics_pilot_test_2': PonicsWidget,
    'sensiroom_h': SensiroomH,
    'ponics_pilot_time_series': ClassStat,
    'sensiroom_co2': SensiroomCO2,
    'sensiroom_co2_time_series': ClassStat,
    'sensiroom_h_time_series': ClassStat,
    'ph_esp': WidgetPHESP,
    'zenith': Zenith,
    'ph_esp_series': ClassStat,
    'zenith_series': ClassStat,
}

export const Widget = ({type, code, widget, closeWidget}) => {
    const WidgetClass = WIDGETS[code]
    return (
        <WidgetClass
            widget={widget}
            key={widget.id}
            deviceId={widget.unitId}
            widgetId={widget.id}
            closeWidget={closeWidget}
            deviceName={widget.unit != null || undefined ?
                `${widget.unit.notes[0].note ? widget.unit.notes[0].note : widget.unit.number}: ${widget.type.note}`
                : `null`}
            code={code}
        />
    )
}
