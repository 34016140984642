import React, { useEffect, useState } from "react";
import { ShowDialog } from "../dialogs/ShowDialog";
import { CrashView } from "../dialogs/mode/CrashView";
import { updateInputValue } from "../../Debounce";

const MAIN_PAGE = 1;
const CRASH_PAGE = 2;

export const RegulatorStateDialog = ({ deviceId, widgetName, crashes, isAlarm, unitId, phIsOn }) => {
    const [page, setPage] = React.useState(MAIN_PAGE);
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        setIsDisabled(false)
    }, [phIsOn])

    function changePhIsOn(key, value) {
        if (value != phIsOn && !isDisabled) {
            updateInputValue(unitId, key, value)
            setIsDisabled(true)
        }
    }

    return <div
        style={{
            height: "204px"
        }}>
        {
            page === CRASH_PAGE
                ? <ShowDialog
                    isAlarm={isAlarm}
                    title={"Просмотр аварий"}
                    number={deviceId}
                    widgetName={widgetName}
                    value={"crashView"}
                    changePage={() => setPage(MAIN_PAGE)}
                    component={<CrashView crashes={crashes} />}
                />
                : <div
                    className="mode-change"
                    draggable="false"
                    style={{
                        height: "100%"
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: 'column',
                            justifyContent: 'space-evenly',
                        }}
                    >
                        <div onClick={() => changePhIsOn('phIsOn', true)}
                            className={"mode-button" + (isDisabled ? " disabled-box"
                                : phIsOn ? " is-active" : "")}>
                            <p>Включить</p>
                        </div>
                        <div onClick={() => changePhIsOn('phIsOn', false)}
                            className={"mode-button" + (isDisabled ? " disabled-box"
                                : !phIsOn ? " is-active" : "")}>
                            <p>Выключить</p>
                        </div>
                        <div onClick={() => setPage(CRASH_PAGE)} className={"mode-button" + (crashes.some(x => x.state) ? " is-alarm" : "")}>
                            <p>ПРОСМОТР АВАРИЙ</p>
                        </div>
                    </div>
                </div>
        }
    </div >
}