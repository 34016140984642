import React from 'react';
import {StatWidget} from "../StatWidget";
import {signals, signalsCO2, signalsH, signalsPHESP, signalsZenith} from "../Signals";


const SIGNALS = {
    'ponics_pilot_time_series': signals,
    'sensiroom_co2_time_series': signalsCO2,
    'sensiroom_h_time_series': signalsH,
    'ph_esp_series': signalsPHESP,
    'zenith_series': signalsZenith,
}

export const ClassStat = ({widgetId, deviceName, deviceId, closeWidget, widget, code}) => {
    const _signals = SIGNALS[code]
    return (
        <StatWidget closeWidget={closeWidget} widgetId={widgetId}
                    deviceName={deviceName} deviceId={deviceId}
                    widget={widget} signals={_signals}/>
    );
};
