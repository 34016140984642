import React from 'react';
import {Checkbox, Form, Input, Table, Tabs, notification, Button, Tooltip} from 'antd';
import requestToAPI from "../lib/Request";
import {QUERY_GET_SESSION_LIST, SESSION_DEL} from "../lib/Query";
import {MSG_REQUEST_ERROR} from "../lib/Const";
import {drawDateAndTimeIncSec} from "../lib/Utils";
import {responsiveMobileColumn} from '../lib/Responsive';
import {DeleteOutlined} from "@ant-design/icons";

const { TabPane } = Tabs;


export const SettingsForm = (props) => {
    const [firstInputRef] = React.useState(React.useRef(null));
    const [, forceUpdate] = React.useReducer(x => x + 1, 0);
    const [sessionData,setSessionData] = React.useState(null);

    React.useEffect(() => {
        setTimeout(() => {
            if (firstInputRef.current) {
                firstInputRef.current.focus({
                    cursor: 'end',
                })
            }
        }, 100);
    });

    const changePasswordFlag = props.form.getFieldValue("changePasswordFlag")

    const downloadSessions = React.useCallback((id)=>{
        //console.log("props.initialValues.id",id);
        requestToAPI.post(QUERY_GET_SESSION_LIST, {id:id})
                .then(response => {
                    console.log(response)
                    setSessionData(response.data.records.map((r, index) => {
                        return {
                            ...r,
                            index: index
                        }
                    }));
                })
                .catch((error) => {
                    console.log(error);
                    notification.error({
                        message: MSG_REQUEST_ERROR,
                        description: error.message
                    })
                })
    },[]);

    React.useEffect(() => {
        if(!sessionData && props.initialValues.id) {
            downloadSessions(props.initialValues.id);
            setSessionData([]);
        }
    },[downloadSessions,props.initialValues.id,sessionData]);

    // выполняется после валидацией, но до сохранения
    props.internalTrigger.onBeforeSave=React.useCallback((validvalues) => {
        // удаляем лишнее
        delete validvalues["login"];
    });

    const sessionDelete = (e, record) => {
        e.stopPropagation()
        requestToAPI.post(SESSION_DEL, {ids: [record.id]})
            .then(response => {
                setSessionData(sessionData.filter(r => r.id != record.id));
            })
            .catch((error) => {
                console.log(error);
                notification.error({
                    message: MSG_REQUEST_ERROR,
                    description: error.message
                })
            })

    }
    const SESSION_COLUMNS = [
        {
            title: 'Дата/Время последнего входа',
            dataIndex: 'accessInSeconds',
            render:drawDateAndTimeIncSec,
            width:"120px"
        },
        {
            title: 'Адрес',
            dataIndex: 'addr',
            ellipsis: true,
            responsive: responsiveMobileColumn(),
            width:"140px"
        },
        {
            title: 'Агент',
            dataIndex: 'agent',
            ellipsis: true,
            responsive: responsiveMobileColumn()
        },
        {
            width: 70,
            fixed: 'right',
            render: (record) => <div className="container-button">
                {record.index !== 0
                    ? <Tooltip placement="top" title="Удалить сессию">
                        <Button className="button-delete-main" onClick={(e) => sessionDelete(e, record)}>
                            <DeleteOutlined />
                        </Button>
                    </Tooltip>
                    : null}
            </div>
        }
    ];

    return <Form
        form={props.form}
        layout="vertical"
        name="settingsform-frm"
        onFieldsChange={(state)=>{
            // изменился флаг смены пароля
            const pswdFlag = state.find(f=>f.name=="changePasswordFlag");
            if(pswdFlag) {
                props.form.setFieldsValue({"newPassword":undefined,"confirmPassword":undefined});
                forceUpdate();
            }
            props.onFieldsChange(state);
        }}
        initialValues={props.initialValues}
        >

        <Tabs defaultActiveKey="1">
            <TabPane tab="Профиль пользователя" key="1" style={{height: 500}}>
            <Form.Item
                    name='id'
                    hidden={true}>
                    <Input />
                </Form.Item>

                <Form.Item
                    name='changePasswordFlag'
                    valuePropName="checked"
                    >
                   <Checkbox>Сменить пароль</Checkbox>
                </Form.Item>
                <Form.Item
                    label="Новый пароль"
                    name='newPassword'
                    hasFeedback
                    rules={[
                        {required: !!changePasswordFlag}
                    ]}>
                    <Input.Password
                        disabled={!props.form.getFieldValue("changePasswordFlag")}
                        autoComplete={"new-password"} />
                </Form.Item>

                <Form.Item
                    label="Подтверждение пароля"
                    name='confirmPassword'
                    dependencies={['newPassword']}
                    hasFeedback
                    rules={[
                        {required: !!changePasswordFlag},
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve();
                              }

                              return Promise.reject(new Error('Значения введенных паролей не совпадают'));
                            },
                          })
                    ]}>
                    <Input.Password
                        disabled={!props.form.getFieldValue("changePasswordFlag")}
                        autoComplete={"new-password"}/>
                </Form.Item>
                <Form.Item
                    label="Логин"
                    name='login'
                    rules={[
                        { required: true },
                        { max: 50 }
                    ]}>
                    <Input readOnly/>
                </Form.Item>
                <Form.Item
                    label="E-mail"
                    name='ident.devUser.email'
                    rules={[
                        { required: false },
                        { max: 320 },
                        { type: 'email'}
                    ]}>
                    <Input ref={firstInputRef}/>
                </Form.Item>
{/*
                <Form.Item
                    name='ident.devUser.phone'
                    rules={[
                        { required: false},
                        {max: 16 }
                    ]}>
                    <Input placeholder="Телефон" />
                </Form.Item>
*/}

                <Form.Item
                    label="Имя"
                    name='firstName'
                    rules={[
                        { required: false },
                        { max: 50 }
                    ]}>
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Фамилия"
                    name='lastName'
                    rules={[
                        { required: false },
                        { max: 50 }
                    ]}>
                    <Input />
                </Form.Item>
            </TabPane>
            <TabPane tab="Сессии" key="2" style={{height: 500}}>
                <Table rowKey="id"
                    pagination={{ pageSize: 6,showSizeChanger: false}}  size={"small"}
                    columns={SESSION_COLUMNS} dataSource={sessionData}></Table>
            </TabPane>
          </Tabs>



    </Form>
}
