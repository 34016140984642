import React from 'react';
import ReactDOM from 'react-dom';

import Main from './components/modules/Main';
import Error from "./components/Error";
import Help from "./components/Help";
import RecoveryPassword from "./components/lib/RecoveryPassword";
import Audit from "./components/modules/userAudit/UserAudit";

import { MSG_PAGENOTFOUND, DEFAULT_DATE_FORMAT } from "./components/lib/Const";

import './resources/css/theme.less';
import './resources/css/index.css';
import './resources/css/widget.css';

import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import moment from 'moment';
import 'moment/locale/ru';

import { Route } from 'react-router';
import {BrowserRouter, Switch} from "react-router-dom";
import ErrorRegister from './components/modules/messages/ErrorRegister';
import SuccessRegister from './components/modules/messages/SuccessRegister';
import Device from './components/modules/device/Device';
import Auth from "./components/Auth";


document.documentElement.lang = 'ru';
moment.locale('ru');
moment().format(DEFAULT_DATE_FORMAT);

const validateMessages = {
    required: "Поле не может быть пустым",// eslint-disable-line
    string: {
        max: "Длина '${label}' не может быть больше ${max}",// eslint-disable-line
        range:"Поле должно содержать от ${min} до ${max} символов",// eslint-disable-line
        len:"Поле должно содержать ${len} символов"// eslint-disable-line
    },
    types: {
        email: "Неверный формат ввода для электронной почты"
    }
};
console.log("environment=", process.env);

ReactDOM.render(
    <ConfigProvider locale={ruRU} form={{ validateMessages }}>
        <BrowserRouter>
            <Switch>
                <Route exact path='/'><Auth /></Route>
                <Route path='/reset-devuser-passwd/:key'><Auth controlCase={"setNewPass"} /></Route>
                {/* Контур Администрирование */}
                <Route exact path='/main'><Main /></Route>
                <Route exact path='/device'><Device /></Route>
                <Route exact path='/audit'><Audit /></Route>
                <Route path='/help'><Help /></Route>
                <Route path='/recovery/:key'><RecoveryPassword /></Route>
                {/* Статус подтверждения почты */}
                <Route path='/errorregister'><ErrorRegister /></Route>
                <Route path='/successregister/:login/:email'><SuccessRegister /></Route>
                <Route><Error text={MSG_PAGENOTFOUND} helpId="/help/pagenotfound" /></Route>

            </Switch>
        </BrowserRouter>
    </ConfigProvider>
    , document.getElementById('root')
);