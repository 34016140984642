import React from 'react';
import {CloseOutlined} from "@ant-design/icons";
import {Col, Row, Spin, Tooltip} from "antd";
import {ShowDialog} from "../dialogs/ShowDialog";
import requestToAPI from "../../../lib/Request";
import {subscribe} from "../../../lib/RequestWS";
import {loadPreference} from "../../../lib/PreferenceStorage";
import ModeCO2 from "./ModeCO2";
import RegulationRange from "./RegulationRange";
import TimerCO2 from "./TimerCO2";
import DosingCO2 from "./DosingCO2";
import circle from "../../../../resources/images/circle.svg";
import co2 from "../../../../resources/images/co2.svg";
import off from "../../../../resources/images/off.svg";
import on from "../../../../resources/images/on.svg";
import auto from "../../../../resources/images/auto.svg";
import schedule from "../../../../resources/images/schedule.svg";
import ppm from "../../../../resources/images/ppm.svg";

const PonicsDefaultMonitor = {}

export const SensiroomCO2 = ({widgetId, deviceName, deviceId, closeWidget}) => {
    const [dialogs, changeDialog] = React.useState({
        mode: false, timer: false, range: false, dosing: false
    });

    const [monitor, setMonitor] = React.useState(PonicsDefaultMonitor)
    const [contextParams] = React.useState({})

    const [isFetching, setIsFetching] = React.useState(true)

    const userPref = loadPreference(widgetId);

    React.useEffect(() => {
        contextParams.mountFlag = true;
        if (requestToAPI.authToken) {
            if (!contextParams.mountFlag) return;
            subscribe(
                `subscription { 
                    deviceUnitByPk(id: ${deviceId}) { 
                        monitor { 
                            key
                            value 
                        }
                        model {
                          metadata
                        }
                    } 
                }`,
                (payload) => {
                    if (!contextParams.mountFlag) return;
                    let new_data = payload.data.deviceUnitByPk
                    if (new_data) {
                        var data = new_data.monitor.reduce(function (map, obj) {
                            map[obj.key] = obj.value;
                            return map;
                        }, {});
                        setMonitor(data)
                        setIsFetching(false)
                    } else {
                        changeDialog({
                            mode: false, timer: false,
                            dosing: false, range: false
                        })
                    }
                }
            )
        }
        return () => {
            contextParams.mountFlag = false
        }
    }, [])

    function random() {
        return Math.floor(Math.random() * 100000)
    }

    async function changePage(value, option, massValues = []) {

        if (massValues.includes(undefined)) {
            return
        }

        switch (value) {
            case "mode":
                changeDialog({...dialogs, mode: option})
                break
            case "timer":
                changeDialog({...dialogs, timer: option})
                break
            case "range":
                changeDialog({...dialogs, range: option})
                break
            case "dosing":
                changeDialog({...dialogs, dosing: option})
                break
            default:
                changeDialog({
                    mode: false, timer: false,
                    range: false, dosing: false
                })
        }

    }

    return (
        <div className="main-widget">
            <div draggable="true" className={monitor.sysState === 1 ? "header-widget background-red" : "header-widget"}>
                <div/>
                <div className={monitor.sysState === 1 ? "main-widget-title background-red" : "main-widget-title"}>
                    {`${deviceName}`}
                </div>
                <div className="area-widget-close" onClick={() => {
                    closeWidget(widgetId)
                }}>
                    <CloseOutlined
                        className={monitor.sysState === 1 ? "widget-close-white widget-close" : "widget-close-main"}
                    />
                </div>

            </div>
            {
                isFetching
                    ? <Spin className="fetching-stat"/>
                    : <>
                        <div>
                            <Row className={'top-widget-part'}>
                                <Col span={12} className={'ppm-box'}>
                                    <div className={'ppm-box-absolute'}>
                                        <p className={'ppm-box-text ppm-box-text-small'}>ppm</p>
                                    </div>
                                    <p className="ppm-box-text wrap-text ppm-box-text-large">
                                        {monitor.sensorValue === 0 || monitor.sensorValue
                                            ? (Math.round((monitor.sensorValue) * 100) / 100)
                                            : "-"
                                        }
                                    </p>
                                </Col>
                                <Col span={12} className={'top-widget-part-indicators'}>


                                    <Col span={8} className={'indicator-box'}>
                                        <Tooltip placement="top" title={monitor.sensorIsOK !== undefined || null
                                            ? `Индикатор исправности датчика СО2`
                                            : 'Нет метаданных'}>
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="16" cy="16" r="16" fill={monitor.sensorIsOK ? "#00CC14" : "#FF0000"}/>
                                            </svg>
                                        </Tooltip>
                                        <p>Датчик</p>
                                    </Col>
                                    <Col span={8} className={'indicator-box'}>
                                        <Tooltip placement="top" title={monitor.out220 !== undefined || null
                                            ? `Индикатор состояния выхода прибора`
                                            : 'Нет метаданных'}>
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="16" cy="16" r="16" fill={monitor.out220 ? "#FF0000" : "#434343"}/>
                                            </svg>
                                        </Tooltip>
                                        <p>Выход</p>
                                    </Col>
                                    <Col span={8} className={'indicator-box'}>
                                        <Tooltip placement="top" title={monitor.alarm !== undefined || null
                                            ? `Индикатор аварийного уровня CO2`
                                            : 'Нет метаданных'}>
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="16" cy="16" r="16" fill={monitor.alarm ? "#FF0000" : "#434343"}/>
                                            </svg>
                                        </Tooltip>
                                        <p>Тревога</p>
                                    </Col>
                                </Col>
                            </Row>
                            <Row className={'bottom-widget-part'}>
                                <Col span={6} className={'bottom-button'}>
                                    <Tooltip placement="top" title={monitor.out220 !== undefined || null
                                        ? `Режим`
                                        : 'Нет метаданных'}>
                                        <div className={'bottom-button-box'} onClick={() => changePage('mode', true, [monitor.mode, monitor.regType])}>
                                            <img className="bottom-button-icon-mode" src={
                                                monitor.mode === 0
                                                    ? off
                                                    : monitor.mode === 1
                                                        ? on
                                                        : auto
                                            } alt="arrow"/>
                                        </div>
                                    </Tooltip>

                                </Col>
                                <Col span={6} className={'bottom-button'}>
                                    <Tooltip placement="top" title={monitor.out220 !== undefined || null
                                        ? `Диапазон регулирования`
                                        : 'Нет метаданных'}>
                                        <div className={'bottom-button-box'} onClick={() => changePage('range', true, [monitor.ppmLow, monitor.ppmHigh])}>
                                            <img className="bottom-button-icon-ppm" src={ppm} alt="arrow"/>
                                        </div>
                                    </Tooltip>

                                </Col>
                                <Col span={6} className={'bottom-button'} onClick={() => changePage('timer', true, [monitor.timeStart, monitor.timeStop])}>
                                    <Tooltip placement="top" title={monitor.out220 !== undefined || null
                                        ? `Расписание`
                                        : 'Нет метаданных'}>
                                        <div className={'bottom-button-box'}>
                                            <img className="bottom-button-icon-timer" src={schedule} alt="arrow"/>
                                        </div>
                                    </Tooltip>

                                </Col>
                                    <Col span={6} className={'bottom-button'} onClick={() => changePage('dosing', true, [monitor.pauseTm, monitor.workTm])}>
                                        <Tooltip placement="top" title={monitor.out220 !== undefined || null
                                            ? `Дозирование`
                                            : 'Нет метаданных'}>
                                            <div className={'bottom-button-box'}>
                                                <img className="bottom-button-icon-dosing" src={co2} alt="arrow"/>
                                            </div>
                                        </Tooltip>
                                    </Col>
                            </Row>

                        </div>
                        {dialogs.mode ?
                            <ShowDialog
                                title={"Режим"}
                                isAlarm={monitor.sysState === 1}
                                widgetName={deviceName}
                                number={widgetId}
                                value={"mode"}
                                changePage={changePage}
                                component={<ModeCO2 unitId={deviceId}
                                                    mode={monitor.mode}
                                                    regType={monitor.regType}

                                />}/>
                            : null}
                        {dialogs.timer ?
                            <ShowDialog
                                title={"Расписание"}
                                isAlarm={monitor.sysState === 1}
                                widgetName={deviceName}
                                number={widgetId}
                                value={"timer"}
                                changePage={changePage}
                                component={<TimerCO2 unitId={deviceId}
                                                     timeStart={monitor.timeStart}
                                                     timeStop={monitor.timeStop}
                                />}/>
                            : null}

                        {dialogs.range ?
                            <ShowDialog
                                title={"Диапазон регулирования"}
                                isAlarm={monitor.sysState === 1}
                                widgetName={deviceName}
                                number={widgetId}
                                value={"range"}
                                changePage={changePage}
                                component={<RegulationRange unitId={deviceId}
                                                            ppmLow={monitor.ppmLow}
                                                            ppmHigh={monitor.ppmHigh}
                                />}/>
                            : null}
                        {dialogs.dosing ?
                            <ShowDialog
                                title={"Дозирование"}
                                isAlarm={monitor.sysState === 1}
                                widgetName={deviceName}
                                number={widgetId}
                                value={"dosing"}
                                changePage={changePage}
                                component={<DosingCO2 unitId={deviceId}
                                                      pauseTm={monitor.pauseTm}
                                                      workTm={monitor.workTm}
                                />}/>
                            : null}
                    </>
            }
        </div>

    )
}
