import React from 'react';
import { Button, Form, Input, Tabs, Checkbox } from 'antd';
import requestToAPI from "./Request";
import { RegistrationForm } from "./RegistrationForm";
import { HTTP_STATUS_UNAUTHORIZED } from "./Request";
import { MSG_NO_LOGIN_OR_PASSWORD } from "./Const";

import '../../resources/css/App.css'
import 'antd/dist/antd.css';
import { resq$ } from 'resq';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { QUERY_GET_TOKEN_DEVUSER,MUT_REVOKE_TOKEN } from './Query';
import { ForgetPassword } from './ForgetPassword';

const { TabPane } = Tabs;



export const getLoginButton = (status, history) => {
    if (status != HTTP_STATUS_UNAUTHORIZED) {
        return undefined;
    }
    return <Button type="primary" onClick={() => history.push("/")}>Вход в систему</Button>
}

export const restoreToken = () => {
    if (!requestToAPI.authToken) {
        let saveToken = sessionStorage.getItem("authToken") ?? localStorage.getItem("authToken");
        if (saveToken) {
            console.log("Restore token")
            requestToAPI.authToken = saveToken;
            requestToAPI.user = {
                id:sessionStorage.getItem("user.id") ?? localStorage.getItem("user.id"),
                name: sessionStorage.getItem("user.userName") ?? localStorage.getItem("user.userName"),
                login: sessionStorage.getItem("user.userLogin") ?? localStorage.getItem("user.userLogin")
            }
        }
    }
}

export const setNewUserName = (firstName, lastName, patronymic)=>{
    requestToAPI.user.name=[firstName,patronymic, lastName].join(" ");
    saveToAllStorages(requestToAPI.authToken,requestToAPI.user);
}

let _activeValidator;

export const LoginContent = (props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);


    const handleKeyDown = (ev) => {
        if (ev.which == 13) {
            let root = document.getElementById("root");
            const okBtn = resq$('Button', root).byProps({ type: "primary" });
            okBtn.props.onClick(okBtn);
        }
    }

    const login = () => {
        _activeValidator.setFieldsValue({ error: undefined });
        return _activeValidator.validateFields()
            .then((values) => {
                setLoading(true);
                requestToAPI.authToken=undefined;
                delete values.saveflag 
                return requestToAPI.post(QUERY_GET_TOKEN_DEVUSER, values);
            })
            .catch((error) => {
                setLoading(false);
                cleanLocalStorage();
                cleanSessionStorage();
                // тут сообщение перекроем, так как UNAUTHORIZED имеет смысл другой
                if (error.status == HTTP_STATUS_UNAUTHORIZED) {
                    error.message = MSG_NO_LOGIN_OR_PASSWORD;
                }
                if (error.message) {
                    _activeValidator.setFieldsValue({ error: error.message });
                }
                throw error;
            })
            .then(response => {
                requestToAPI.loginComplete(response.data.devUserPasswdAuth);

                if (_activeValidator.getFieldValue("saveflag")) {
                    setLocalStorage(requestToAPI.authToken, requestToAPI.user);
                } else {
                    setSessionStorage(requestToAPI.authToken, requestToAPI.user);
                };

            })
            .then(response => {
                setLoading(false);
                if (props.cb) {
                    props.cb();
                }
            });
    }

    function forgetPass(e) {
        e.preventDefault();
        props.setControlCase("forgetPass");
    }

    _activeValidator = form;

    return (
        <div className="main">
            <Tabs defaultActiveKey="1" className="auth-reg-forms">
                <TabPane tab="ВХОД" key="1">
                    <div onKeyDown={handleKeyDown}>
                        <Form
                            layout={"vertical"}
                            form={form}
                            name="formLogin"
                            style={{ padding: 30 }}
                            initialValues={{}}
                        >
                            {/* <p className="vhod">ВХОД</p>
                            <hr id="red"/>
                            <br /> */}

                            <Form.Item
                                name="userLogin"
                                rules={[
                                    {
                                        required: true,
                                        message: "Имя пользователя не может быть пустым"
                                    }
                                ]}>
                                <Input id="size" prefix={<UserOutlined />} placeholder="Логин*"/>
                            </Form.Item>

                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Пароль не может быть пустым"
                                    }
                                ]}
                            >
                                <Input.Password id="size" prefix={<LockOutlined />} placeholder="Пароль*"/>
                            </Form.Item>
                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) => prevValues.error !== currentValues.error}>
                                {
                                    ({ getFieldValue }) =>
                                        getFieldValue('error') ? (
                                            <div className="ant-form-item ant-form-item-explain ant-form-item-explain-error">{getFieldValue('error')}</div>
                                        ) : null
                                }
                            </Form.Item>
                            <Form.Item
                                name="saveflag"
                                valuePropName="checked"
                                getValueFromEvent={(event) => {
                                    return event.target.checked ? 1 : 0;
                                }}
                            >
                                <Checkbox>Запомнить меня</Checkbox>
                            </Form.Item>
                            <Form.Item
                                noStyle>
                                <button
                                    type="button"
                                    className="link-button forget-pass"
                                    onClick={forgetPass}
                                >
                                    Забыли пароль?
                                </button>
                            </Form.Item>
                            <br />
                            <br />
                            <Form.Item>
                                <Button type="primary" htmlType="submit" onClick={login} loading={loading}>Войти</Button>
                            </Form.Item>
                        </Form>
                    </div>
                </TabPane>
                <TabPane tab="РЕГИСТРАЦИЯ" key="2">
                    <div>
                        <RegistrationForm cb={props.cb} />
                    </div>
                </TabPane>
            </Tabs>
        </div>
    )
}

const cleanStorage = (storage) => {
    storage.removeItem("authToken");
    storage.removeItem("user.userLogin");
    storage.removeItem("user.userName");
    storage.removeItem("user.id");
}

export const setStorage = (storage,authToken, user) => {
    storage.setItem("authToken", authToken);
    storage.setItem("user.userLogin", user.login);
    storage.setItem("user.userName", user.name);
    storage.setItem("user.id", user.id);
}


export const setSessionStorage = (authToken, user) => {
    setStorage(sessionStorage, authToken, user);
    cleanLocalStorage();
}

export const saveToAllStorages = (authToken,user) => {
    if(sessionStorage.getItem("user.id")) {
        setSessionStorage(authToken,user);
    }
    if(localStorage.getItem("user.id")) {
        setLocalStorage(authToken,user);
    }
}

export const cleanSessionStorage = () => {
    cleanStorage(sessionStorage);
}

export const setLocalStorage = (authToken, user) => {
    setStorage(localStorage, authToken, user);
    cleanSessionStorage();
}

export const cleanLocalStorage = () => {
    cleanStorage(localStorage);
}

export const logout = (history, cb) => {
    requestToAPI.post(MUT_REVOKE_TOKEN);
    requestToAPI.authToken = undefined;
    requestToAPI.user = undefined;
    cleanLocalStorage();
    cleanSessionStorage();
    history.push("/");
    if (cb) cb();
}