import React from 'react';
import { Button, Form } from 'antd';
import { resq$ } from 'resq';
import logoFull from '../../../resources/images/logo-full.svg';

const ErrorRegister = () => {

    const [form] = Form.useForm();

    const handleKeyDown = (ev) => {
        if (ev.which == 13) {
            let root = document.getElementById("root");
            const okBtn = resq$('Button', root).byProps({ type: "primary" });
            okBtn.props.onClick(okBtn);
        }
    }

    return <div>
        <div className="header">
        <div className="logotype"><img src={logoFull} alt="Logotype"/></div>
            <hr className="gradient" />
        </div>
        <div className="main">
            <div onKeyDown={handleKeyDown} className={"main"} style={{ marginTop: "-20px !important" }}>
                <Form
                    layout={"vertical"}
                    form={form}
                    name="formFail"
                    initialValues={{}}
                >
                    <div className="fail-mail">
                        <p className="header-fail">Ошибка подтверждения</p>
                        <hr id="red" />
                        <p className="p-fail"> Произошла ошибка подтверждения эл. почты</p>
                    </div>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" href="/">Войти</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    </div>
}
export default ErrorRegister;