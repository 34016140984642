import React, {useEffect} from 'react';
import App from '../App';
import ModuleHeader from "../lib/ModuleHeader";
import requestToAPI from "../lib/Request";
import { CONTOUR_ADMIN, MODULE_MAIN } from '../lib/ModuleConst';
import {PlusOutlined} from '@ant-design/icons';
import {Button, Form, notification, Tooltip} from 'antd';
import { ShowModal } from "./../lib/EditForm";
import {
    QUERY_GET_DEVICE,
    MUT_INS_DEVICE,
    QUERY_GET_DASHBOARD_WIDGETS,
    MUT_INS_DASHBOARD_WIDGET,
    MUT_DEL_WIDGET
} from './../lib/Query';
import DeviceRegisterForm from './device/DeviceRegisterForm';
import {DevicesListForm} from "./DevicesListForm";
import {Dashboard} from "./widget/Dashboard";
import {MSG_REQUEST_ERROR} from "../lib/Const";
import {useHistory} from "react-router-dom";


const MOD_TITLE = "Добро пожаловать в систему E-MODE";
const MODE_HELP_ID = "help/main"
const CONTOUR = CONTOUR_ADMIN;
const MODULE = MODULE_MAIN;
const MNU_MENU = [MODULE_MAIN.name, MODULE.name];
const MNU_SUMMENU = MODULE_MAIN.name;

const Main = (props) => {
    const [, forceUpdate] = React.useReducer(x => x + 1, 0);
    const [form] = Form.useForm();
    const [topLayer, setTopLayer] = React.useState([]);
    const [, setNewPin] = React.useState([]);
    const [widgets, setWidgets] = React.useState([]);
    const [contextParams] = React.useState({});
    const history = useHistory()

    // получение данных о виджетах
    useEffect(() => {
        contextParams.mountFlag = true;
        if (requestToAPI.user.id) {
            getDashboard()
        }
        return () => {
            contextParams.mountFlag = false
        }

    }, [])


    const getDashboard = () => {
        if (!contextParams.mountFlag) return;
        requestToAPI.post(QUERY_GET_DASHBOARD_WIDGETS, {id: requestToAPI.user.id})
            .then(response => {
                setWidgets(response.data.records.sort((a, b) => {
                    return a.setting.pos - b.setting.pos
                }))
            })
            .catch((error) => {
                notification.error({
                    message: MSG_REQUEST_ERROR,
                    description: error.message
                })
            })
    }

    const setAfterDragDevices = (devices) => {
        setWidgets(devices)
    }

    function closeWidget(id) {
        if (!contextParams.mountFlag) return;
        setWidgets([...widgets.filter((device) => device.id !== id)])
        requestToAPI.post(MUT_DEL_WIDGET, {id: id})
            .catch((error) => {
                notification.error({
                    message: MSG_REQUEST_ERROR,
                    description: error.message
                })
            })
    }

    async function addWidget(data, lastPos) {
        if (!contextParams.mountFlag) return;
        for (let i = 0; i < data.length; i++) {
            await requestToAPI.post(MUT_INS_DASHBOARD_WIDGET, {
                pos: lastPos + i + 1,
                filters: [],
                beg: '',
                end: '',
                typeId: data[i].widget.id,
                unitId: data[i].unitId
            }).catch((error) => {
                notification.error({
                    message: MSG_REQUEST_ERROR,
                    description: error.message
                })
            })

        }

    }

    const getList = () => {
        const dialog = ShowModal({
            editorContext: {
                uriForGetOne: QUERY_GET_DEVICE
            },
            form: form,
            dialogId:"modal-devices",
            title: "Выберите прибор",
            saveButtonTitle: "Выбрать",
            content: <DevicesListForm widgets={widgets} topLayer={topLayer}
                                      setTopLayer={setTopLayer} addWidget={addWidget} getDashboard={getDashboard}/>,
            idName: "id",
            width: "720px",
            isFooter: false,
            destroyDialog: (dlgId) => {
                form.resetFields();
                setTopLayer([...topLayer.filter(c => c.props.id != dlgId)]);
            },
        });
        // вставляем Modal в top layer
        setTopLayer([dialog])
    }

    const changeRegForm = () => {
        const config = {
            form: form,
            dialogId:"modal-regdevice",
            editorContext: {
                uriForInsert:MUT_INS_DEVICE,
                uriForGetOne:QUERY_GET_DEVICE
            },
            destroyDialog: (dlgId) => {
                form.resetFields();
                setTopLayer([...topLayer.filter(c => c.props.id != dlgId)]);
            },
        }
        // формируем диалог
        const dialog = ShowModal({
            ...config,
            title: "Регистрация прибора",
            content: <DeviceRegisterForm />,
            idName: "id",
            width: "520px",
            afterSave: (values) => {
                history.push('/device')
                // const record = values.data.record;
                // setNewPin(record.unit.number, record.unit.model.name);
            }
        });
        // вставляем Modal в top layer
        setTopLayer([dialog])
    }


    return (
        <App subsystem={CONTOUR.name} menu={MNU_MENU} submenu={MNU_SUMMENU}
             helpId={MODE_HELP_ID}
             defaultCollapsed={false}
             afterLogin={forceUpdate}
             afterLogout={forceUpdate}
        >
            {/* <p>
                Общая информация о продукте, телефоны СТП и прочее...
            </p> */}
            {widgets.length === 0
                ? <div>
                    <ModuleHeader title={MOD_TITLE} search={false} />
                    <Button className="add-device" onClick={changeRegForm} icon={<PlusOutlined className="icon-plus"/>}> <p className="p-add-device">Зарегистрировать прибор</p> </Button>
                    {topLayer.map(item => item)}
                </div>
                : <div>
                    <ModuleHeader title={'Состояние приборов'} search={false} />
                    <Dashboard widgets={widgets}
                               closeWidget={closeWidget}
                               setAfterDragDevices={setAfterDragDevices}/>
                </div>

            }

            <div className="button-add-widget">
                <Tooltip placement="topRight" title='Добавить прибор на Главную страницу'>
                    <Button onClick={getList} type="primary" size="middle"><p>+</p></Button>
                </Tooltip>
            </div>

            {topLayer.map(item => item)}
        </App>
    )
}
export default Main;
