// Контуры (в lowerCase)
export const CONTOUR_ADMIN = { name: "admin", title: "Администрирование" };
export const CONTOUR_REFBOOKS = { name: "refbooks", title: "НСИ" };
export const CONTOUR_DOCUMENTS = { name: "documents", title: "Документы" };

export const CONTOURS = {
    "refbooks": "НСИ",
    "documents": "Документы",
    "admin": "Администрирование",
}

// Модули (в lowerCase)
// В контуре "Администрирование"
export const MODULE_CREDENTIAL = { name: "credential", title: "СПРАВОЧНИКИ" };
export const MODULE_MAIN = { name: "main", title: "ГЛАВНАЯ СТРАНИЦА" };
export const MODULE_DEVICES = { name: "devices", title: "ПРИБОРЫ" };
export const MODULE_SYSTEM = { name: "system", title: "УСТРОЙСТВА" };
export const MODULE_AUDIT = { name: "audit", title: "АУДИТ" };
export const MODULE_RESOURCE = { name: "resource", title: "ДОКУМЕНТЫ" };
export const MODULE_NOTIFICATION = { name: "admin", title: "УПРАВЛЕНИЕ ОПОВЕЩЕНИЯМИ" };
export const MODULE_TYPEDEVICES = { name: "typedevices", title: "ТИПЫ ПРИБОРОВ" };
// В контуре "НСИ" 
export const MODULE_EDIZM = { name: "edizm", title: "Единицы измерения" };
export const MODULE_CAPCLASS = { name: "capclass", title: "Справочники" };
// В контуре "Тестовый контур"
export const MODULE_REQUEST = { name: "request", title: "Заявки" };

export const NONE = {};

export let MODULES = {};
MODULES[MODULE_CREDENTIAL.name] = MODULE_CREDENTIAL.title;
MODULES[MODULE_SYSTEM.name] = MODULE_SYSTEM.title;
MODULES[MODULE_MAIN.name] = MODULE_MAIN.title;
MODULES[MODULE_DEVICES.name] = MODULE_DEVICES.title;
MODULES[MODULE_AUDIT.name] = MODULE_AUDIT.title;
MODULES[MODULE_RESOURCE.name] = MODULE_RESOURCE.title;
MODULES[MODULE_NOTIFICATION.name] = MODULE_NOTIFICATION.title;
MODULES[MODULE_EDIZM.name] = MODULE_EDIZM.title;
MODULES[MODULE_CAPCLASS.name] = MODULE_CAPCLASS.title;
MODULES[MODULE_REQUEST.name] = MODULE_REQUEST.title;
MODULES[MODULE_TYPEDEVICES.name] = MODULE_TYPEDEVICES.title;

export const CONTOURS_WITH_MODULES = new Map([
    // [CONTOUR_REFBOOKS, [MODULE_EDIZM, MODULE_CAPCLASS]],
    // [CONTOUR_DOCUMENTS, [MODULE_REQUEST]],
    [CONTOUR_ADMIN, [MODULE_MAIN, MODULE_SYSTEM, MODULE_DEVICES, MODULE_AUDIT, [MODULE_TYPEDEVICES]]],
]);