import React from 'react';
import { Form, Input, Upload, Tabs } from 'antd';
import moment from 'moment';
import { DEVICE_DOC_TYPE_CODE, FORM_ITEMS_LAYOUT } from "../../lib/Const";
import {
    PaperClipOutlined
} from '@ant-design/icons';
import {prettySizeOf,textAreaKeyDown} from "../../lib/Utils";



const { TextArea } = Input;
const { TabPane } = Tabs;


const DeviceForm = (props) => {
    let values = {...props.initialValues};
    const firstInputRef = React.useRef(null);
    const [files] = React.useState({deleteAllFiles:[],insAllFiles:[]});
    
    React.useEffect(() => {
        setTimeout(() => {
            if (firstInputRef.current) {
                firstInputRef.current.focus({
                    cursor: 'end',
                })
            }
        }, 100);
    });


    if(Object.keys(values).length>0) {
        values.datetime=moment(values.manufacturedInSeconds*1000).format("DD.MM.YYYY HH:mm");
    }

    props.internalTrigger.onBeforeSave=(values) => {
        values.id=props.initialValues["devUser.note.id"];
    };    

    if(props.initialValues["model.docs"]) {
        Object.keys(DEVICE_DOC_TYPE_CODE).forEach(key=>{
            files[key] = props.initialValues["model.docs"]
                        .filter(f=>f.type.code==DEVICE_DOC_TYPE_CODE[key].code)
                        .map(f=>{
                            f.file.version = f.version;
                            f.file.note = f.note;
                            return f.file;
                        });
        });
    } else {
        files.manuals=[];
    }

    const fileItemRender = (originNode,file,fileList,actions)=>{
            return <div className="ant-upload-list-text-container">
              <div className="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-text">
                <div className="ant-upload-list-item-info">
                  <span className="ant-upload-span">
                    <PaperClipOutlined />
                    <a className="ant-upload-list-item-name" href={file.url} target="_blank" rel="noreferrer">{file.name}</a>
                    <span className="file-list-info">{prettySizeOf(file.size)}</span>
                    <span className="file-list-info">Версия {file.version}</span>
                  </span>
                </div>  
              </div>  
            </div>;
    }
    
    return<Form
        {...FORM_ITEMS_LAYOUT}
        form={props.form}
        layout="horizontal"
        name="formDevice"
        onFieldsChange={props.onFieldsChange}
        initialValues={values}>
        <div className="col-item"><label className="row-item">Серийный номер: </label><label className="item">{values.number}</label></div>
        <div className="col-item"><label className="row-item">Произведен: </label><label className="item">{values.datetime}</label></div>
        <div className="col-item"><label className="row-item">Тип прибора: </label><label className="item">{values["model.name"]}</label></div>
        <div className="col-item"><label className="row-item">Описание владельца: </label></div>
        <Form.Item
            name="notes.note"
        >
            <TextArea ref={firstInputRef} style={{height: 58}} onKeyDown={textAreaKeyDown}/>
        </Form.Item>
        <Tabs defaultActiveKey="1">
            {Object.keys(DEVICE_DOC_TYPE_CODE).map(key=> {
                    return  <TabPane tab={DEVICE_DOC_TYPE_CODE[key].title} key={key}>
                                <Upload fileList={files[key]} 
                                    itemRender={fileItemRender}
                                    multiple>
                                </Upload>
                            </TabPane>
                }) 
            } 
        </Tabs>
    </Form>


}

export default DeviceForm;