import React from "react";
import { Input } from "../../hooks/Input";

export const RegulatorRangeDialog = ({ unitId, phLowerMin, phUpperMax, phUpper, phLower }) => {
    const [disabled, setDisabled] = React.useState(false)
    const [update, setUpdate] = React.useState(true)
    
    React.useEffect(() => {
        setDisabled(false)
        setUpdate(!update)
    }, [phUpper, phLower])

    return <div>
        <div className="ph-dialog" draggable="false">
            <div className="row-ph-dialog ph-level-row">
                <p>Верхняя точка</p>
                <Input
                    value={phUpper}
                    max={phUpperMax}
                    min={phLower + 0.5}
                    mode={0.1}
                    round={10}
                    deviceId={unitId}
                    ckey={'phUpper'}
                    disabled={disabled}
                    setDisabled={setDisabled}
                    update={update}
                />
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>Нижняя точка</p>
                <Input
                    value={phLower}
                    min={phLowerMin}
                    max={phUpper - 0.5}
                    mode={0.1}
                    round={10}
                    deviceId={unitId}
                    ckey={'phLower'}
                    disabled={disabled}
                    setDisabled={setDisabled}
                    update={update}
                />
            </div>
        </div>
    </div>
}