import React, {useEffect, useState} from 'react'
import {Tooltip} from "antd";
import grayLowTank from "../../../resources/images/grayLowTank.svg";
import grayFullTank from "../../../resources/images/grayFullTank.svg";
import grayMediumTank from "../../../resources/images/grayMediumTank.svg";
import greenArrowDown from "../../../resources/images/greenArrowDown.svg";
import greenArrowDownFull from "../../../resources/images/greenArrowDownFull.svg";
import greenArrowUpLow from "../../../resources/images/greenArrowUpLow.svg";
import greenArrowDownLow from "../../../resources/images/greenArrowDownLow.svg";
import greenArrowUp from "../../../resources/images/greenArrowUp.svg";
import greenArrowUpFull from "../../../resources/images/greenArrowUpFull.svg";
import yellowArrowDown from "../../../resources/images/yellowArrowDown.svg";
import yellowArrowDownFull from "../../../resources/images/yellowArrowDownFull.svg";
import yellowArrowUpLow from "../../../resources/images/yellowArrowUpLow.svg";
import yellowArrowDownLow from "../../../resources/images/yellowArrowDownLow.svg";
import yellowArrowUp from "../../../resources/images/yellowArrowUp.svg";
import yellowArrowUpFull from "../../../resources/images/yellowArrowUpFull.svg";
import recycleGray from "../../../resources/images/recycleGray.svg";

import redLow from "../../../resources/images/redLow.svg";
import redFull from "../../../resources/images/redFull.svg";
import recycleRed from "../../../resources/images/recycleRed.svg";

export const Tank = ({canvasName, value, name, dinState, curState, title}) => {

    const [image, setImage] = useState(null)

    useEffect(() => {
       createCanvas()

    }, [curState, dinState])

    function createCanvas() {

        let color
        let arrowUp = false
        let arrow = false
        let replace = false
        
        switch (name) {
            case "PROB":
                if (dinState.alarmE103 || dinState.alarmE102) {
                    color = '#FF0000'
                    value = 100
                    setImage(<img style={{width: 44, height: 44}} src={redFull}></img>)
                }else if (dinState.dinSampler) {
                    value = 100
                    color = "#666666"
                    replace = true
                    setImage(<img style={{width: 44, height: 44}} src={recycleGray}></img>)
                } else if (dinState.outSampler) {
                    value = 100
                    color = "#FF0000"
                    replace = true
                    setImage(<img style={{width: 44, height: 44}} src={recycleRed}></img>)
                } else {
                    value = 20
                    color = '#FF0000'
                    setImage(<img style={{width: 44, height: 44}} src={redLow}></img>)
                }
                break
            case "TANK": {
               switch (curState) {
                   case '1000000000':
                       value = 20
                       color = '#FF0000'
                       setImage(<img style={{width: 44, height: 44}} src={redLow}></img>)
                       break
                   case '1000100000':
                       value = 20
                       color = '#00FA9E'
                       setImage(<img style={{width: 44, height: 44}} src={greenArrowUpLow}></img>)
                       arrow = true
                       arrowUp = true
                       break
                   case '1000010000':
                       value = 20
                       color = '#00FA9E'
                       arrow = true
                       setImage(<img style={{width: 44, height: 44}} src={greenArrowDownLow}></img>)
                       break
                   case '1000001000':
                       value = 50
                       color = '#666666'
                       setImage(<img style={{width: 44, height: 44}} src={grayMediumTank}></img>)
                       break
                   case '1000101000':
                       value = 50
                       color = '#00FA9E'
                       arrow = true
                       arrowUp = true
                       setImage(<img style={{width: 44, height: 44}} src={greenArrowUp}></img>)
                       break
                   case '1000011000':
                       value = 50
                       color = '#00FA9E'
                       arrow = true
                       setImage(<img style={{width: 44, height: 44}} src={greenArrowDown}></img>)
                       break
                   case '1000001100':
                       value = 100
                       color = "#666666"
                       setImage(<img style={{width: 44, height: 44}} src={grayFullTank}></img>)
                       break
                   case '1000101100':
                       value = 100
                       color = '#00FA9E'
                       arrow = true
                       arrowUp = true
                       setImage(<img style={{width: 44, height: 44}} src={greenArrowUpFull}></img>)
                       break
                   case '1000011100':
                       value = 100
                       color = '#00FA9E'
                       arrow = true
                       setImage(<img style={{width: 44, height: 44}} src={greenArrowDownFull}></img>)
                       break
                   case '1000000001':
                       value = 20
                       color = '#FF0000'
                       setImage(<img style={{width: 44, height: 44}} src={yellowArrowUpLow}></img>)
                       break
                   case '1000001001':
                       value = 50
                       color = '#666666'
                       setImage(<img style={{width: 44, height: 44}} src={yellowArrowUp}></img>)
                       break
                   case '1000001101':
                       value = 100
                       color = "#666666"
                       setImage(<img style={{width: 44, height: 44}} src={yellowArrowUpFull}></img>)
                       break
                   default:
                       value = 100
                       color = '#FF0000'
                       setImage(<img style={{width: 44, height: 44}} src={redFull}></img>)
                       break
               }
                break

            }
            case "CHAN": {
                switch (curState) {
                    case '000000':
                        value = 20
                        color = '#FF0000'
                        setImage(<img style={{width: 44, height: 44}} src={redLow}></img>)
                        break
                    case '000100':
                        value = 20
                        color = '#00FA9E'
                        arrow = true
                        arrowUp = true
                        setImage(<img style={{width: 44, height: 44}} src={greenArrowUpLow}></img>)
                        break
                    case '000010':
                        value = 20
                        color = '#00FA9E'
                        arrow = true
                        setImage(<img style={{width: 44, height: 44}} src={greenArrowDownLow}></img>)
                        break
                    case '010000':
                    case '001000':
                        value = 50
                        color = "#666666"
                        setImage(<img style={{width: 44, height: 44}} src={grayMediumTank}></img>)
                        break
                    case '010100':
                    case '001100':
                        value = 50
                        color = '#00FA9E'
                        arrow = true
                        arrowUp = true
                        setImage(<img style={{width: 44, height: 44}} src={greenArrowUp}></img>)
                        break
                    case '010010':
                    case '001010':
                        value = 50
                        color = '#00FA9E'
                        arrow = true
                        setImage(<img style={{width: 44, height: 44}} src={greenArrowDown}></img>)
                        break
                    case '011000':
                        value = 100
                        color = "#666666"
                        setImage(<img style={{width: 44, height: 44}} src={grayFullTank}></img>)
                        break
                    case '011100':
                        value = 100
                        color = '#00FA9E'
                        arrow = true
                        arrowUp = true
                        setImage(<img style={{width: 44, height: 44}} src={greenArrowUpFull}></img>)
                        break
                    case '011010':
                        value = 100
                        color = '#00FA9E'
                        arrow = true
                        setImage(<img style={{width: 44, height: 44}} src={greenArrowDownFull}></img>)
                        break
                    case '000001':
                        value = 20
                        color = '#FF0000'
                        setImage(<img style={{width: 44, height: 44}} src={yellowArrowDownLow}></img>)
                        break
                    case '010001':
                    case '001001':
                        value = 50
                        color = "#666666"
                        setImage(<img style={{width: 44, height: 44}} src={yellowArrowDown}></img>)
                        break
                    case '011001':
                        value = 100
                        color = "#666666"
                        setImage(<img style={{width: 44, height: 44}} src={yellowArrowDownFull}></img>)
                        break
                    default:
                        value = 100
                        color = '#FF0000'
                        setImage(<img style={{width: 44, height: 44}} src={redFull}></img>)
                        break

                }
                break
            }

            default:
                if (value < 50) {
                    color = "#FF0000"
                } else if (value <= 99) {
                    color = "#00FA9E"
                } else {
                    color = "#666666"
                }
                break

        }

        // function createRoundRect(x, y, width, height, radius, fill, color) {
        //     ctx.strokeStyle = color
        //     ctx.beginPath();
        //     ctx.moveTo(x, y);
        //     ctx.lineTo(x + width, y);
        //     ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        //     ctx.lineTo(x + width, y + height - radius);
        //     ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        //     ctx.lineTo(x + radius, y + height);
        //     ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        //     ctx.lineTo(x, y + radius);
        //     ctx.closePath();
        //     ctx.lineWidth = 2
        //     ctx.fillStyle = color
        //     ctx.stroke();
        //     if (fill) {
        //         ctx.fill()
        //     }
        //
        // }
        //
        // function fillValue(curValue = value, curColor = color) {
        //     let y = 40 - 0.34 * curValue
        //     let height = curValue * 0.34
        //     ctx.fillStyle = curColor
        //     ctx.fillRect(4, y, 36, height)
        //     ctx.stroke()
        // }
        //
        // function createArrow(beginY, y1, y2, y3, y4, curColor="#00FA9E") {
        //     ctx.strokeStyle = curColor
        //     ctx.beginPath();
        //     ctx.moveTo(20, beginY);
        //     ctx.lineTo(22, y1);
        //     ctx.lineTo(24, y2);
        //     ctx.closePath();
        //     ctx.lineWidth = 2
        //     ctx.fillStyle = curColor
        //     ctx.stroke();
        //
        //     ctx.beginPath();
        //     ctx.moveTo(22, y3);
        //     ctx.lineTo(22, y4);
        //     ctx.closePath();
        //     ctx.lineWidth = 2
        //     ctx.fillStyle = curColor
        //     ctx.stroke();
        //     ctx.fill()
        // }
        //
        // createRoundRect(1, 1, 42, 42, 12, false, color)
        // fillValue()
        // createRoundRect(3, 3, 38, 38, 8, false, "#000")
        //
        //
        // if (replace) {
        //     var img = new Image();
        //     img.onload = function() {
        //         ctx.drawImage(img, 10, 11.5, 25, 24);
        //     };
        //     img.src = replaceImage
        // }
        // if (color === "#00FA9E" && arrow) {
        //     if (arrowUp) {
        //         // up
        //         if (value > 67) {
        //             createArrow(21, 18, 21, 28, 20, "#000")
        //         } else if (value > 35) {
        //             createArrow(10, 7, 10, 10, 18)
        //         } else if (value <= 35) {
        //             createArrow(17, 14, 17, 24, 17)
        //         }
        //
        //     } else {
        //         // down
        //         if (value > 67) {
        //             createArrow(25, 28, 25, 18, 25, "#000")
        //         } else if (value > 35) {
        //             createArrow(15, 18, 15, 7, 16)
        //         } else if (value <= 35) {
        //             createArrow(20, 23, 20, 20, 13)
        //         }
        //
        //     }
        //
        // }

    }


    return (
        <div>
            <Tooltip placement="top" title={title}>
                <div>
                    {image}
                    {/*<canvas id={canvasName} width="44" height="44"/>*/}
                </div>
                <p className="tank-text">{name}</p>
            </Tooltip>
        </div>
    )
}