import React from "react";
import { HookDosing } from "../../hooks/HookDosing";


export const ManualControlPHDialog = ({
    unitId, tankPh, taskPhDown, taskPhUp, taskPhDownMax, taskPhUpMax
}) => {
    return (
        <div className="ph-dialog" draggable="false">
            <div
                className="ph-level-row"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0 19px",
                    alignItems: "center",
                }}
            >
                <div className="ph-value">
                    <p style={{ fontSize: "38px" }}>pH</p>
                    <p>{(Math.round((tankPh) * 100) / 100).toFixed(1)}</p>
                </div>
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>pH-</p>
                <HookDosing unitId={unitId} used={taskPhDown} taskEC='taskPhDown'
                    valueTaskEcMin={0} valueTaskEcMax={taskPhDownMax} mode={1} />
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>pH+</p>
                <HookDosing unitId={unitId} used={taskPhUp} taskEC='taskPhUp'
                    valueTaskEcMin={0} valueTaskEcMax={taskPhUpMax} mode={1} />
            </div>
        </div>
    )
}
