import React, {useState} from 'react';
import {HookSwitch} from "../../hooks/HookSwitch";
import {updateInputValue} from "../../Debounce";
import {REPLY_TIMEOUT} from "../Const";


let controlECProg = {
    0: "Выключен",
    1: "Включен",
    2: "Авто"
}

const ModeCO2 = ({unitId, mode, regType}) => {

    const [timeoutId, setTimeoutId] = useState(null)
    const [value, setValue] = useState(controlECProg[mode])
    const [disabled, setDisabled] = useState(false)

    React.useEffect(() => {
        setValue(controlECProg[mode])
        setDisabled(false)
        stop()
    }, [mode])

    function setProgram(value) {
        updateInputValue(unitId, 'mode', Number(value))
        setDisabled(true)
        start()
    }

    function start() {
        if (timeoutId) {
            return;
        }
        setTimeoutId(setTimeout(() => {
            setDisabled(false)
            setTimeoutId(null)
        }, REPLY_TIMEOUT))
    }

    function stop() {
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(null)
        }
    }


    return (
        <div>
            <div className="ph-dialog" draggable="false">
                <div className="row-ph-dialog ph-level-row">
                    <p>Вентиляция</p>
                    <HookSwitch unitId={unitId} value={regType} name={'regType'}/>
                </div>
                {Object.entries(controlECProg).map((prog, index) => {
                    return <div key={index} className="row-ph-dialog ph-level-row" onClick={() => {
                        if (!disabled && value !== prog[1]) {
                            setProgram(Number(prog[0]), prog[1])
                        }
                    }} >
                            <p style={ value === prog[1]
                                ? disabled ? {color: '#888888'} : {color: '#00FA9E'}
                                : disabled ? {color: '#888888'} : null }
                            >
                                {prog[1]}
                            </p>
                        <div/>
                    </div>
                })}
            </div>
        </div>
    );
};

export default ModeCO2;