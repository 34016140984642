import {Input} from "../../hooks/Input";
import React from "react";
import {HookSwitch} from "../../hooks/HookSwitch";


export const Timer = ({sysWaterTm, sysPauseTm, sysWaterTmControl, sysPauseTmControl, unitId, sysWaterOnPrep}) => {

    return (
        <div className="ph-dialog" draggable="false">
            <div className="row-ph-dialog ph-level-row">
                <p>Полив (мин.)</p>
                <Input value={sysWaterTm} min={sysWaterTmControl.min} max={sysWaterTmControl.max} mode={1}
                       deviceId={unitId} ckey={'sysWaterTm'}/>

            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>Пауза (мин.)</p>
                <Input value={sysPauseTm} min={sysPauseTmControl.min} max={sysPauseTmControl.max} mode={1}
                       deviceId={unitId} ckey={'sysPauseTm'}/>
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>Полив при подготовке</p>
                <HookSwitch name={'sysWaterOnPrep'} value={sysWaterOnPrep} unitId={unitId}/>
            </div>
            <div className="row-ph-dialog ph-level-row">

            </div>
        </div>

    )
}
