import React from "react";
import {Input} from "../../../hooks/Input";


export const PhLevel = ({
                            phLower, phUpper, phLowerMax,
                            phLowerMin, phUpperMax, phUpperMin, unitId
                        }) => {
    return (
        <div className="ph-dialog" draggable="false">
            <div className="row-ph-dialog ph-level-row">
                <p>Нижняя точка рН</p>
                <Input value={phLower} min={phLowerMin} max={phLowerMax} mode={0.1000000000000001} round={10} deviceId={unitId} ckey={'phLower'} floor={true}/>
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>Верхняя точка рН</p>
                <Input value={phUpper} min={phUpperMin} max={phUpperMax} mode={0.1000000000000001} round={10} deviceId={unitId} ckey={'phUpper'} floor={true}/>
            </div>
            <div className="row-ph-dialog ph-level-row">

            </div>
            <div className="row-ph-dialog ph-level-row">

            </div>
        </div>
    )
}
