import {Input} from "../../../../hooks/Input";
import React from "react";


export const SettingSelection = ({ecSp, ecA, ecB, ecC, unitId}) => {
    return (
        <div>
            <div className="ph-dialog">
                <div className="row-ph-dialog ph-level-row">
                    <p>Уровень ЕС</p>
                    <Input value={ecSp} min={0} max={5} mode={0.01} round={100} deviceId={unitId} ckey={'ecSp'}/>
                </div>
                <div className="row-ph-dialog ph-level-row">
                    <p>Удобрение A</p>
                    <Input value={ecA} min={1} max={100} mode={1} deviceId={unitId} ckey={'ecA'}/>
                </div>
                <div className="row-ph-dialog ph-level-row">
                    <p>Удобрение B</p>
                    <Input value={ecB} min={0} max={100} mode={1} deviceId={unitId} ckey={'ecB'}/>
                </div>
                <div className="row-ph-dialog ph-level-row">
                    <p>Удобрение C</p>
                    <Input value={ecC} min={0} max={100} mode={1} deviceId={unitId} ckey={'ecC'}/>
                </div>
            </div>
        </div>
    )
}
