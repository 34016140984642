import React, { useEffect, useState } from "react";
import { ShowDialog } from "../dialogs/ShowDialog";
import { CrashView } from "../dialogs/mode/CrashView";
import { updateInputValue } from "../../Debounce";

const MAIN_PAGE = 1;
const CRASH_PAGE = 2;

export const WateringDialog = ({ deviceId, widgetName, crashes, isAlarm, waterMode, unitId }) => {
    const [page, setPage] = React.useState(MAIN_PAGE);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        setIsDisabled(false)
    }, [waterMode])

    function changeWaterMode(key, value) {
        if (value !== waterMode && !isDisabled) {
            updateInputValue(unitId, key, value)
            setIsDisabled(true)
        }
    }

    return <div
        style={{
            height: "204px"
        }}>
        {
            page === CRASH_PAGE
                ? <ShowDialog
                    isAlarm={isAlarm}
                    title={"Просмотр аварий"}
                    number={deviceId}
                    widgetName={widgetName}
                    value={"crashView"}
                    changePage={() => setPage(MAIN_PAGE)}
                    component={<CrashView crashes={crashes} />}
                />
                : <div
                    className="mode-change"
                    draggable="false"
                    style={{
                        height: "100%"
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: 'column',
                            justifyContent: 'space-evenly',
                        }}
                    >
                        <div onClick={() => changeWaterMode('waterMode', 1)}
                            className={"mode-button" + (isDisabled ? " disabled-box"
                                : [1, 2, 4].includes(waterMode) ? " is-active" : "")}>
                            <p>
                                {[1, 2, 4].includes(waterMode) ? "Включен" : "Включить"}
                            </p>
                        </div>
                        <div onClick={() => changeWaterMode('waterMode', 0)}
                            className={"mode-button" + (isDisabled ? " disabled-box"
                                : waterMode == 0 ? " is-active" : "")}>
                            <p>
                                {waterMode == 0 ? "Выключен" : "Выключить"}
                            </p>
                        </div>
                        <div onClick={() => setPage(CRASH_PAGE)} className={"mode-button" + (waterMode == 3 ? " is-alarm" : "")}>
                            <p>
                                {waterMode == 3 ? "АВАРИЯ" : "ПРОСМОТР АВАРИЙ"}
                            </p>
                        </div>
                    </div>
                </div>
        }
    </div >
}