import React from "react";

import {HookSwitch} from "../../../hooks/HookSwitch";


export const Channel = ({unitId, sysHasChanRigth, sysHasChanLeft}) => {

    return (
        <div className="ph-dialog" draggable="false">
            <div className="row-ph-dialog ph-level-row">
                <p>Датчик 1</p>
                <HookSwitch name={'sysHasChanRigth'} value={sysHasChanRigth} unitId={unitId}/>

            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>Датчик 2</p>
                <HookSwitch name={'sysHasChanLeft'} value={sysHasChanLeft} unitId={unitId}/>
            </div>
            <div className="row-ph-dialog ph-level-row">

            </div>
        </div>
    )
}
