import React from 'react';
import { Form, Input } from 'antd';
import moment from 'moment';
import {textAreaKeyDown} from "../../lib/Utils";

const { TextArea } = Input;

const MessageType= {
    'unit.alarm.fault':'Авария',
    'unit.alarm.recovery':'Восстановление после аварии',
    'unit.control':'Управление атрибутом прибора',
    'generic':'Общий тип сообщения',
    'auth.login.passwd':'Вход по паролю'
}

const MessageLevel= {
    0:'Авария',
    1:'Предупреждение',
    2:'информационное'
}


const AuditForm = (props) => {
    let values = {...props.initialValues};
    const firstInputRef = React.useRef(null);

    React.useEffect(() => {
        setTimeout(() => {
            if (firstInputRef.current) {
                firstInputRef.current.focus({
                    cursor: 'end',
                })
            }
        }, 100);
    });



     if(Object.keys(values).length>0) {
        values.eventType = MessageType[values.type];
        values.note.updated = new Date().toISOString();
        values.note.created = values.note&&values.note.created?values.note.created:values.note.updated;
        values.note.read = values.note&&values.note.read?values.note.read:values.note.updated;
     }

    return <Form
        form={props.form}
        layout="vertical"
        name="formEdizm"
        onFieldsChange={props.onFieldsChange}
        initialValues={values}
        >
        
        <Form.Item
            name={['note', 'created']}
            hidden
            >
            <Input readOnly />
        </Form.Item>
        <Form.Item
            name={['note', 'updated']}
            hidden
            >
            <Input readOnly />
        </Form.Item>
        <Form.Item
            name={['note', 'read']}
            hidden
            >
            <Input readOnly />
        </Form.Item>
        <div className="col-item"><label className="row-item">Дата/Время: </label><label className="item">{moment(values.createdInSeconds*1000).format("DD.MM.YYYY HH:mm")}</label></div>
        <div className="col-item"><label className="row-item">Тип: </label><label className="item">{values.eventType}</label></div>
        <div className="col-item"><label className="row-item">Прибор: </label><label className="item">{values.unit?values.unit.number:null}</label></div>
        <div className="col-item"><label className="row-item">Уровень события: </label><label className="item">{MessageLevel[values.level]}</label></div>
        <div className="col-item"><label className="row-item">Код атрибута: </label><label className="item">{values.data?values.data.name:null}</label></div>
        <div className="col-item"><label className="row-item">Значение атрибута: </label><label className="item">{values.data?values.data.value:null}</label></div>
        <div className="col-item"><label className="row-item">Комментарий: </label></div>
        <Form.Item
            name={['note', 'text']}
        >
            <TextArea ref={firstInputRef} style={{height: 58}} onKeyDown={textAreaKeyDown}/>
        </Form.Item>

    </Form>


}    

export default AuditForm;