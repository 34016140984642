import React from 'react';
import PropTypes from 'prop-types';

import { Modal, notification, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons'
import { MSG_NO_RECORD_FORGETONE, MSG_SAVE_ERROR, MSG_CONFIRM_MODIFY } from './Const';
import requestToAPI from "./Request";
import { getLoginButton } from "./LoginForm";
import { confirm } from "./Dialogs";
import { resq$ } from 'resq'
import { useHistory } from "react-router-dom";
import moment from 'moment';

export const FORM_MAX_WIDTH = 30000;

const CommandButton = (props) => {
    return <Button {...props}>{props.children}</Button>
}


const EditForm = (props) => {
    const [data, setData] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [internalTrigger] = React.useState({});
    const [status] = React.useState({});
    const idName = props.idName;
    const history = useHistory();
    const [contextParams] = React.useState({});
    const noSave = !(props.editorContext.uriForInsert || props.editorContext.uriForUpdate);
    const convertors = props.convertors;
    const [, forceUpdate] = React.useReducer(x => x + 1, 0);

    const closeDialog = React.useCallback(() => {
        props.afterCancel();
        // если компонент размонтирован не надо устанавливать данные
        if (!contextParams.mountFlag) return;
        setData(null);
    }, [props, contextParams.mountFlag])

    const convertLoad = React.useCallback((response) => {
        let convertedResponse = { ...response };
        if (convertors && convertors.date) {
            convertors.date.forEach(element => {
                if (convertedResponse[element]) {
                    convertedResponse[element] = moment(convertedResponse[element]);
                }
            });
        }
        return convertedResponse;
    }, [convertors])

    const convertSave = React.useCallback((values,modeAdd) => {
        let convertedValues = { ...values };
        if (convertors && convertors.date) {
            convertors.date.forEach(element => {
                if (convertedValues[element]) {
                    convertedValues[element] = convertedValues[element].valueOf();
                }
            });
        }
        // конвертируем имена ключей .->_
        Object.keys(convertedValues).forEach(k=>{
            if(k.indexOf(".")>=0) {
                convertedValues[k.replaceAll(".","_")]=convertedValues[k];
                delete convertedValues[k];
            }
        })
        return convertedValues;
    }, [convertors])

    const setResponse = React.useCallback((resp) => {
        // если компонент размонтирован не надо устанавливать данные
        if (!contextParams.mountFlag) return;

        let response;
        if (props.afterLoad) {
            response = props.afterLoad(resp.data) ?? resp.data.record;
        } else {
            response = resp.data.record;
        }
        setLoading(false);
        if(response) {
            // преобразование в плоскую структуру
            if(props.transformerData) {
                props.transformerData(response); 
            }
            // Выполним конвертирование полученного ответа в соответствии с пропсом convertors
            setData(convertLoad(response));
        } else {
            setData({});
        }
    }, [props, convertLoad, contextParams.mountFlag])

    const handleError = React.useCallback((error) => {
        console.log(error);
        //если компонент размонтирован не надо устанавливать данные
        if (!contextParams.mountFlag) return;
        setLoading(false);
        notification.error({
            message: MSG_NO_RECORD_FORGETONE,
            description: error.message,
            btn: getLoginButton(error.status, history)
        })
        closeDialog();
    }, [closeDialog,contextParams.mountFlag,history])

    //Загрузка
    const load = React.useCallback(() => {
        status.modified = false;
        status.modeCopy = noSave; // если нет uri для сохранения переходим в режим копировать
        setLoading(true);
        // получение записи для update
        if (props.editorContext.uriForGetOne && props.editorContext.id) {
            console.log("Load for edit / id = " + props.editorContext.id);

            let promise;
            //uriForGetOne может быть функцией
            if(typeof props.editorContext.uriForGetOne == 'function') {
                promise = props.editorContext.uriForGetOne(props.editorContext.id)
            } else {
                promise = requestToAPI.post(props.editorContext.uriForGetOne, {id:props.editorContext.id})
            }

            promise
                .then(response => {
                    setResponse(response);
                })
                .catch(error => {
                    handleError(error);
                })
        } else 
        // получение записи для insert
        if (props.editorContext.uriForGetDefault) {
            console.log("Load for insert");
            requestToAPI.post(props.editorContext.uriForGetDefault)
                .then(response => {
                    setResponse(response);
                })
                .catch(error => {
                    handleError(error);
                })
        } else 
        // только для режима копирования
        if (props.editorContext.record) {
            status.modeCopy = props.modeCopy ?? false;
            setLoading(false);
            setData(convertLoad(props.editorContext.record));
        } else {
            setLoading(false);
            setData({});
        }
    }, [props, status, noSave, convertLoad,setResponse, handleError])

    React.useEffect(() => {
        if (data != null) {
            props.form.resetFields();
        }
    }, [data, props.form]);

    React.useEffect(() => {
        contextParams.mountFlag = true;

        if (!data && props.visible) {
            setData({});
            load();
        }
        // размонтирования компонента сбросит флаг
        return () => contextParams.mountFlag = false;
    }, [data, contextParams, load, props.visible]);

    //Сохранение
    const save = React.useCallback((values, after) => {
        const modeAdd = !values.id;
        console.log("Save values", values);
        // Выполним конвертирование сохраняемых данных в соответствии с пропсом convertors
        const valuesForSend = convertSave(values,modeAdd);
        console.log("GraphQL save values", valuesForSend);

        const graphQLQuery = modeAdd?props.editorContext.uriForInsert:props.editorContext.uriForUpdate;
        if (graphQLQuery) {
            let promise;
            //graphQLQuery может быть функцией
            if(typeof graphQLQuery == 'function') {
                promise = graphQLQuery(valuesForSend)
            } else {
                promise = requestToAPI.post(graphQLQuery, valuesForSend)
            }
            setLoading(true);
            promise
                .then(response => {
                    setLoading(false);
                    after(response);
                })
                .catch(error => {
                    setLoading(false);
                    notification.error({
                        message: MSG_SAVE_ERROR,
                        description: error.message,
                        btn: getLoginButton(error.status, history)
                    })

                    // Нужно обновить data иначе поля ввода пересоздадуться с initValues
                    const newdata = { ...data };
                    Object.assign(newdata, values);
                    setData(newdata);
                })
        } else {
            after(values);
        }
    }, [props, data, history, convertSave]);

    const beforeSave = React.useCallback((values) => {
        if (internalTrigger.onBeforeSave) {
            internalTrigger.onBeforeSave(values);
        }
        if (props.beforeSave) {
            props.beforeSave(values);
        }
    }, [props, internalTrigger])

    const afterSave = React.useCallback((response) => {
        status.modified = false;
        if (props.afterSave) {
            props.afterSave(response);
        }
    }, [props, status])

    const afterCopy = React.useCallback((response) => {
        if (props.afterCopy) {
            props.afterCopy(response);
        }
    }, [props])

    const handleCancel = () => {
        if (status.modified) {
            confirm(MSG_CONFIRM_MODIFY, () => {
                closeDialog();
            })
        } else {
            closeDialog();
        }
    };

    const handleKeyDown = (ev) => {
        switch (ev.which) {
            case 13: {
                // только если не в режиме добавления
                if (!status.modeCopy) {
                    let root = document.getElementsByClassName("__dialog__" + props.id);
                    const btn = resq$('button', root[0]).byProps({ id: "ok-btn" });
                    btn.props.onClick(btn);
                }
                ev.stopPropagation();
                break;
            }
            case 27: {
                let root = document.getElementsByClassName("__dialog__" + props.id);
                let btn;
                if (!status.modeCopy) {
                    btn = resq$('button', root[0]).byProps({ className: "ant-modal-close" });
                } else {
                    // Это кнопка Закрыть
                    btn = resq$('button', root[0]).byProps({ id: "ok-btn" });
                }
                if (btn && btn.props && btn.props.onClick) {
                    handleCancel(ev);
                }
                break;
            }
            default:
        }
    }

    const handleFieldsChange = (state) => {
        status.modified = true;
    }

    const handleOk = (ev, copyClickFlag) => {
        // если кнопка Ok, но в режиме копирования, то просто закрываем
        if (!copyClickFlag && status.modeCopy) {
            afterSave(null);
            setData(null);
            return;
        }
        if (props.status) {
            props.status.isModeAdd = !props.editorContext.id || copyClickFlag;
        }
        props.form.validateFields()
            .then((values) => {
                // при редактировании добавим Id в данные
                if (props.editorContext.id && !copyClickFlag) {
                    values[idName] = props.editorContext.id;
                }
                // переключаем в режим копирования
                if (copyClickFlag) {
                    status.modeCopy = true;
                }
                beforeSave(values);
                save(values, (response) => {
                    console.log("after save response", response);
                    // если копируем то окно не закрываем, данные не сбрасываем
                    if (!copyClickFlag) {
                        afterSave(response);
                        setData(null);
                    } else {
                        status.modified = false;
                        // при копировании нужно обновить data иначе поля ввода
                        // пересоздадуться с initValues
                        const newdata = { ...data };
                        Object.assign(newdata, values);
                        afterCopy(response)
                        setData(newdata);
                    }
                })
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    if (props.children.props.events) {
        props.children.props.events.handleOk = handleOk;
        props.children.props.events.forceUpdate = forceUpdate;
    }

    let modalWidth = props.width;
    if (typeof modalWidth == 'number' && modalWidth < 0) {
        modalWidth = undefined;
    }

    let modalHeight = props.height;
    if (typeof modalHeight !== 'number' || modalHeight < 0) {
        modalHeight = "auto";
    }

    const mergeInitialValues = (data, initialValues) => {
        for (var i in initialValues) {
            data[i] = initialValues[i];
        }
        return data;
    }

    const additionalButtons = props.children.props.additionalButtons ?? [];

    if(props.interface) {
        props.interface.isModified=()=>(status.modified);
        internalTrigger.interface = props.interface;
    }

    return <Modal
        centered={true}
        destroyOnClose
        preserve={false}
        wrapClassName={"__dialog__" + props.id}
        visible={props.visible}
        title={props.title || ((props.editorContext.id && !status.modeCopy) ? "Изменение записи" : "Новая запись")}
        width={modalWidth}
        bodyStyle={{ "height": modalHeight }}
        closeIcon={<CloseOutlined onClick={handleCancel} />}
        footer={props.isFooter ? [
            <CommandButton id="ok-btn" type={status.modeCopy ? null : "primary"} key="3" loading={loading} onClick={handleOk}>{status.modeCopy ? 'Закрыть' : (props.saveButtonTitle ?? 'Сохранить')}</CommandButton>,
            ...additionalButtons
        ]: null}
    >
        <div onKeyDown={handleKeyDown}>
            {props.children ? React.cloneElement(props.children, {
                initialValues: mergeInitialValues(data || {}, props.children.props.initialValues),
                internalTrigger: internalTrigger,
                onFieldsChange: handleFieldsChange,
                mode: props.editorContext.id ? 1 : 0
            }) : null}
        </div>
    </Modal>
}

export const ShowModal = (props) => {
    //props.form.resetFields();
    const dialogId = props.dialogId || "Dialog-" + Math.floor(Math.random() * 10000);
    return <EditForm
        key={dialogId}
        id={dialogId}
        visible={true}
        form={props.form}
        title={props.title}
        width={props.width ?? FORM_MAX_WIDTH}
        height={props.height}
        editorContext={props.editorContext}
        afterSave={(response) => {
            props.destroyDialog(dialogId)
            if (props.afterSave) props.afterSave(response);
        }}
        afterCancel={() => {
            props.destroyDialog(dialogId)
        }}
        beforeSave={props.onBeforeSave}
        idName={props.idName ?? "id"}
        convertors={props.convertors}
        modeCopy={props.modeCopy}
        transformerData={props.transformerData}
        saveButtonTitle={props.saveButtonTitle}
        isFooter={props.isFooter}
        >
        {React.cloneElement(props.content, {
            form: props.form,
            initialValues: {}
        })}
    </EditForm>
}


export default EditForm;

EditForm.propTypes = {
    idName: PropTypes.string,  // default "id"
    editorContext: PropTypes.object.isRequired,
    interface:PropTypes.object,
    afterCancel: PropTypes.func.isRequired,
    beforeSave: PropTypes.func,
    afterSave: PropTypes.func,
    afterCopy: PropTypes.func,
    afterLoad: PropTypes.func,
    visible: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    form: PropTypes.object.isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    copyButtonFlag: PropTypes.bool,
    convertors: PropTypes.object,
    transformerData:PropTypes.func,
    isFooter: PropTypes.bool.isRequired,
}

EditForm.defaultProps = {
    idName: "id",
    visible: false,
    width: -1,
    isFooter: true
}