import React, {useEffect, useState} from "react";


export const RecordIndicator = ({massCurAlarm, isOn, massAlarm, name}) => {

    const [isAlarm, setIsAlarm] = useState(false)

    useEffect(() => {
        setIsAlarm(defAlarm())
    }, [massAlarm])


    function defAlarm() {
        for (let i = 0; i < massAlarm.length; i++) {
            if (massCurAlarm.includes(massAlarm[i].name)){
                return true
            }
        }
        return false
    }


    return <div className="record-indicator">
        <div className={ isOn
            ? isAlarm ? "letter-box red-box" : "letter-box green-box" : "letter-box green-box"}>
            {isOn
                ? 'A'
                : 'M'
            }
        </div>
        <p>{name}</p>
    </div>
}