import React, { useEffect } from 'react';
import { DatePicker, Tooltip, Radio, Space } from "antd";
import moment from "moment";

export const DataChange = ({ value, changePage, applyDataChange, curTimeMode, timeModes, date }) => {
    const [firstDate, setFirstDate] = React.useState(!timeModes.some(tm => tm.id === curTimeMode) ? moment(date[0]) : null)
    const [secondDate, setSecondDate] = React.useState(!timeModes.some(tm => tm.id === curTimeMode) ? moment(date[1]) : null)
    const [timeMode, setTimeMode] = React.useState(curTimeMode)

    function addZeroForItem(item) {
        if (item < 10) {
            return '0' + item
        }
        return item
    }

    function getTimestamp(date, milliseconds = '000') {
        let year = date.getFullYear()
        let month = addZeroForItem(date.getMonth() + 1)
        let day = addZeroForItem(date.getDate())
        let hours = addZeroForItem(date.getHours())
        let minutes = addZeroForItem(date.getMinutes())
        return year + "-" + month + "-" + day + "T" + hours + ":" + minutes + ":00." + milliseconds
    }

    const returnStatWidget = () => {
        changePage(value, false)
    }

    const saveData = () => {
        let begin
        let end

        if (timeMode === -1) {
            begin = getTimestamp(new Date(firstDate))
            end = getTimestamp(new Date(secondDate), '001')
            // 001 милисекунда нужна, чтобы интервал можно было отличить от забитых TIME_MODES,
            // когда границы интервала приходят с бэка (в БД не хранится выбранный режим)
        }
        else {
            const now = new Date()
            end = getTimestamp(now)
            begin = getTimestamp(new Date(now.setHours(now.getHours() - timeMode)))
        }
        applyDataChange(begin, end, timeMode)
        returnStatWidget()
    }

    const onChange = (e) => {
        setFirstDate(null)
        setSecondDate(null)
        setTimeMode(e.target.value)
    };

    const getErrorMessage = () => {
        if (timeMode === -1 && (!firstDate || !secondDate)) {
            return "Выберите начальную и конечную даты"
        }
        if (firstDate && secondDate && firstDate >= secondDate) {
            return "Начальная дата должна быть меньше, чем конечная"
        }
        return null;
    }
    const errorMessage = getErrorMessage();

    return (
        <div className="data-change">
            <div className="wrapper">
                <Radio.Group onChange={onChange} value={timeMode}>
                    <Space>
                        <Tooltip placement="left" title={timeModes[0].description}>
                            <Radio.Button value={timeModes[0].id}>{timeModes[0].label}</Radio.Button>
                        </Tooltip>
                        <Tooltip placement="top" title={timeModes[1].description}>
                            <Radio.Button value={timeModes[1].id}>{timeModes[1].label}</Radio.Button>
                        </Tooltip>
                        <Tooltip placement="top" title={timeModes[2].description}>
                            <Radio.Button value={timeModes[2].id}>{timeModes[2].label}</Radio.Button>
                        </Tooltip>
                        <Tooltip placement="top" title={timeModes[3].description}>
                            <Radio.Button value={timeModes[3].id}>{timeModes[3].label}</Radio.Button>
                        </Tooltip>
                        <Tooltip placement="right" title={timeModes[4].description}>
                            <Radio.Button value={timeModes[4].id}>{timeModes[4].label}</Radio.Button>
                        </Tooltip>
                    </Space>
                </Radio.Group>
                <Radio.Group onChange={onChange} value={timeMode}>
                    <Space>
                        <Tooltip placement="left" title={timeModes[5].description}>
                            <Radio.Button value={timeModes[5].id}>{timeModes[5].label}</Radio.Button>
                        </Tooltip>
                        <Tooltip placement="bottom" title={timeModes[6].description}>
                            <Radio.Button value={timeModes[6].id}>{timeModes[6].label}</Radio.Button>
                        </Tooltip>
                        <Tooltip placement="bottom" title={timeModes[7].description}>
                            <Radio.Button value={timeModes[7].id}>{timeModes[7].label}</Radio.Button>
                        </Tooltip>
                        <Tooltip placement="bottom" title={timeModes[8].description}>
                            <Radio.Button value={timeModes[8].id}>{timeModes[8].label}</Radio.Button>
                        </Tooltip>
                        <Tooltip placement="right" title="Укажите интервал вручную">
                            <Radio.Button value={-1}>Интервал</Radio.Button>
                        </Tooltip>
                    </Space>
                </Radio.Group>
                <div className="input-group">
                    <div className="field">
                        <p>Выберите начальную дату и время</p>
                        <Tooltip placement="bottom" title={timeMode !== -1 ? 'Выберите режим "Интервал"' : null}>
                            <DatePicker
                                disabled={timeMode !== -1}
                                showTime size="medium"
                                onChange={(value) => setFirstDate(value)}
                                format={'DD.MM.YYYY HH:mm'}
                                value={firstDate} />
                        </Tooltip>
                    </div>
                    <div className="field">
                        <p>Выберите конечную дату и время</p>
                        <Tooltip placement="bottom" title={timeMode !== -1 ? 'Выберите режим "Интервал"' : null}>
                            <DatePicker
                                disabled={timeMode !== -1}
                                showTime size="medium"
                                onChange={(value) => setSecondDate(value?.set('second', 0))} // требуется 0 секунд для корректного сравнения
                                format={'DD.MM.YYYY HH:mm'}
                                value={secondDate} />
                        </Tooltip>
                    </div>
                    <div className="buttons-block reset-buttons">
                        {errorMessage === null
                            ? <button onClick={saveData}>Применить</button>
                            : <Tooltip placement="right" title={errorMessage}>
                                <button disabled={true}
                                    style={{
                                        color: '#333333',
                                        border: '1px solid #333333', borderRadius: 5
                                    }}>
                                    Применить
                                </button>
                            </Tooltip>
                        }
                        <button onClick={returnStatWidget}>Отмена</button>
                    </div>
                </div>
            </div>
        </div>
    )
};

