import React from 'react';
import { drawTimeDurationFromInt } from '../../../lib/Utils';
import { Tooltip } from 'antd';

export const TimeButton = ({ handleClick, dayTime, dayTimeTitle }) => {
    const timeString = drawTimeDurationFromInt(dayTime);

    return <div
        onClick={handleClick}
        className="record-box"
        style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '49px 26px',
            cursor: 'default',
            height: 145
        }}
    >
        <Tooltip
            placement="topLeft"
            title={dayTimeTitle?.title}
        >
            <span
                className="wrap-text"
                style={{
                    fontSize: timeString.length == 5 ? 56 : 46,
                    fontWeight: 'bold',
                    margin: 'auto',
                }}
            >
                {timeString}
            </span>
        </Tooltip>
    </div>
}