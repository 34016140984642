export const colors = ['#2195f2', '#4cae50', '#f34336', '#fec007', '#7e9ba9']

export const signals = {
    tankPh: {
        value: 'tankPh',
        label: 'pH',
        extendedLabel: 'pH',
        fullLabel: 'pH фактор раствора (pH)',
    },
    tankEc: {
        value: 'tankEc',
        label: 'EC',
        extendedLabel: 'EC, mS/cm',
        fullLabel: 'Электропроводность раствора (EC)',
    },
    tankTemp: {
        value: 'tankTemp',
        label: 't',
        extendedLabel: 't, ℃',
        fullLabel: 'Температура раствора в градусах Цельсия (t)',
    },
    ecNutrUsedA: {
        value: 'ecNutrUsedA',
        label: 'A',
        extendedLabel: 'A, ml',
        fullLabel: 'Объем удобрения «A» (A)',
    },
    ecNutrUsedB: {
        value: 'ecNutrUsedB',
        label: 'B',
        extendedLabel: 'B, ml',
        fullLabel: 'Объем удобрения «B» (B)',
    },
    ecNutrUsedC: {
        value: 'ecNutrUsedC',
        label: 'C',
        extendedLabel: 'C, ml',
        fullLabel: 'Объем удобрения «C» (C)',
    },
    phAgentUsedUp: {
        value: 'phAgentUsedUp',
        label: 'phAgentUsedUp',
        extendedLabel: 'ph+, ml',
        fullLabel: 'Объем реагента «pH+» (phAgentUsedUp)',
    },
    phAgentUsedDown: {
        value: 'phAgentUsedDown',
        label: 'phAgentUsedDown',
        extendedLabel: 'ph-, ml',
        fullLabel: 'Объем реагента «pH-» (phAgentUsedDown)',
    },
    dinTankAlarm: {
        value: 'dinTankAlarm',
        label: 'dinTankAlarm',
        extendedLabel: 'Ав. уровень (бак)',
        fullLabel: 'Аварийный уровень раствора в баке. (Ав. уровень (бак))',
    },
    dinTankUpper: {
        value: 'dinTankUpper',
        label: 'dinTankUpper',
        extendedLabel: 'Верх. уровень (бак)',
        fullLabel: 'Верхний уровень раствора в баке (Верх. уровень (бак))',
    },
    dinTankLower: {
        value: 'dinTankLower',
        label: 'dinTankLower',
        extendedLabel: 'Нижн. уровень (бак)',
        fullLabel: 'Нижний уровень раствора в баке (Нижн. уровень (бак))',
    },
    dinChanL: {
        value: 'dinChanL',
        label: 'dinChanL',
        extendedLabel: 'dinChanL',
        fullLabel: 'Наличие раствора в «левом» блоке каналов. (dinChanL)',
    },
    dinChanR: {
        value: 'dinChanR',
        label: 'dinChanR',
        extendedLabel: 'dinChanR',
        fullLabel: 'Наличие раствора в «правом» блоке каналов. (dinChanR)',
    },
    dinSysLeak: {
        value: 'dinSysLeak',
        label: 'dinSysLeak',
        extendedLabel: 'Датчик протечки',
        fullLabel: 'Датчик протечки (dinSysLeak)',

    },
    dinEcRdyA: {
        value: 'dinEcRdyA',
        label: 'dinEcRdyA',
        extendedLabel: 'dinEcRdyA',
        fullLabel: 'Наличие компонента удобрения «A» (dinEcRdyA)',
    },
    dinEcRdyB: {
        value: 'dinEcRdyB',
        label: 'dinEcRdyB',
        extendedLabel: 'dinEcRdyB',
        fullLabel: 'Наличие компонента удобрения «B» (dinEcRdyB)',
    },
    dinEcRdyC: {
        value: 'dinEcRdyC',
        label: 'dinEcRdyC',
        extendedLabel: 'dinEcRdyC',
        fullLabel: 'Наличие компонента удобрения «C» (dinEcRdyC)',
    },
    dinPhRdyUp: {
        value: 'dinPhRdyUp',
        label: 'dinPhRdyUp',
        extendedLabel: 'dinEcRdyUp',
        fullLabel: 'Наличие регулятора кислотности «pH+» (dinPhRdyUp)',
    },
    dinPhRdyDown: {
        value: 'dinPhRdyDown',
        label: 'dinPhRdyDown',
        extendedLabel: 'dinEcRdyDown',
        fullLabel: 'Наличие регулятора кислотности «pH-» (dinPhRdyDown)',
    },
    dinSampler: {
        value: 'dinSampler',
        label: 'dinSampler',
        extendedLabel: 'dinSampler',
        fullLabel: 'Наличие раствора в пробоотборнике (dinSampler)',
    },
    outEcA: {
        value: 'outEcA',
        label: 'outEcA',
        extendedLabel: 'outEcA',
        fullLabel: 'Насос подачи удобрения «A» (outEcA)',
    },
    outEcB: {
        value: 'outEcB',
        label: 'outEcB',
        extendedLabel: 'outEcB',
        fullLabel: 'Насос подачи удобрения «B» (outEcB)',

    },
    outEcC: {
        value: 'outEcC',
        label: 'outEcC',
        extendedLabel: 'outEcC',
        fullLabel: 'Насос подачи удобрения «C» (outEcC)',

    },
    outPhUp: {
        value: 'outPhUp',
        label: 'outPhUp',
        extendedLabel: 'outPhUp',
        fullLabel: 'Насос подачи реагента «pH+» (outPhUp)',

    },
    OutPhDown: {
        value: 'OutPhDown',
        label: 'OutPhDown',
        extendedLabel: 'OutPhDown',
        fullLabel: 'Насос подачи реагента «pH-» (OutPhDown)',

    },
    outWater: {
        value: 'outWater',
        label: 'outWater',
        extendedLabel: 'outWater',
        fullLabel: 'Полив (outWater)',

    },
    outTankIn: {
        value: 'outTankIn',
        label: 'outTankIn',
        extendedLabel: 'outTankIn',
        fullLabel: 'Подача воды в бак (outTankIn)',

    },
    outTankOut: {
        value: 'outTankOut',
        label: 'outTankOut',
        extendedLabel: 'outTankOut',
        fullLabel: 'Слив раствора из бака (outTankOut)',

    },
    outChanOut: {
        value: 'outChanOut',
        label: 'outChanOut',
        extendedLabel: 'outChanOut',
        fullLabel: 'Слив раствора из каналов (outChanOut)',

    },
    outTherm: {
        value: 'outTherm',
        label: 'outTherm',
        extendedLabel: 'outTherm',
        fullLabel: 'Подогрев/охлаждение раствора (outTherm)',

    },
    outSampler: {
        value: 'outSampler',
        label: 'outSampler',
        extendedLabel: 'outSampler',
        fullLabel: 'Отбор проб и перемешивание раствора (outSampler)',

    }
}

export const signalsH = {
    humidity: {
        value: 'humidity',
        label: 'humidity',
        extendedLabel: 'humidity, %',
        fullLabel: 'Относительная влажность почвы/субстрата в % (humidity)',
    },
    outWater: {
        value: 'outWater',
        label: 'outWater',
        extendedLabel: 'Состояние вых. полива',
        fullLabel: 'Состояние выхода полива (outWater)',
    }
}


export const signalsCO2 = {
    sensorIsOK: {
        value: 'sensorIsOK',
        label: 'sensorIsOK',
        extendedLabel: 'Датчик CO2 исправен',
        fullLabel: 'Датчик CO2 исправен (sensorIsOK)',
    },
    sensorValue: {
        value: 'sensorValue',
        label: 'sensorValue',
        extendedLabel: 'Значение конц. CO2, ppm',
        fullLabel: 'Значение концентрации CO2 в ppm (sensorValue)',
    },
    out220: {
        value: 'out220',
        label: 'out220',
        extendedLabel: 'Состояние вых. прибора',
        fullLabel: 'Состояние выхода прибора (out220)',
    },
    reboot: {
        value: 'reboot',
        label: 'reboot',
        extendedLabel: 'reboot',
        fullLabel: 'Флаг перезагрузки прибора (reboot)',
    },
    tbConnecnted: {
        value: 'tbConnecnted',
        label: 'tbConnecnted',
        extendedLabel: 'tbConnecnted',
        fullLabel: 'Флаг подключения к MQTT-серверу (tbConnecnted)',
    }
}

export const signalsPHESP = {
    tankPh: {
        value: 'tankPh',
        label: 'pH',
        extendedLabel: 'pH',
        fullLabel: 'pH фактор раствора (pH)',
    },
    tankEC: {
        value: 'tankEC',
        label: 'EC',
        extendedLabel: 'EC',
        fullLabel: 'Электропроводность раствора (EC)',
    },
    phAgentUsedUp: {
        value: 'phAgentUsedUp',
        label: 'phAgentUsedUp',
        extendedLabel: 'phAgentUsedUp, ml',
        fullLabel: 'Объём реагента «pH+», (phAgentUsedUp)',
    },
    phAgentUsedDown: {
        value: 'phAgentUsedDown',
        label: 'phAgentUsedDown',
        extendedLabel: 'phAgentUsedDown, ml',
        fullLabel: 'Объём реагента «pH-» (phAgentUsedDown)',
    },
    tbConnected: {
        value: 'tbConnected',
        label: 'tbConnected',
        extendedLabel: 'tbConnected',
        fullLabel: 'Флаг подключения к платформе (tbConnected)',
    },
    wPh: {
        value: 'wPh',
        label: 'wPh',
        extendedLabel: 'wPh',
        fullLabel: 'Вес текущего реагента (wPh)',
    },
    rPh: {
        value: 'rPh',
        label: 'rPh',
        extendedLabel: 'rPh',
        fullLabel: 'Дисперсия шума веса реагента (rPh)',
    },
    outPhUp: {
        value: 'outPhUp',
        label: 'outPhUp',
        extendedLabel: 'outPhUp',
        fullLabel: 'Насос подачи реагента «pH+» (outPhUp)',
    },
    outPhDown: {
        value: 'outPhDown',
        label: 'outPhDown',
        extendedLabel: 'outPhDown',
        fullLabel: 'Насос подачи реагента «pH-» (outPhDown)',
    },
}

export const signalsZenith = {
    tbConnected: {
        value: 'tbConnected',
        label: 'tbConnected',
        extendedLabel: 'tbConnected',
        fullLabel: 'Флаг подключения к платформе (tbConnected)',
    },
    dinLeak: {
        value: 'dinLeak',
        label: 'dinLeak',
        extendedLabel: 'dinLeak',
        fullLabel: 'Вход «Протечка» (dinLeak)',
    },
    dinTankLower: {
        value: 'dinTankLower',
        label: 'dinTankLower',
        extendedLabel: 'dinTankLower',
        fullLabel: 'Вход «Минимальный уровень воды в баке» (dinTankLower)',
    },
    outPwmEn: {
        value: 'outPwmEn',
        label: 'outPwmEn',
        extendedLabel: 'outPwmEn',
        fullLabel: 'Выход «Разрешения работы диммеров» (outPwmEn)',
    },
    outWater: {
        value: 'outWater',
        label: 'outWater',
        extendedLabel: 'outWater',
        fullLabel: 'Выход «Полив» (outWater)',
    },
    outLight: {
        value: 'outLight',
        label: 'outLight',
        extendedLabel: 'outLight',
        fullLabel: 'Выход «Свет» (outLight)',
    },
    dayTime: {
        value: 'dayTime',
        label: 'dayTime',
        extendedLabel: 'dayTime, min',
        fullLabel: 'Время (dayTime)',
    },
    waterMode: {
        value: 'waterMode',
        label: 'waterMode',
        extendedLabel: 'waterMode',
        fullLabel: 'Состояние системы полива (waterMode)',
    },
    waterOnTm: {
        value: 'waterOnTm',
        label: 'waterOnTm',
        extendedLabel: 'waterOnTm, min',
        fullLabel: 'Время полива (waterOnTm)',
    },
    waterOffTm: {
        value: 'waterOffTm',
        label: 'waterOffTm',
        extendedLabel: 'waterOffTm, min',
        fullLabel: 'Время паузы между поливами (waterOffTm)',
    },
    lightStart: {
        value: 'lightStart',
        label: 'lightStart',
        extendedLabel: 'lightStart, min',
        fullLabel: 'Время начала рассвета (lightStart)',
    },
    lightDay: {
        value: 'lightDay',
        label: 'lightDay',
        extendedLabel: 'lightDay, h',
        fullLabel: 'Продолжительность дня (lightDay)',
    },
    lightState: {
        value: 'lightState',
        label: 'lightState',
        extendedLabel: 'lightState',
        fullLabel: 'Системы освещения (lightState)',
    },
}