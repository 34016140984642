import React from "react";
import {HookSwitch} from "../../../hooks/HookSwitch";


export const TankDialog = ({unitId, sysHasTank}) => {

    return (
        <div>
            <div className="ph-dialog">
                <div className="row-ph-dialog ph-level-row">
                    <p>Датчики</p>
                    <HookSwitch unitId={unitId} value={sysHasTank} name={'sysHasTank'}/>
                </div>
                <div className="row-ph-dialog ph-level-row">

                </div>
            </div>
        </div>
    )
}