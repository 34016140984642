import requestToAPI from './Request';
import { ApolloClient, InMemoryCache } from  '@apollo/client';
import { WebSocketLink } from "@apollo/client/link/ws";
import gql from 'graphql-tag';

var url = new URL(process.env.REACT_APP_ENDPOINT, window.location)
var newUri;

if (url.protocol === "https:") {
    newUri = "wss://" + url.host + url.pathname;
} else {
    newUri = "ws://" + url.host + url.pathname;
}

const GRAPHQL_ENDPOINT = newUri;




export const subscribe = (query, func)=>{

    if(!requestToAPI.authToken) {
        throw new Error("subscribe error: authToken not found");
    }
    let client;
    if(!client) {
        client = createApolloClient(requestToAPI.authToken);
    }

    client.subscribe({
        query: gql`${query}`,
        variables: {}
      }).subscribe({
        next (data) {
            func(data);
        }
    });
}

const createApolloClient = (authToken) => {
    console.log("process.env",process.env);

    return new ApolloClient({
     link: new WebSocketLink({
       uri: GRAPHQL_ENDPOINT,
       options: {
         reconnect: true,
         connectionParams: {
            headers: {
              Authorization: `Bearer ${authToken}`
            }
         }
       }
      }),
      cache: new InMemoryCache(),
    });
  };
