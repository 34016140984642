import React, {useState} from "react";
import {updateInputValue} from "../Debounce";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {REPLY_TIMEOUT} from "../widget/Const"


export const HookDosing = ({unitId, taskEC, valueTaskEcMin, valueTaskEcMax=Number.MAX_SAFE_INTEGER, used, mode, afterSetValue}) => {
    let value;
    if (used >= valueTaskEcMax) value = valueTaskEcMax || 0
    else if (used >= valueTaskEcMin) value = Math.round(used)
    else value = valueTaskEcMin || 0

    const [valueTaskEc, setValueTaskEc] = useState(value)
    const [isDisabled, setIsDisabled] = useState(false)
    const [isDisabledL, setIsDisabledL] = useState(false)
    const [isDisabledR, setIsDisabledR] = useState(false)
    const [timeoutId, setTimeoutId] = useState(null)

    const intervalRef = React.useRef(null)

    React.useEffect(()=> {
        return () => stopCounter()
    }, []);

    React.useEffect(() => {
        stop()
        setIsDisabled(false)
        setIsDisabledR(value >= valueTaskEcMax);
        setIsDisabledL(value <= valueTaskEcMin);
        setValueTaskEc(value)
    }, [value])

    const startCounter = (fn) => {
        setValueTaskEc((prevCounter) => fn(prevCounter))
        if (intervalRef.current) return;
        intervalRef.current = setInterval(() => {
            setValueTaskEc((prevCounter) => fn(prevCounter))
        }, 120);
    };

    async function stopCounter() {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
        setIsDisabledR(valueTaskEc >= valueTaskEcMax);
        setIsDisabledL(valueTaskEc <= valueTaskEcMin);
    }

    function afterSet() {
        stopCounter();
        if(afterSetValue) afterSetValue(valueTaskEc);
    }

    function increaseValue(prev) {
        setIsDisabledL(false)
        return Math.min(valueTaskEcMax, Math.ceil((prev + mode) * 100) / 100)
    }

    function decreaseValue(prev) {
        setIsDisabledR(false)
        return Math.max(valueTaskEcMin, Math.floor((prev - mode) * 100) / 100)
    }


    function applyTaskValue(key, value) {
        setIsDisabled(true)
        start()
        updateInputValue(unitId, key, value)
    }

    function start() {
        if (timeoutId) {
            return;
        }

        setTimeoutId(setTimeout(() => {
            setIsDisabled(false)
            setValueTaskEc(Math.round((valueTaskEc) * 100) / 100)
            setIsDisabledR(valueTaskEc >= valueTaskEcMax);
            setIsDisabledL(valueTaskEc <= valueTaskEcMin);
            setTimeoutId(null)
        }, REPLY_TIMEOUT))

    }

    function stop() {
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(null)
        }
    }

    return (
        <div className="counter-plus-minus">
            <div className="counter-plus-minus">
                <button onMouseDown={() => startCounter(decreaseValue)}
                        disabled={isDisabledL || isDisabled}
                        onMouseUp={afterSet}
                        onMouseLeave={afterSet}
                        className={isDisabledL || isDisabled ? "invisible-button disabled-button" : "invisible-button"}>
                    <MinusOutlined/>
                </button>
                <div className={isDisabled ? "clock-face clock-face-disabled" : "clock-face"}>
                    <p>{valueTaskEc}</p>
                </div>
                <button onMouseDown={() => startCounter(increaseValue)}
                        disabled={isDisabledR || isDisabled}
                        onMouseUp={afterSet}
                        onMouseLeave={afterSet}
                        className={isDisabledR || isDisabled ? "invisible-button disabled-button" : "invisible-button"}>
                    <PlusOutlined/>
                </button>
            </div>
            <button disabled={isDisabled}
                onClick={() => applyTaskValue(taskEC, valueTaskEc)}
                    className={isDisabled ? "button-arrow triangle-button triangle-button-hover" : "button-arrow triangle-button"}>
                <div className={isDisabled ? "rectangle-icon disabled-button-tria" : "rectangle-icon"}>
                    <svg width="18" height="21" viewBox="0 0 18 21" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.470459 0.15918L17.6237 10.5271L0.470459 20.895V0.15918Z" fill={isDisabled ? "#333333" : "white"}/>
                    </svg>
                </div>
            </button>
        </div>
    )
}
