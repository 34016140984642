import {Col, Row} from "antd";
import React from "react";
import requestToAPI from "../../lib/Request";
import {MUT_UPD_SETTINGS_WIDGET, QUERY_GET_WIDGET_INFO} from "../../lib/Query";
import {Widget} from "./Widget";

export const Dashboard = ({widgets, closeWidget, setAfterDragDevices}) => {
    const [currentDevice, setCurrentDevice] = React.useState({})

    async function setPosWidget(id, pos) {
        await requestToAPI.post(QUERY_GET_WIDGET_INFO, {
            id: id
        }).then((response) => {
            let beg = ''
            let end = ''
            let filters = []
            if (response.data.dashboardWidgetByPk) {
                beg = response.data.dashboardWidgetByPk.setting.beg
                end = response.data.dashboardWidgetByPk.setting.end
                filters = response.data.dashboardWidgetByPk.setting.filters
            }
            requestToAPI.post(MUT_UPD_SETTINGS_WIDGET, {
                id: id,
                pos: pos,
                filters: filters,
                beg: beg,
                end: end,
            }).catch(error => {
                console.error(error);
                throw error;
            })
        }).catch(error => {
            console.error(error);
            throw error;
        })

    }

    function dragStartHandler(e, device) {
        setCurrentDevice(device)

    }

    function dragEndHandler(e) {
        e.preventDefault()
    }

    function dragOverHandler(e) {
        e.preventDefault()
    }

    async function dropHandler(e, dr_device) {
        e.preventDefault()

        if (currentDevice.setting.pos === dr_device.setting.pos) {
            return
        }


        setAfterDragDevices(widgets.map(device => {
            if (device.id === dr_device.id) {
                setPosWidget(dr_device.id, currentDevice.setting.pos, currentDevice)
                return {...device, setting: currentDevice.setting}
            }
            if (device.id === currentDevice.id) {
                setPosWidget(currentDevice.id, dr_device.setting.pos, currentDevice)
                return {...device, setting: dr_device.setting}
            }
            return device
        }))


    }

    const sortItems = (a, b) => {
        if (a.setting.pos > b.setting.pos) {
            return 1
        } else {
            return -1
        }
    }

    return (
        <Row gutter={[25, 16]} className="grid-widgets">
            {widgets.sort(sortItems).map((widget) => {
                return <Col
                        key={widget.id}
                        onDragStart={(e) => dragStartHandler(e, widget)}
                        onDragLeave={(e) => dragEndHandler(e)}
                        onDragEnd={(e) => dragEndHandler(e)}
                        onDragOver={(e) => dragOverHandler(e)}
                        onDrop={(e) => dropHandler(e, widget)}
                    >
                        <Widget type={widget.type.id} code={widget.type.code} widget={widget} closeWidget={closeWidget}/>

                    </Col>
            })}
        </Row>


    )
}
