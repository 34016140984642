import React, {useEffect} from 'react';
import App from '../../App';
import ModuleHeader from "../../lib/ModuleHeader";
import { withRouter } from "react-router";
import { CONTOUR_ADMIN, MODULE_DEVICES } from '../../lib/ModuleConst';
import {
  MUT_UPD_DEVICE,
  QUERY_GET_DEVICE,
  QUERY_GET_LIST_DEVICE,
  MUT_INS_DEVICE,
  QUERY_DETACH_DEVICE,
  MUT_INS_DASHBOARD_WIDGET, QUERY_GET_DASHBOARD_WIDGETS
} from '../../lib/Query';
import { responsiveMobileColumn, isMobile } from '../../lib/Responsive';
import {
  DEFAULT_TABLE_CONTEXT,
  MSG_REQUEST_ERROR,
  MSG_SELECT_WIDGETS_ERROR_IN_DEVICE
} from "../../lib/Const";
import {Button, Form, notification, Tooltip} from 'antd';
import { drawDateAndTimeIncSec } from "../../lib/Utils";
import {
  PlusOutlined, RedoOutlined,DeleteOutlined, AppstoreAddOutlined
} from '@ant-design/icons';
import { FilterButton } from '../../lib/FilterButton';
import { FilterPanelExt } from "../../lib/FilterPanelExt";
import DataTable, { ColumnTypes } from "../../lib/DataTable";
import EditForm from "../../lib/EditForm";
import DeviceForm from './DeviceForm';
import DeviceRegisterForm from './DeviceRegisterForm';
import { ShowModal } from "../../lib/EditForm";
import moment from 'moment';
import { PanelButton } from '../../lib/PunelButton';
import requestToAPI from "../../lib/Request";
import {WidgetsListForm} from "../WidgetsListForm";
import {useHistory} from "react-router-dom";


const ENTITY_TITLE = "Приборы";
const MODE_HELP_ID = "/help/typedevice";
const CONTOUR = CONTOUR_ADMIN;
const MODULE = MODULE_DEVICES;
const MNU_SUBSYSTEM = CONTOUR.name;
const MNU_MENU = [MODULE_DEVICES.name, MODULE.name];
const MNU_SUMMENU = MODULE_DEVICES.name;
const AUTO_REFRESH = true;

const URI_FOR_GET_LIST = QUERY_GET_LIST_DEVICE;
const URI_FOR_GET_ONE = QUERY_GET_DEVICE;
const URI_FOR_GET_DEFAULT = undefined; // когда необходимо вызвать метод с сервера для заполнения данных по умолчанию
const URI_FOR_DELETE = QUERY_DETACH_DEVICE;


const URI_FOR_UPDATE = MUT_UPD_DEVICE;

// колонки в таблице

const transformerRecord = (r) => {
  r["model.name"] = r.model.name;
  r["model.note"] = r.model.note;
  r["model.docs"] = r.model.docs;
  r["notes.note"] = r.notes[0].note;
  r["devUser.note.id"]=r.notes[0].id;

  delete r.model;
  delete r.notes;
}


const transformerData = (data) => {
  data.forEach(r =>
    transformerRecord(r)
  );
  return data;
}
// Уникальный идентификатор формы редактировавания
const EDIT_FORM_ID = "typedevices-frm";
// Форма для редактирования
const buildForm = (form) => {
  return <DeviceForm form={form} initialValues={{}} />
}
// размер формы, -1 - по умолчанию, FORM_MAX_WIDTH - максимальная ширина
const FORM_WIDTH = 800;

// Создание компонент для фильтров
// key это уникальное имя фильтра, попадает в REST API
const buildFilters = () => {
  return <React.Fragment>
    {/*
      <Primary>
          <span>Фильтры</span>
      </Primary>
      <Select key="statusDisplay" style={{ width: 160}} allowClear={true}  placeholder="Статус">
              {statusTypeList.map(value => <Option key={value.id} value={value.id}>{value.title}</Option>)}
          </Select>
      <DateInputRange key="dateRange" allowClear={false} />
      <DataLookup.ProgUser key="servicesUsersName" style={{ width: 240 }} allowClear={true} placeholder="Логин"/>
      <DataLookup.ProgUser key="servicesUserschannelAddress" style={{ width: 240 }} allowClear={true}  placeholder="E-mail" />
      <DataLookup.ProgUser key="servicesUsersphone" style={{ width: 240 }} allowClear={true}  placeholder="Телефон" />
      */}
  </React.Fragment>
}
// начальное значение фильтров
// если значение фильра не объект, а простое значение,
// то значение имени свойства компонента принимается как defaultValue компонента
const initFilters = {
}

// дополнительные команды
// если меню нет, то и кнопки нет

/*
const buildMenuCommand = (config, handleMenuClick) => {
  return <Menu onClick={handleMenuClick}>
      {buildPrintMenu(MODULE.name, config)}
  </Menu>
};

// обрабочик меню
const buildMenuHandler = (config) => {
  return (ev) => {
      console.log('click', ev);
  }
}
*/

// меню для записи
/*
const recordMenu = (config, record) => (
   <React.Fragment>
       {buildEntityPrintMenu(ENTITY, record, config)}
       <Menu.Divider />
       <Menu.Item key="manageAccessRole" icon={<UsergroupAddOutlined />} onClick={(ev) => manageAccessRole(ev, config, record)}>Роли</Menu.Item>
       <Menu.Item key="setPasswordProgUser" onClick={(ev) => setPasswordProgUser(ev, config, record)}>Установить пароль</Menu.Item>
   </React.Fragment>
)
*/

//===============================================================================
// Основной функциональный компонент
//===============================================================================

const Device = (props) => {
  let [formVisible, setFormVisible] = React.useState(false);
  const [topLayer, setTopLayer] = React.useState([]);
  const [widgets, setWidgets] =  React.useState([]);
  let [editorContext] = React.useState({
    uriForGetOne: URI_FOR_GET_ONE,
    uriForGetDefault: URI_FOR_GET_DEFAULT,
    uriForUpdate: URI_FOR_UPDATE
  });
  const history = useHistory();

  const [tableInterface] = React.useState(Object.assign({}, DEFAULT_TABLE_CONTEXT));
  const [form] = Form.useForm();
  const [, forceUpdate] = React.useReducer(x => x + 1, 0);
  const [updateRecords, setUpdateRecords] = React.useState([]);

  useEffect(() => {
    getDashboard()
  }, [])
  /*
    const menuCommand = buildMenuCommand({ form: form, forceUpdateModule: forceUpdate }, buildMenuHandler({
        topLayer,
        setTopLayer,
        form,
        tableInterface,
        destroyDialog: (dlgId) => {
            // нужно через timeout так как после вызова destroyDialog следуют обращения к state
            setTimeout(() => { setTopLayer([...topLayer.filter(c => c.props.id != dlgId)]) }, 100)
        }
    }));
  */

  tableInterface.addWidget = (record) => {
      getWidgetsList(record)
    }

  async function addWidget(data, lastPos) {
    for (let i = 0; i < data.length; i++) {
      await requestToAPI.post(MUT_INS_DASHBOARD_WIDGET, {
        pos: lastPos + i + 1,
        filters: [],
        beg: '',
        end: '',
        typeId: data[i].widget.id,
        unitId: data[i].unitId
      }).catch((error) => {
        notification.error({
          message: MSG_REQUEST_ERROR,
          description: error.message
        })
      })

    }
    history.push('/main')

  }
  function getLastPos() {
    let max = 0
    for (let i = 0; i < widgets.length; i++) {
      if (max < widgets[i].setting.pos){
        max = widgets[i].setting.pos
      }
    }
    return max
  }

  const getDashboard = () => {
    requestToAPI.post(QUERY_GET_DASHBOARD_WIDGETS, {id: requestToAPI.user.id})
        .then(response => {
          setWidgets(response.data.records.sort((a, b) => {
            return a.setting.pos - b.setting.pos
          }))
        })
        .catch((error) => {
          notification.error({
            message: MSG_REQUEST_ERROR,
            description: error.message
          })
        })
  }

  const getWidgetsList = (curDevice) => {
    const dialog = ShowModal({
      editorContext: {
        uriForInsert: (dlgId) => {
          return new Promise(async function (resolve, reject) {
            let data = form.getFieldValue().rows
            let lastPos = getLastPos()
            if (data) {
              await addWidget(data, lastPos)
              await getDashboard()
              resolve('done');
              reject('error')
            }else {
              notification.info({
                message: MSG_SELECT_WIDGETS_ERROR_IN_DEVICE
              })
              form.resetFields();
              setTopLayer([...topLayer.filter(c => c.props.id != dlgId)]);
            }
          })
        },
        uriForGetOne: QUERY_GET_DEVICE
      },
      form: form,
      dialogId:"modal-devices123",
      title: "Выберите виджеты",
      saveButtonTitle: "Выбрать",
      content: <WidgetsListForm widgets={widgets} topLayer={topLayer}
                                setTopLayer={setTopLayer} curDevice={curDevice}/>,
      idName: "id",
      width: "720px",
      destroyDialog: (dlgId) => {
        setFormVisible(false)
        form.resetFields();
        setTopLayer([...topLayer.filter(c => c.props.id != dlgId)]);
      },
    });
    // вставляем Modal в top layer
    setTopLayer([dialog])
  }
  const openWidgetSelect = (e, record) => {
    e.stopPropagation();
    getWidgetsList(record)
  }

  const COLUMNS = [
    {
      title: 'Серийный номер',
      dataIndex: 'number',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Описание владельца',
      dataIndex: 'notes.note',
      sorter: true,
      ellipsis: true,
      responsive: responsiveMobileColumn(),
    },
    {
      title: 'Тип прибора',
      dataIndex: 'model.name',
      sorter: true,
      ellipsis: true,
      responsive: responsiveMobileColumn(),
    },
    {
      title: 'Произведен',
      dataIndex: 'manufacturedInSeconds',
      dataType: ColumnTypes.DATETIME,
      sorter: true,
      ellipsis: true,
      defaultSortOrder: 'ascend',
      render: drawDateAndTimeIncSec,
      renderForFilter: (data) => moment(data * 1000).format("DD.MM.YYYY HH:mm")
    },
    {
      width: 70,
      fixed: 'right',
      render: (record) => <div className="container-button">
        <Tooltip placement="top" title="Добавить виджет">
          <Button className="button-add-widget-main" onClick={(e) => openWidgetSelect(e, record)}>
            <AppstoreAddOutlined />
          </Button>
        </Tooltip>

      </div>
    }
  ]

  const changeRegForm = () => {
    const config = {
      form: form,
      dialogId: "modal-regdevice",
      editorContext: {
        uriForInsert: MUT_INS_DEVICE,
      },
      destroyDialog: (dlgId) => {
        form.resetFields();
        setTopLayer([...topLayer.filter(c => c.props.id != dlgId)]);
      },
    }
    // формируем диалог
    const dialog = ShowModal({
      ...config,
      title: "Регистрация прибора",
      content: <DeviceRegisterForm />,
      idName: "id",
      width: "520px",
      afterSave: (response) => {
        if (!props.editorContext) {
          afterAdd(response)
        }
      }
    });
    // вставляем Modal в top layer
    setTopLayer([dialog])
  }

  const setFilters = React.useCallback((config) => {
    tableInterface.requestParams.filters = config;
    tableInterface.refreshData();
  }, [tableInterface])


  const callForm = React.useCallback((id) => {
    editorContext.id = id;
    setFormVisible(true)
  }, [editorContext])
  // тут формируются кнопки
  const buttons = [
    <PanelButton title="Зарегистрировать прибор" key="add" onClick={changeRegForm} icon={<PlusOutlined />} />,
    <PanelButton title="Удалить прибор" key="del" onClick={() => tableInterface.deleteData()} disabled={tableInterface.isLoading() || tableInterface.getSelectedRows().length == 0} icon={<DeleteOutlined />} />,
    <PanelButton title="Обновить" key="refresh" onClick={() => tableInterface.refreshData()} icon={<RedoOutlined />} />
  ];
  /*
    if (menuCommand) {
         buttons.push(<Dropdown.Button key="more"
             className="more-dropbutton"
             trigger="click"
             overlay={menuCommand} icon={<MoreOutlined />} />);
    }
  */
  if (isMobile()) {
    const filters = buildFilters();
    buttons.push(<FilterButton key="filter" filters={filters}
      onChange={(fc) => setFilters(fc)}
      initValues={initFilters} />);
  }

  const afterEdit = React.useCallback((values) => {
    values.data.record = { ...values.data.record.unit }
    const flatRecord = values.data.record;
    transformerRecord(flatRecord);
    tableInterface.updateRecord(flatRecord);
    setUpdateRecords([...updateRecords, flatRecord])
  }, [tableInterface, updateRecords])
  const afterAdd = React.useCallback((values) => {
    values.data.record = { ...values.data.record.unit }
    const flatRecord = values.data.record;
    transformerRecord(flatRecord);
    tableInterface.insFirstRecord(flatRecord);
    setUpdateRecords([...updateRecords, flatRecord])
  }, [tableInterface, updateRecords])

  return (
    <App subsystem={MNU_SUBSYSTEM} menu={MNU_MENU} submenu={MNU_SUMMENU}
      breadcrumb={[{ label: ENTITY_TITLE }]}
      afterLogin={forceUpdate}
      buttons={buttons}
      helpId={MODE_HELP_ID}>

      <ModuleHeader
        title={ENTITY_TITLE}
        searchValue={tableInterface.requestParams ? tableInterface.requestParams.search : undefined}
        onSearch={value => {
          tableInterface.requestParams.search = value;
          tableInterface.refreshData();
        }}
        buttons={buttons}
      />
      <FilterPanelExt onChange={(fc) => setFilters(fc)} initValues={initFilters}>
        {buildFilters()}
      </FilterPanelExt>
      <DataTable className="mod-main-table"
        uri={{
          forSelect: URI_FOR_GET_LIST,
          forDelete: URI_FOR_DELETE
        }}
        columns={COLUMNS}
        exportNameFile='devices'
        autoRefresh={AUTO_REFRESH}
        editCallBack={(record) => callForm(record.id)}
        interface={tableInterface}
        onSelectedChange={() => forceUpdate()}
        onAfterRefresh={() => setUpdateRecords([])}
        updateRecords={updateRecords}
        transformerData={transformerData}
        quickFilterResetButtonVisible={false}
        addWidget={true}
      /*
      recordMenu={(record) => recordMenu({
           topLayer,
           setTopLayer,
           form,
           tableInterface,
           idName: ENTITY.charAt(0).toLowerCase() + ENTITY.slice(1) + "Id",
           destroyDialog: (dlgId) => {
               setTopLayer([...topLayer.filter(c => c.props.id != dlgId)])
           }
      }, record)}
      */
      />
      <EditForm
        id={EDIT_FORM_ID}
        copyButtonFlag={true}
        visible={formVisible}
        form={form}
        width={FORM_WIDTH}
        editorContext={editorContext}
        afterSave={(response) => {
          setFormVisible(false);
          if (response) {
            if (!editorContext.id) {
              afterAdd(response)
            } else {
              afterEdit(response)
            }
          }
        }}
        transformerData={transformerRecord}
        afterCopy={afterAdd}
        afterCancel={() => {
          setFormVisible(false);
        }}
        idName="id">
        {buildForm(form)}
      </EditForm>
      {topLayer.map(item => item)}
    </App>
  )
}
export default withRouter(Device);
