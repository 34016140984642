import React from 'react';
import {Input} from "../../hooks/Input";

const DosingCO2 = ({unitId, workTm, pauseTm}) => {
    return (
        <div className="ph-dialog" draggable="false">
            <div className="row-ph-dialog ph-level-row">
                <p>Подача (сек.)</p>
                <Input value={workTm} min={1} max={3599} mode={1}
                          deviceId={unitId} ckey={'workTm'} time={true}/>

            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>Пауза (мин.)</p>
                <Input value={pauseTm} min={60} max={86340} mode={60}
                          deviceId={unitId} ckey={'pauseTm'} time={true} format={'HH:MM'}/>
            </div>
            <div className="row-ph-dialog ph-level-row">

            </div>

        </div>
    );
};

export default DosingCO2;