import React from 'react';
import { Button, Form, Input } from 'antd';
import { resq$ } from 'resq'
import requestToAPI from "./Request";
import { ArrowLeftOutlined } from '@ant-design/icons';
import {MUT_ASK_DEV_USER_PASSWD_RESET} from "./Query";

let _activeValidator;

export const ForgetPassword = (props) => {
    const [form] = Form.useForm();
    const firstInputRef = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [controlCase, setControlCase] = React.useState(false);

    React.useEffect(() => {
        const timerId = setTimeout(() => {
            if (firstInputRef.current)
                firstInputRef.current.focus({
                    cursor: 'end',
                })
        }, 100);
        return () => {
            clearTimeout(timerId)
        }
    })

    const handleKeyDown = (ev) => {
        if (ev.which == 13) {
            let root = document.getElementById("root");
            const okBtn = resq$('Button', root).byProps({ type: "primary" });
            okBtn.props.onClick(okBtn);
        }
    }

    _activeValidator = form;

    const recovery = () => {
        _activeValidator.setFieldsValue({ error: undefined });
        return _activeValidator.validateFields()
            .then((value) => {
                setLoading(true);
                return requestToAPI.post(MUT_ASK_DEV_USER_PASSWD_RESET, {email: value.email});
            })
            .then(response => {
                setLoading(false);
                if (props.cb) {
                    props.cb();
                }
                setControlCase(true);
            })
            .catch((error) => {
                setLoading(false);
                if (error.message) {
                    _activeValidator.setFieldsValue({ error: error.message });
                }
                throw error;
            });
    }

    function goBack(e){
        e.preventDefault();
        props.setControlCase("");
    }

    switch (controlCase) {
        case true:
            return  <div className='formForgetPassword successfulForgetPassword'>
                        <h1 className="h1ForgetPassword">ВОССТАНОВЛЕНИЕ ПАРОЛЯ</h1>
                        <div className='contentForgetPassword'>
                            <span className="email-pass-recovery">Если Вы зарегистрированы, то на Вашу почту было отправлено письмо для восстановления пароля</span>
                            <Button type="primary" onClick={(e) => goBack(e)} >На главную</Button>
                        </div>
                    </div>;
        default:
            return <div onKeyDown={handleKeyDown} className='formForgetPassword'>
                <h1 className="h1ForgetPassword">ВОССТАНОВЛЕНИЕ ПАРОЛЯ</h1>
                <div className='contentForgetPassword'>
                <Form
                    layout={"vertical"}
                    form={form}
                    name="formForgetPassword"
                    initialValues={{}}>
                    
                    <Button
                        icon={<ArrowLeftOutlined />}
                        className="back-button"
                        onClick={(e) => goBack(e)}
                    />
                    <Form.Item
                        name="email"
                        label="Введите адрес электронной почты для восстановления пароля"
                        rules={[
                            {
                                required: true,
                                message: "E-mail не может быть пустым",
                            }
                        ]}>
                        <Input ref={firstInputRef} placeholder="E-mail*"/>
                    </Form.Item>

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.error !== currentValues.error}>
                        {
                            ({ getFieldValue }) =>
                                getFieldValue('error') ? (
                                    <div className="ant-form-item ant-form-item-explain ant-form-item-explain-error">{getFieldValue('error')}</div>
                                ) : null
                        }
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={recovery} loading={loading}>Восстановить пароль</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>;
    }
}