import React from 'react';
import { CloseOutlined } from "@ant-design/icons";
import { Col, Row, Spin } from "antd";
import { ManualControlPHButton } from "./ManualControlPHButton";
import { ShowDialog } from "../dialogs/ShowDialog";
import requestToAPI from "../../../lib/Request";
import { subscribe } from "../../../lib/RequestWS";
import { ParameterPHButton } from './ParameterPHButton';
import { RegulatorStateButton } from './RegulatorStateButton';
import { RegulatorRangeButton } from './RegulatorRangeButton';
import { RegulatorProtectButton } from './RegulatorProtectButton';
import { ParameterPHDialog } from './ParameterPHDialog';
import { RegulatorStateDialog } from './RegulatorStateDialog';
import { ManualControlPHDialog } from './ManualControlPHDialog';
import { RegulatorRangeDialog } from './RegulatorRangeDialog';

const selectedMode = {
    0: "Выключен",
    1: "Авария",
    2: "Включен",
    3: "Слив бака",
    4: "Слив каналов",
    5: "Слив системы"
}

const MAIN_PAGE = 1;
const PH_PAGE = 2;
const MANUAL_CONTROL_PAGE = 3;
const REGULATOR_STATE_PAGE = 4;
const REGULATOR_RANGE_PAGE = 5;

const PonicsDefaultMonitor = {}

export const WidgetPHESP = ({ widgetId, deviceName, deviceId, closeWidget }) => {
    const [page, setPage] = React.useState(MAIN_PAGE);

    const [monitor, setMonitor] = React.useState(PonicsDefaultMonitor)
    const [crashes, setCrashes] = React.useState([])
    const [contextParams] = React.useState({})
    const [trueCrashes, setTrueCrashes] = React.useState([])
    const [isFetching, setIsFetching] = React.useState(true)
    const accidentCodes = ['E501', 'E502', 'E503', 'E504'];
    const [dataMonitor, setDataMonitor] = React.useState([]);

    React.useEffect(() => {
        contextParams.mountFlag = true;
        if (requestToAPI.authToken) {
            if (!contextParams.mountFlag) return;
            subscribe(
                `subscription { 
                    deviceUnitByPk(id: ${deviceId}) { 
                        monitor { 
                            key
                            value 
                        }
                        model {
                          metadata
                        }
                    } 
                }`,
                (payload) => {
                    if (!contextParams.mountFlag) return;
                    let new_data = payload.data.deviceUnitByPk
                    if (new_data) {
                        var data = new_data.monitor.reduce(function (map, obj) {
                            map[obj.key] = obj.value;
                            return map;
                        }, {});
                        setMonitor(data)

                        let metadata = new_data.model.metadata && new_data.model.metadata.monitor ? new_data.model.metadata.monitor : {};
                        setDataMonitor(metadata);

                        let crash = []
                        let trueCrash = []
                        for (let key in data) {
                            let obj = {}
                            if (key.substr(0, 5) === 'alarm') {
                                obj["state"] = data[key]
                                obj["name"] = key.substr(5, 4)
                                obj["meta"] = metadata[key]

                                crash.push(obj)
                                if (obj.state) {
                                    trueCrash.push(obj)
                                }
                            }
                        }
                        setTrueCrashes(trueCrash)
                        setCrashes(crash)
                        setIsFetching(false)
                    } else {
                        setPage(MAIN_PAGE);
                    }
                }
            )
        }
        return () => {
            contextParams.mountFlag = false
        }
    }, [])

    return (
        <div className="main-widget">
            <div draggable="true" className={crashes.some(x => x.state) ? "header-widget background-red" : "header-widget"}>
                <div />
                <div className={crashes.some(x => x.state) ? "main-widget-title background-red" : "main-widget-title"}>
                    {`${deviceName}`}
                </div>
                <div className="area-widget-close" onClick={() => {
                    closeWidget(widgetId)
                }}>
                    <CloseOutlined
                        className={crashes.some(x => x.state) ? "widget-close-white widget-close" : "widget-close-main"}
                    />
                </div>
            </div>
            {
                (() => {
                    if (isFetching) {
                        return <Spin className="fetching-stat" />
                    }
                    switch (page) {
                        case PH_PAGE:
                            return <ShowDialog
                                title={"Настройка регулятора pH"}
                                widgetName={deviceName}
                                isAlarm={crashes.some(x => x.state)}
                                number={widgetId}
                                value={"pH"}
                                changePage={() => setPage(MAIN_PAGE)}
                                component={
                                    <ParameterPHDialog
                                        unitId={deviceId}
                                        tankEC={monitor.tankEC}
                                        tankECMax={10}
                                        tankECMin={0}
                                        phCycleTm={monitor.phCycleTm}
                                        phCycleTmMin={0}
                                    />
                                }
                            />
                        case MANUAL_CONTROL_PAGE:
                            return <ShowDialog
                                title={"Ручное управление pH"}
                                isAlarm={crashes.some(x => x.state)}
                                widgetName={deviceName}
                                number={widgetId}
                                value={"manualControl"}
                                changePage={() => setPage(MAIN_PAGE)}
                                component={<ManualControlPHDialog
                                    unitId={deviceId}
                                    tankPh={monitor.tankPh}
                                    taskPhDown={monitor.taskPhDown}
                                    taskPhUp={monitor.taskPhUp}
                                    taskPhUpMax={50}
                                    taskPhDownMax={50}
                                />} />
                        case REGULATOR_STATE_PAGE:
                            return <ShowDialog
                                title={"Режим E-MODE"}
                                isAlarm={crashes.some(x => x.state)}
                                widgetName={deviceName}
                                number={widgetId}
                                value={"mode"}
                                changePage={() => setPage(MAIN_PAGE)}
                                component={<RegulatorStateDialog
                                    isAlarm={crashes.some(x => x.state)}
                                    deviceId={widgetId}
                                    widgetName={deviceName}
                                    crashes={crashes}
                                    unitId={deviceId}
                                    phIsOn={monitor.phIsOn}
                                />} />
                        case REGULATOR_RANGE_PAGE:
                            return <ShowDialog
                                title={"pH раствора"}
                                isAlarm={crashes.some(x => x.state)}
                                widgetName={deviceName}
                                number={widgetId}
                                value={"mode"}
                                changePage={() => setPage(MAIN_PAGE)}
                                component={<RegulatorRangeDialog
                                    unitId={deviceId}
                                    phLowerMin={5}
                                    phUpperMax={8}
                                    phUpper={monitor.phUpper}
                                    phLower={monitor.phLower}
                                />} />
                        default:
                            return <div>
                                <Row draggable="false" className='border-down'>
                                    <Col span={15} className="left-side-widget">
                                        <ParameterPHButton
                                            tankPh={monitor.tankPh}
                                            handleClick={() => setPage(PH_PAGE)}
                                            trueCrashes={trueCrashes}
                                            phTitle={dataMonitor.phTitle}
                                        />
                                    </Col>
                                    <Col span={9} className="right-side-widget">
                                        <ManualControlPHButton
                                            outPhDown={monitor.outPhDown}
                                            outPhUp={monitor.outPhUp}
                                            handleClick={() => setPage(MANUAL_CONTROL_PAGE)}
                                        />
                                    </Col>
                                </Row>
                                <Row draggable="false" style={{ height: 112.5 }}>
                                    <Col span={6} className="right-border-2px">
                                        <RegulatorStateButton
                                            handleClick={() => setPage(REGULATOR_STATE_PAGE)}
                                            phIsOn={monitor.phIsOn}
                                            trueCrashes={trueCrashes}
                                            accidentCodes={accidentCodes}
                                            phIsOnTitle={dataMonitor.phIsOnTitle}
                                        />
                                    </Col>
                                    <Col span={6} className="right-border-2px">
                                        <RegulatorRangeButton
                                            handleClick={() => setPage(REGULATOR_RANGE_PAGE)}
                                            regulatorRangeTitle={dataMonitor.regulatorRangeTitle}
                                        />
                                    </Col>
                                    <Col span={6} className="right-border-2px">
                                        <RegulatorProtectButton
                                            unitId={deviceId}
                                            phIsProtected={monitor.phIsProtected}
                                            phIsProtectedTitle={dataMonitor.phIsProtectedTitle}
                                        />
                                    </Col>
                                </Row>
                            </div>
                    }
                })()
            }
        </div >
    )
}