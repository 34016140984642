import React  from 'react';
import {
    Form,
    Input,
    Button,
} from 'antd';
import requestToAPI from "./Request";
import { notification } from 'antd';
import {MUT_REGISTER_DEV_USER} from "./Query";
import {setSessionStorage} from "./LoginForm";



export const RegistrationForm = (props) => {
    const [form] = Form.useForm();
    const [isRegister, setIsRegister] = React.useState(false)
    const [registerData, setRegisterData] = React.useState(null)
    const [isAuth, setIsAuth] = React.useState(false)
    const login = (source)=>{
        requestToAPI.loginComplete(source);
        setSessionStorage(requestToAPI.authToken, requestToAPI.user);
        if (props.cb) {
            props.cb();
        }
    }

    const register = () => {
        form.setFieldsValue({ error: undefined });
        return form.validateFields()
            .then((values) => {
                console.log(form.getFieldValue("email"))
                delete values.confirm;
               // delete values.captcha;
               // delete values.agreement;
                
                return requestToAPI.post(MUT_REGISTER_DEV_USER, values);
            })
            .catch((error) => {
                if(!error.errorFields) {
                    form.setFieldsValue({ error: error.message });
                }
                throw error;
            })
            .then(response => {
                console.log(form.getFieldValue("email"))
                setRegisterData(response.data.return)
                if(form.getFieldValue("email")==null) {
                    setIsAuth(true)
                } else {
                    setIsRegister(true)
                }
            })
            .then(response => {
                if (props.cb) {
                    props.cb();
                }
            });
    }

    return (<Form
                form={form}
                name="register"
                layout={"vertical"}
                style={{ padding: 30}}
                scrollToFirstError
            >
                {/* <p className="vhod">РЕГИСТРАЦИЯ</p>
            <hr id="red"/>
            <br /> */}
                {(!isAuth && !isRegister) ? <React.Fragment>
                            <Form.Item
                                name="login"
                                className="register-fields"
                                rules={[{ required: true, message: 'Заполните логин!', whitespace: true }]}
                            >
                                <Input placeholder="Логин*" />
                            </Form.Item>

                            <Form.Item
                                name="email"
                                className="register-fields"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Формат e-mail неподходящий!',
                                    },
                                ]}
                            >
                                <Input placeholder="E-mail" />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите пароль',
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password placeholder="Пароль*" />
                            </Form.Item>

                            <Form.Item
                                name="confirm"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите подтверждение пароля',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Пароли должны совпадать!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder="Повторите пароль*" />
                            </Form.Item>

                            {/*<Form.Item
                        name="captcha"
                        rules={[{ required: true, message: 'Введите символы с картинки!' }]}
                    >
                        <Input placeholder="Введите символы с изображения*" />
                    </Form.Item>

                    <Form.Item className="register-fields" name="captchaButton">
                        <Button>Get captcha</Button>
                    </Form.Item>

                    <Form.Item
                        name="agreement"
                        style={{"color": "#fff", "width": "100%"}}
                        valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) =>
                                    value ? Promise.resolve() : Promise.reject(new Error('Примите условия соглашения!')),
                            },
                        ]}
                    >
                        <Checkbox style={{"color": "#fff"}}>
                            Заполняя форму, Вы принимаете <a style={{"color": "#e31e24", "textDecoration": "underline"}} href="">пользовательское соглашение</a>
                        </Checkbox>
                    </Form.Item>*/}
                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) => prevValues.error !== currentValues.error}>
                                {
                                    ({ getFieldValue }) =>
                                        getFieldValue('error') ? (
                                            <div className="ant-form-item ant-form-item-explain ant-form-item-explain-error">{getFieldValue('error')}</div>
                                        ) : null
                                }
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" onClick={register} className="login-form-button" style={{ "width": "100%" }}>
                                    Зарегистрироваться
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <span style={{"color": "#808080"}}>Уже есть аккаунт? </span>
                                <a style={{"color": "#e31e24", "textDecoration": "underline"}} href={ "/" }>Войдите</a>
                            </Form.Item>
                    </React.Fragment>
                    :  <div>
                        <div className="text-complete-registration">
                            {isRegister ?
                                <React.Fragment>
                                    <p>Проверьте указанную почту!</p>
                                    <p>
                                        К вам на почту отправлено письмо с подтверждением почтового адреса.
                                        Это не мешает сейчас войти в систему.</p>
                                </React.Fragment>
                                : <React.Fragment>
                                    <p>Авторизируйтесь!</p>
                                    <p>Вы можете войти в систему.</p>
                                </React.Fragment>

                            }
                        </div>
                        <Button style={{"marginTop": 30}} type="primary" onClick={()=>login(registerData)}>Вход</Button>
                    </div>
                    }
    </Form>
    );

};