import React from "react";
import { Input } from "../../hooks/Input";
import { HookSwitch } from "../../hooks/HookSwitch";

export const LightingDialog = ({ unitId, lightIsOn, lightStart, lightDay, lightStartMin, lightDayMin, lightDayMax }) => {
    return <div>
        <div className="ph-dialog" draggable="false">
            <div className="row-ph-dialog ph-level-row">
                <p>Включать</p>
                <HookSwitch value={lightIsOn} unitId={unitId} name={'lightIsOn'}/>
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>Начало цикла</p>
                <Input
                    value={lightStart}
                    min={lightStartMin}
                    mode={1}
                    deviceId={unitId}
                    ckey={'lightStart'}
                    time={true}
                />
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>День (ч.)</p>
                <Input
                    value={lightDay}
                    min={lightDayMin}
                    max={lightDayMax}
                    mode={1}
                    deviceId={unitId}
                    ckey={'lightDay'}
                />
            </div>
        </div>
    </div>
}
