import requestToAPI from "../lib/Request";
import {MUT_UPD_VALUE_WIDGET} from "../lib/Query";
import {notification} from "antd";
import {MSG_REQUEST_ERROR} from "../lib/Const";

export const debounce = (fn, ms) => {
    let timeout
    return function () {
        const fnCall = () => {
            fn.apply(this, arguments)
        }
        clearTimeout(timeout)
        timeout = setTimeout(fnCall, ms)
    }
}

export function updateInputValue(deviceId, key, value) {
    return requestToAPI.post(MUT_UPD_VALUE_WIDGET, {
        unitId: deviceId,
        key: key,
        value: value
    }).catch((error) => {
        notification.error({
            message: MSG_REQUEST_ERROR,
            description: error.message
        })
    })
}
