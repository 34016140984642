import React from 'react';
import { Tooltip } from "antd";

export const ParameterPHButton = ({ handleClick, tankPh, trueCrashes, phTitle }) => {
    let phValue = tankPh || tankPh == 0
        ? (Math.round((tankPh) * 100) / 100).toFixed(1)
        : "-"

    return <div
        onClick={handleClick}
        className="record-box"
        style={{
            alignItems: 'flex-end',
            padding: '49px 0px',
            justifyContent: 'center'
        }}
    >
        <span
            style={{
                fontSize: 32,
                lineHeight: '22px',
                marginRight: '13px',
            }}>
            pH
        </span>
        <Tooltip
            placement="topLeft"
            title={
                trueCrashes.length > 0
                    ? trueCrashes.map(crash => crash.meta.title).join('; ')
                    : phValue !== '-' ? phValue + ' - ' + phTitle?.title : phTitle?.nonePhTitle
            }
        >
            <p
                className="wrap-text"
                style={{
                    fontSize: 62,
                    fontWeight: 'bold',
                    margin: 0,
                    color: trueCrashes.length > 0 ? '#FF0000' : '#00FA9E',
                    lineHeight: '47px',
                    minWidth: '143px'
                }}
            >
                {
                    phValue
                }
            </p>
        </Tooltip>
    </div>
}