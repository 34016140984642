import React, {useState} from "react";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {updateInputValue} from "../Debounce";
import {REPLY_TIMEOUT} from "../widget/Const"

export const Input = ({
                          value, min = Number.MIN_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER,
                          mode, deviceId, ckey, round= 1, afterUpdate,
                          time = false, format = 'ММ:СС', ...other
                      }) => {

    const [curValue, setCurValue] = useState(
    other?.floor
        ? Math.floor((value) * round) / round
        : Math.round((value) * round) / round
    )

    const [isDisabled, setIsDisabled] = useState(false)
    const [isDisabledL, setIsDisabledL] = useState(false)
    const [isDisabledR, setIsDisabledR] = useState(false)
    const [timeoutId, setTimeoutId] = useState(null)

    React.useEffect(() => {
        stop()
        setIsDisabled(false)
        if (value !== max) {
            setIsDisabledR(false)
        } else {
            setIsDisabledR(true)
        }
        if (value !== min) {
            setIsDisabledL(false)
        } else {
            setIsDisabledL(true)
        }
        if (other?.floor) {
            setCurValue(Math.floor((value) * round) / round)
        } else {
            setCurValue(Math.round((value) * round) / round)
        }

        if (other?.setValue) {
            other.setValue(Math.round((value) * round) / round)
        }

    }, [value, other?.update])

    const intervalRef = React.useRef(null);

    React.useEffect(() => {
        if (curValue === max) {
            setIsDisabledR(true)
        } else if (curValue === min) {
            setIsDisabledL(true)
        }
        return () => stopCounter(true)
    }, []);

    function start() {
        if (timeoutId) {
            return;
        }

        setTimeoutId(setTimeout(() => {
            setIsDisabled(false)
            if (other?.floor) {
                setCurValue(Math.floor((value) * round) / round)
            } else {
                setCurValue(Math.round((value) * round) / round)
            }
            // режим с 2-мя импутами
            if (other.setValue) {
                other.setValue(Math.round((value) * round) / round)
            }
            if (value !== max) {
                setIsDisabledR(false)
            } else {
                setIsDisabledR(true)
            }
            if (value !== min) {
                setIsDisabledL(false)
            } else {
                setIsDisabledL(true)
            }
            // режим с 2-мя импутами
            if (other.setDisabled) {
                other.setDisabled(false)
            }

            setTimeoutId(null)
        }, REPLY_TIMEOUT))

    }

    function stop() {
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(null)
        }
    }

    const startCounter = (fn) => {
        setCurValue((prevCounter) => fn(prevCounter))

        if (other?.setValue) {
            other.setValue((prevCounter) => fn(prevCounter))
        }
        if (intervalRef.current) return;
        intervalRef.current = setInterval(() => {
            setCurValue((prevCounter) => fn(prevCounter))
            if (other?.setValue) {
                other.setValue((prevCounter) => fn(prevCounter))
            }
        }, 100);
    };


    async function stopCounter(unMountComponent) {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            // режим с 2-мя импутами
            if (other?.setDisabled) {
                other.setDisabled(true)
            }
            if (format === 'time'){
                let offset = new Date().getTimezoneOffset() * 60
                await updateInputValue(deviceId, ckey, curValue + offset)
            }else {
                await updateInputValue(deviceId, ckey, curValue)
            }

            // режим с 2-мя импутами
            if (other?.skey) {
                if (curValue + 150 >= other.tvalue) {
                    await updateInputValue(deviceId, other.skey, other.svalue)
                }
            }

            if(afterUpdate) {
                afterUpdate(curValue);
            }

            if (curValue === max) {
                setIsDisabledR(true)
                setIsDisabled(true)
            } else if (curValue === min) {
                setIsDisabledL(true)
                setIsDisabled(true)
            } else {
                setIsDisabled(true)
            }
            start()

        }
    }

    function increaseValue(prev) {
        let new_value;
        setIsDisabledL(false)
        new_value = Math.min(max, (prev + mode))
        if (other?.secondValue) {
            if (other.secondValue === prev + mode) {
                new_value = Math.min(max, (prev  + 2 * mode))
            }
        }

        if (other?.floor) {
            return Math.floor((new_value) * round) / round
        } else {
            return Math.round((new_value) * round) / round
        }
    }

    function decreaseValue(prev) {
        let new_value;
        setIsDisabledR(false)
        new_value = Math.max(min, (prev - mode))
        if (other?.secondValue) {
            if (other.secondValue === prev - mode) {
                new_value = Math.max(min, (prev - 2 * mode))
            }
        }

        if (other?.floor) {
            return Math.floor((new_value) * round) / round
        } else {
            return Math.round((new_value) * round) / round
        }
    }

    function createClockFace(value) {
        let rightResult;
        let leftResult;
        if (format === "ММ:СС") {
            rightResult = Math.trunc(value / 60)
            leftResult = value - rightResult * 60
        } else if (format === "HH:MM") {
            rightResult = Math.trunc(value / 60 / 60)
            leftResult = Math.trunc((value - rightResult * 60 * 60) / 60)
        } else {
            rightResult = new Date(value * 1000).getHours()
            leftResult = new Date(value * 1000).getMinutes()
        }
        if (rightResult < 10) {
            rightResult = '0' + rightResult
        }
        if (leftResult < 10) {
            leftResult = '0' + leftResult
        }
        return rightResult + ':' + leftResult

    }


    return (
        <div className="counter-plus-minus">
            <button onMouseDown={() => startCounter(decreaseValue)}
                    disabled={isDisabledL || isDisabled || other?.disabled}
                    onMouseUp={stopCounter}
                    onMouseLeave={stopCounter}
                    className={other?.disabled || isDisabledL || isDisabled ? "invisible-button disabled-button" : "invisible-button" }>
                <MinusOutlined/>
            </button>
            <div className={other?.disabled || isDisabled ? "clock-face clock-face-disabled" : "clock-face"
                }>
                <p>{
                    curValue !== undefined || '' || null
                    ? time ? createClockFace(curValue) : curValue
                    : "?"
                }</p>
            </div>
            <button onMouseDown={() => startCounter(increaseValue)}
                    disabled={isDisabledR || isDisabled || other?.disabled}
                    onMouseUp={stopCounter}
                    onMouseLeave={stopCounter}
                    className={isDisabledR || isDisabled || other?.disabled ? "invisible-button disabled-button" : "invisible-button"}>
                <PlusOutlined/>
            </button>
        </div>
    )
}
