import {Col, Row, Tooltip} from "antd";

export const CrashView = ({crashes}) => {
    return (
        <Row className="crash-view-dialog" gutter={[21, 5]} draggable="false">
            {
                crashes
                    ?
                    crashes.map((crash, index) => {
                        return <Tooltip key={index+1000} className="tooltip-crash" placement="top" title={crash.meta !== undefined || null
                            ? crash.meta.title
                            : null}>
                            <Col key={index} className={crash.state ? "crash-active" : null}>{crash.name || "-"}</Col>
                        </Tooltip>
                    })
                    : null
            }
        </Row>
    )
}