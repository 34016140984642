import React from "react";
import {HookSwitch} from "../../../hooks/HookSwitch";


export const SettingEC = ({unitId, ecSensUsedA, ecSensUsedB, ecSensUsedC}) => {
    return (
        <div className="ph-dialog" draggable="false">
            <div className="row-ph-dialog ph-level-row">
                <p>Датчик уровня A</p>
                <HookSwitch name={'ecSensUsedA'} value={ecSensUsedA} unitId={unitId}/>
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>Датчик уровня B</p>
                <HookSwitch name={'ecSensUsedB'} value={ecSensUsedB} unitId={unitId}/>
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>Датчик уровня C</p>
                <HookSwitch name={'ecSensUsedC'} value={ecSensUsedC} unitId={unitId}/>
            </div>
            <div className="row-ph-dialog ph-level-row">

            </div>
        </div>
    )
}
