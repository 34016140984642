import React from "react";
import arrow from "../../../../../resources/images/Arrow-right.svg";
import {ShowDialog} from "../ShowDialog";
import {SettingPH} from "./SettingPH";
import {HookDosing} from "../../../hooks/HookDosing";


export const ManualControlPH = ({
                                    unitId, tankPh, phAgentUsedDown, phAgentUsedUp,
                                    taskPhDownControl, taskPhUpControl,
                                    phSensUsedUp, phSensUsedDown,
                                    deviceId, widgetName, isAlarm
                                }) => {

    const [dialogs, changeDialog] = React.useState({
        setting: false,
    });

    const changePage = (value, option) => {

        switch (value) {
            case "setting":
                changeDialog({...dialogs, setting: option})
                break
            default:
                changeDialog({
                    mode: false
                })
        }

    }


    return (
        <div className="ph-dialog" draggable="false">
            <div className="row-ph-dialog ph-level-row">
                <div className="ph-value">
                    <p>pH</p>
                    <p>{(Math.round((tankPh) * 100) / 100).toFixed(1)}</p>
                </div>
                <div className="level-ph setting-arrow">
                    <p>Настройка</p>
                    <button className="button-arrow">
                        <div className="logo-widget">
                            <img onClick={() => changePage('setting', true)} src={arrow} alt="arrow"/>
                        </div>
                    </button>
                </div>
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>pH-</p>
                <HookDosing unitId={unitId} used={phAgentUsedDown} taskEC='taskPhDown'
                            valueTaskEcMin={1} valueTaskEcMax={taskPhDownControl.max} mode={1}/>
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>pH+</p>
                <HookDosing unitId={unitId} used={phAgentUsedUp} taskEC='taskPhUp'
                            valueTaskEcMin={1} valueTaskEcMax={taskPhUpControl.max} mode={1}/>
            </div>
            <div className="row-ph-dialog ph-level-row">

            </div>
            {dialogs.setting ?
                <ShowDialog
                    isAlarm={isAlarm}
                    title={"Настройка подачи реагентов"}
                    number={deviceId}
                    widgetName={widgetName}
                    value={"setting"}
                    changePage={changePage}
                    component={<SettingPH unitId={unitId}
                                          phSensUsedUp={phSensUsedUp}
                                          phSensUsedDown={phSensUsedDown}/>}/>
                : null}
        </div>
    )
}
