import React from 'react';
import logoFull from '../resources/images/logo-full.svg'
import {LoginContent} from "./lib/LoginForm";
import {ForgetPassword} from "./lib/ForgetPassword";
import {SetPasswordContent} from "./lib/SetPasswordForm";
import {ResetDevUserPasswd} from './lib/ResetDevUserPasswd';
import requestToAPI from "./lib/Request";
import { Redirect, useParams } from "react-router-dom";


function SignupContent(props) {
    const [controlCase, setControlCase] = React.useState(props.controlCase);
    const [userName, setUserName] = React.useState("");

    switch (controlCase) {
        case "tempPass":
            return <SetPasswordContent cb={props.cb} userName={userName} />;
        case "forgetPass":
            return <ForgetPassword setControlCase={setControlCase} />;
        case "setNewPass":
            return <ResetDevUserPasswd code={props.code} />
        default:
            return <LoginContent cb={props.cb} setControlCase={setControlCase} setUserName={setUserName} />;
    }
}

const Auth = (props) => {
    const params = useParams()
    const [, forceUpdate] = React.useReducer(x => x + 1, 0);

    // проверяем наличие сохраненного токен
    let saveToken = sessionStorage.getItem("authToken") ?? localStorage.getItem("authToken");


    return requestToAPI.authToken || saveToken ? (
        <Redirect from="/" to="/main" />
    ) : (
        <React.Fragment>
            <div className="header">
                <div className="logotype">
                    <img src={logoFull} alt="Logotype"/>
                </div>
                <hr className="gradient"/>
                <div className="app-version">
                    Версия {process.env.REACT_APP_VERSION}
                </div>
            </div>
            <div className="wrap-3">
                <div className="wrap-2">
                    <div className="wrap-1">
                        <SignupContent cb={forceUpdate} controlCase={props.controlCase} code={params.key}/>
                    </div>
                </div>
            </div>


        </React.Fragment>
    )
}
export default Auth;
