import React from "react";
import {CloseOutlined} from "@ant-design/icons";
import {HookSwitch} from "../hooks/HookSwitch";
import {Input} from "../hooks/Input";
import requestToAPI from "../../lib/Request";
import {subscribe} from "../../lib/RequestWS";
import {Spin} from "antd";
import {updateInputValue} from "../Debounce";

export const SensiroomH = ({widgetId, deviceName, deviceId, closeWidget}) => {
    const [monitor, setMonitor] = React.useState({})
    const [contextParams] = React.useState({})

    const [isFetching, setIsFetching] = React.useState(true)

    console.log("SensiroomH load",{widgetId, deviceName, deviceId, closeWidget});

    React.useEffect(() => {
        contextParams.mountFlag = true;
        if (requestToAPI.authToken) {
            if (!contextParams.mountFlag) return;
            subscribe(
                `subscription { 
                    deviceUnitByPk(id: ${deviceId}) { 
                        monitor { 
                            key
                            value 
                        }
                        model {
                          metadata
                        }
                    } 
                }`,
                (payload) => {
                    if (!contextParams.mountFlag) return;
                    let new_data = payload.data.deviceUnitByPk
                    if (new_data) {
                        var data = new_data.monitor.reduce(function (map, obj) {
                            map[obj.key] = obj.value;
                            return map;
                        }, {});
                        setMonitor(data)
                        setIsFetching(false)
                    } else {

                    }
                }
            )
        }
        return () => {
            contextParams.mountFlag = false
        }
    }, [])

    return (
        <div className="main-widget main-sensiroom">
            <div draggable="true" className="header-widget">
                <div/>
                <div className={"main-widget-title"}>
                    {`${deviceName}`}
                </div>
                <div className="area-widget-close" onClick={() => {
                    closeWidget(widgetId)
                }}>
                    <CloseOutlined
                        className={"widget-close-main"}
                    />
                </div>

            </div>
            {
                isFetching
                    ? <Spin className="fetching-stat"/>
                    : <div className="ph-dialog" draggable="false">
                        <div className="row-ph-dialog ph-level-row">
                            <div className="ph-value">
                                <p>RH</p>
                                <p>{`${monitor.humidity?monitor.humidity.toFixed(0):"?"}%`}</p>
                            </div>
                            <div className="level-ph setting-arrow">
                                <div className={monitor.manual ? "disable-text" : null}>
                                    <p>Полить</p>
                                </div>
                                <button disabled={monitor.outWater}
                                        style={{marginLeft: 12}}
                                        onClick={() => updateInputValue(deviceId, 'manual', true)}
                                        className={monitor.outWater ? "button-arrow triangle-button triangle-button-hover" : "button-arrow triangle-button"}>
                                    <div className={monitor.outWater ? "rectangle-icon disabled-button-tria" : "rectangle-icon"}>
                                        <svg width="18" height="21" viewBox="0 0 18 21" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.470459 0.15918L17.6237 10.5271L0.470459 20.895V0.15918Z"
                                                  fill={monitor.outWater ? "#333333" : "white"}/>
                                        </svg>
                                    </div>
                                </button>
                                <div className="drop-block">
                                    <svg width="27" height="33" viewBox="0 0 27 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.5 0C4.50563 7.5075 0 13.992 0 19.47C0 27.687 6.4125 33 13.5 33C20.5875 33 27 27.687 27 19.47C27 13.992 22.4944 7.5075 13.5 0ZM6.46312 19.8C7.0875 19.8 7.59375 20.229 7.71187 20.823C8.40375 24.486 11.5594 25.74 13.8544 25.5585C14.58 25.5255 15.1875 26.0865 15.1875 26.796C15.1875 27.456 14.6475 28.0005 13.9725 28.0335C10.3781 28.248 6.17625 26.235 5.21438 21.2355C5.07938 20.493 5.68687 19.8 6.46312 19.8Z"
                                              fill={monitor.outWater ? "#00FA9E" : "#838383"}/>
                                    </svg>
                                </div>

                            </div>
                        </div>
                        <div className="row-ph-dialog ph-level-row">
                            <p>Автоматический полив</p>
                            <HookSwitch value={monitor.IsOn} unitId={deviceId} name={'IsOn'}/>
                        </div>
                        <div className="row-ph-dialog ph-level-row">
                            <p>Время полива (сек.)</p>
                            <Input value={monitor.waterTime} min={10} max={600}
                                                mode={1} deviceId={deviceId} ckey={'waterTime'}/>
                        </div>
                        
                        <div className="row-ph-dialog ph-level-row">
                            <p>Пауза (мин.)</p>
                            <Input value={monitor.pauseTime} min={10} max={1440}
                                                mode={1} deviceId={deviceId} ckey={'рauseTime'}/>
                        </div>
                    </div>
            }

        </div>
    )
}
