import React from 'react';
import { Button, Form } from 'antd';
import { resq$ } from 'resq';
import logoFull from '../../../resources/images/logo-full.svg';

const SuccessRegister = (props) => {
    const login = props.match.params.login;
    const email = props.match.params.email;

    const [form] = Form.useForm();

    const handleKeyDown = (ev) => {
        if (ev.which == 13) {
            let root = document.getElementById("root");
            const okBtn = resq$('Button', root).byProps({ type: "primary" });
            okBtn.props.onClick(okBtn);
        }
    }


    return <div>
        <div className="header">
            <div className="logotype"><img src={logoFull} alt="Logotype" /></div>
            <hr className="gradient" />
        </div>
        <div className="main">
            <div onKeyDown={handleKeyDown} className={"main"} style={{ marginTop: "-20px !important" }}>
                <Form
                    layout={"vertical"}
                    form={form}
                    name="formSuccess"
                    initialValues={{}}
                >
                    <div className="success-mail">
                        <p className="header-success">Подтверждение завершено</p>
                        <hr id="red" />
                        <p className="p-success">Эл. почта {email} <br /> подтверждена пользователю {login}</p>
                    </div>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" href="/">Войти</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    </div>
}
export default SuccessRegister;