import React from 'react';
import { Form, Input } from 'antd';


const DeviceRegisterForm = (props) => {
    let values = {...props.initialValues};
    const firstInputRef = React.useRef(null);

    React.useEffect(() => {
        setTimeout(() => {
            if (firstInputRef.current) {
                firstInputRef.current.focus({
                    cursor: 'end',
                })
            }
        }, 100);
    }); 


    
    return<Form
        form={props.form}
        layout="vertical"
        name="formDevice"
        onFieldsChange={props.onFieldsChange}
        initialValues={values}>
        <Form.Item
            label="Код регистрации"
            name="pin"
            rules={[
                { 
                  len: 8,  
                  required: true
                }
            ]}>
            <Input maxLength={8} ref={firstInputRef}/>
        </Form.Item>
    </Form>


}

export default DeviceRegisterForm;