import { MSG_NETWORK_ERROR, MSG_NO_ACCESS,LANGUAGE } from './Const';
import { cleanLocalStorage, cleanSessionStorage, setLocalStorage, setSessionStorage } from "./LoginForm";
import {ERROR_MESSAGES} from './resources/Dict';
import {QUERY_RENEW_TOKEN_DEVUSER} from './Query';

// определяется в .env в момент компиляции!
// const BASE_URL = process.env.NODE_ENV == "production" ? process.env.REACT_APP_PROD_ENDPOINT :
//     process.env.REACT_APP_DEV_ENDPOINT;
const BASE_URL = process.env.REACT_APP_ENDPOINT;
/**
 * Методы API должны возвращать http статус UNAUTHORIZED, если токен устарел или неверен
 */
export const HTTP_STATUS_UNAUTHORIZED = 401;

/**
 * Для работы нужно установить токен доступа к API requestToAPI.token = ...
 * Все методы get, post возвращают Promise
 * Обработка ошибок в двух случаях
 * 1: HTTP статус != 200
 * 2. HTTP статус = 200. Ошибка в самом json ответе
 */

const requestToAPI = {
    authToken: undefined,   // token доступа
    /**
     * информация о текущем пользователе. Объект
     * {
     *  name:"фио пользователя",
     *  login:"логин пользователя"
     * }
     */
    user: undefined,
    /**
     * @param {*} query - GraphQL выражение
     * @param {*} data - данные в теле запроса в виде объекта
     * @param {*} config - опции: extResponse - в then передается полный ответ (с заголовками, статусом и т.д), иначе только payload
     * @returns
     */
    post: (query, data, config) => {
        config = config || {};
        return new Promise((resolve, reject) => {
            const options = {
                method: 'post',
                mode: 'cors',
                body: JSON.stringify({
                    query: query,
                    variables: data
                }),
                headers: {
                    'Content-Type': 'application/json',
                    ...(requestToAPI.authToken && {'Authorization':"Bearer "+requestToAPI.authToken})
                },
                credentials: "omit"
            };
            fetch(BASE_URL, options)
                .then(response => {
                    if (!response.ok) {
                        let status = response.status,
                            statusText = response.statusText;

                        const err = new Error(statusText)
                        err.status = status;

                        throw err;
                    } else {
                        if (config.extResponse) {
                            return response;
                        } else {
                            return response.json()
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                    if (error.status) {
                        reject(error);
                    } else {
                        reject({ message: MSG_NETWORK_ERROR })
                    }
                })
                .then(json => {
                    // обработка ошибок для GraphQL
                    if(json && json.errors) {
                        const codeerr = json.errors[0].extensions.code;

                        // проверка на устаревший токен
                        if(codeerr=="token-will-expire-soon" && requestToAPI.authToken ) {
                            return new Promise((resolve, reject) => {
                                requestToAPI.renew(query, data)
                                    .then(response => resolve(response))
                                    .catch(() => {
                                        reject({
                                            message: MSG_NO_ACCESS,
                                            status:HTTP_STATUS_UNAUTHORIZED
                                        })
                                    });
                            })
                        }

                        reject({
                            message: json.errors.map(v=>
                                ERROR_MESSAGES[LANGUAGE][v.extensions.code] || v.message).join(". "),
                            // статус устанавливается для того, чтобы обрабочики оошибки могли
                            // отличить ошибку доступа от всех остальных
                            status: codeerr=="access-denied"?HTTP_STATUS_UNAUTHORIZED:undefined
                        });
                        return;
                    } else {
                        resolve(json);
                    }
                })
        });
    },

    renew: (replayUrl, replayData) => {
        return new Promise((resolve, reject) => {
            requestToAPI.post(QUERY_RENEW_TOKEN_DEVUSER)
                .then(response => {
                    requestToAPI.loginComplete(response.data.devUserPasswdAuth);

                    if (sessionStorage.getItem("authToken")) {
                        setSessionStorage(requestToAPI.authToken, requestToAPI.user);
                    } else {
                        cleanSessionStorage();
                    };

                    if (localStorage.getItem("authToken")) {
                        setLocalStorage(requestToAPI.authToken, requestToAPI.user);
                    } else {
                        cleanLocalStorage();
                    };

                    requestToAPI.post(replayUrl, replayData)
                        .then(response => {
                            resolve(response);
                        })
                        .catch((reason) => {
                            reject(reason);
                        })
                })
                .catch((reason) => {
                    reject(reason);
                })
        })
    },

    loginComplete:(source)=>{
        requestToAPI.authToken = source.authToken;
        requestToAPI.user = {
            id:source.id,
            login: source.userLogin,
            name: source.userLogin,
        };
    }
}

export default requestToAPI;
