import React from 'react';
import { ReactComponent as PhWithArrowsSvg } from '../../../../resources/images/pHWithArrows.svg';
import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';

export const RegulatorRangeButton = ({ handleClick, regulatorRangeTitle }) => {

    return <div
        style={{
            color: '#666',
            fontSize: '55px',
        }}
        onClick={handleClick}
        className='widget-button'
    >
        <Tooltip
            placement="top"
            title={regulatorRangeTitle?.title}
        >
            <Icon component={PhWithArrowsSvg} />
        </Tooltip>
    </div>
}