import React from "react";
import {HookSwitch} from "../../../hooks/HookSwitch";


export const SettingPH = ({unitId, phSensUsedUp, phSensUsedDown}) => {
    return (
        <div className="ph-dialog" draggable="false">
            <div className="row-ph-dialog ph-level-row">
                <p>Датчик уровня pH-</p>
                <HookSwitch name={'phSensUsedDown'} value={phSensUsedDown} unitId={unitId}/>
            </div>
            <div className="row-ph-dialog ph-level-row">
                <p>Датчик уровня pH+</p>
                <HookSwitch name={'phSensUsedUp'} value={phSensUsedUp} unitId={unitId}/>
            </div>
            <div className="row-ph-dialog ph-level-row">

            </div>
        </div>
    )
}
