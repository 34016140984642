import React from 'react';
import { Button, Menu, Dropdown, Form } from 'antd';
import DataTable,{ColumnTypes} from "../../lib/DataTable";
import App from '../../App';
import ModuleHeader from "../../lib/ModuleHeader";
import { FilterPanelExt, Primary } from "../../lib/FilterPanelExt";
import { FilterButton } from '../../lib/FilterButton';
import { withRouter } from "react-router";
import { DEFAULT_TABLE_CONTEXT, EVENT } from "../../lib/Const";
import { MoreOutlined, RedoOutlined } from '@ant-design/icons';
import { drawDateAndTimeIncSec, drawStatus, drawBoolIcon } from "../../lib/Utils";
import EditForm from "../../lib/EditForm";
import { CONTOUR_ADMIN, MODULE_AUDIT } from "../../lib/ModuleConst"
import { buildPrintMenu, buildEntityPrintMenu } from '../../lib/stddialogs/PrintDialog';
import AuditForm from './UserAuditForm';
import { DateInputRange } from '../../lib/DateInput';
import DataLookup from '../../lib/DataLookup';
import moment from 'moment';
import 'moment-duration-format';
import AuditEventSelect from './UserAuditEventSelect';
import { responsiveMobileColumn, isMobile } from '../../lib/Responsive';
import { QUERY_GET_LIST_USER_LOG, QUERY_GET_USER_LOG, MUT_UPD_USER_LOG } from '../../lib/Query';
import Status from '../../lib/Status';
import requestToAPI from "../../lib/Request";


const MOD_TITLE = "Журнал логов";
const MODE_HELP_ID = "/help/audit";
const CONTOUR = CONTOUR_ADMIN;
const MODULE = MODULE_AUDIT;

// URI для использования формой со списком (текущей) и формой добавления/изменения
const URI_FOR_GET_LIST = QUERY_GET_LIST_USER_LOG;
const URI_FOR_GET_ONE = QUERY_GET_USER_LOG;
const URI_FOR_UPDATE = MUT_UPD_USER_LOG;

// позиция в меню
// в subsystem - key верхнего меню
const MNU_SUBSYSTEM = CONTOUR.name;
// в menu - key бокового главного
const MNU_MENU = MODULE.name;
const NAME_MENU = "Аудит";
// в submenu - key бокового подменю (финальный пункт)
// его имя равно имени модуля
const MNU_SUMMENU = MODULE.name + ".sm1";
// const MNU_SUMMENU = "none";
// автоматическое обновление при монтировании компонента
const AUTO_REFRESH = true;


// колонки в таблице
const COLUMNS = [
    {
        title: '',
        dataIndex: 'note.read',
        width: "50px",
        align: 'center',
        render: (data,record)=>{ 
            if (data) {
                return undefined;
            }
            else{
                return <Status color={"#fff"} />;
            }
         }
    },
    {
        title: 'Дата/Время',
        dataIndex: 'createdInSeconds',
        dataType:ColumnTypes.DATETIME,
        sorter: true,
        render:drawDateAndTimeIncSec,
        //renderForFilter:(data)=>moment(data*1000).format("DD.MM.YYYY HH:mm"),     // Если нужна будет фильтрация по дате
        defaultSortOrder: 'descend',
        width:"120px"
    },
    {
        title: 'Тип',
        dataIndex: 'eventType',
        ellipsis: true
    },
    {
        title: 'Прибор',
        dataIndex: 'unit.number',
        ellipsis: true,
        sorter: true
    },
    {
        title: 'Описание прибора',
        dataIndex: 'unit.notes.note',
        ellipsis: true
    },
    {
        title: 'Комментарий',
        dataIndex: 'note.text',
        ellipsis: true
    }
]


const transformerRecord = (r)=>{
    r["createdInSeconds"]=r.createdInSeconds;
    r["eventType"]=MessageType[r.type];
    r["unit.number"]=r.unit?r.unit.number:null;
    r["unit.notes.note"]=r.unit && r.unit.notes ? r.unit.notes[0].note : null;
    r["note.text"]=r.note?r.note.text:null;
    r["note.created"]=r.note?r.note.created:null;
    r["note.read"]= r.note?r.note.read:null;
    //delete r.ident;
}

const MessageType= {
    'unit.alarm.fault':'Авария',
    'unit.alarm.recovery':'Восстановление после аварии',
    'unit.control':'Управление атрибутом прибора',
    'generic':'Общий тип сообщения',
    'auth.login.passwd':'Вход по паролю'
}

const transformerData = (data)=>{
    data.forEach(r => 
        transformerRecord(r)
    );
    return data;
}


// Уникальный идентификатор формы редактировавания
const EDIT_FORM_ID = "audit-frm";
// Форма для редактирования
const buildForm = (form) => {
    return <AuditForm form={form} initialValues={{}} />
}
// размер формы, -1 - по умолчанию, FORM_MAX_WIDTH - максимальная ширина
const FORM_WIDTH = isMobile() ? -1 : "50%";

// Создание компонент для фильтров
// key это уникальное имя фильтра, попадает в REST API
const buildFilters = () => {
    return <React.Fragment>
        <Primary>
            <DateInputRange key="createdInSeconds" allowClear={false} />
        </Primary>
        {/* <span>События:</span>
        <AuditEventSelect key="typeId" style={{ width: 480 }} />  */}
    </React.Fragment>
}
// начальное значение фильтров
// если значение фильра не объект, а простое значение, 
// то имя свойства компонента принимается defaultValue
const initFilters = {
     createdInSeconds: [moment().startOf('month'), moment().endOf('month')]
}

// дополнительные команды
// если меню нет, то и кнопки нет
/*
const buildMenuCommand = (config, handleMenuClick) => {
    return <Menu onClick={handleMenuClick}>
        {buildPrintMenu(MODULE.name, config)}
    </Menu>
};

// обрабочик меню
const buildMenuHandler = (config) => {
    return (ev) => {
    }
}

// меню записи

const recordMenu = (config, record) => (
    <React.Fragment>
        {buildEntityPrintMenu(ENTITY, record, config)}
    </React.Fragment>
)
*/
//===============================================================================
// Основной функциональный компонент
//===============================================================================
/**
 * Таблица передает на сервер post-запрос в теле которого 
 * pagination - информация о странице
 * sort - сортировка
 * filters - фильтры (+ быстрые фильтры начинаются с quick.*)
 * search - строка полнотекстового поиска
 */
const Audit = (props) => {
    let [formVisible, setFormVisible] = React.useState(false);
    const [topLayer, setTopLayer] = React.useState([]);
    let [editorContext] = React.useState({
        //uriForGetOne: URI_FOR_GET_ONE,
        uriForGetOne: (id) => {
            return requestToAPI.post(URI_FOR_GET_ONE, {id:id})
                .then(result => { 
                if(!result.data.record.note){
                    result.data.record.note = {};
                }
                if(!result.data.record.note.read){
                    result.data.record.note.read = new Date().toISOString();
                        requestToAPI.post(URI_FOR_UPDATE, {id:id, note: result.data.record.note})
                            .then(
                                response => afterEdit(response)
                            );
                    }
                return result;
            })},
        uriForUpdate: URI_FOR_UPDATE
    });
    const [tableInterface] = React.useState(Object.assign({}, DEFAULT_TABLE_CONTEXT));
    const [form] = Form.useForm();
    const [, forceUpdate] = React.useReducer(x => x + 1, 0);
    const [updateRecords, setUpdateRecords] = React.useState([]);

    /*
    const menuCommand = buildMenuCommand({ form: form, forceUpdateModule: forceUpdate }, buildMenuHandler({
        topLayer,
        setTopLayer,
        form,
        tableInterface,
        destroyDialog: (dlgId) => {
            setTopLayer([...topLayer.filter(c => c.props.id != dlgId)]);
        }
    }));
    */


    const setFilters = React.useCallback((config) => {
        tableInterface.requestParams.filters = config;
        tableInterface.refreshData();
    }, [tableInterface])


    const callForm = React.useCallback((id) => {
        editorContext.id = id;
        setFormVisible(true);
    }, [editorContext])

    // тут формируются кнопки
    const buttons = [
        // <Button key="refresh" onClick={() => tableInterface.refreshData()}>{BUTTON_REFRESH_LABEL}</Button>,
        <Button key="refresh" onClick={() => tableInterface.refreshData()} icon={<RedoOutlined />}/>
    ];
    /*
    if (menuCommand) {
        buttons.push(<Dropdown.Button key="more"
            className="more-dropbutton"
            trigger="click"
            overlay={menuCommand} icon={<MoreOutlined />} />);
    }
    */
    if (isMobile()) {
        const filters = buildFilters();
        buttons.push(<FilterButton key="filter" filters={filters}
            onChange={(fc) => setFilters(fc)}
            initValues={initFilters} />);
    }

    const afterEdit = React.useCallback((values) => {
      //values.data.record = { ...values.data.record.unit }
      const flatRecord = values.data.record;
      transformerRecord(flatRecord);
      tableInterface.updateRecord(flatRecord);
      setUpdateRecords([...updateRecords, flatRecord])
    }, [tableInterface, updateRecords])
    const afterAdd = React.useCallback((values) => {
      values.data.record = { ...values.data.record.unit }
      const flatRecord = values.data.record;
      transformerRecord(flatRecord);
      tableInterface.insFirstRecord(flatRecord);
      setUpdateRecords([...updateRecords, flatRecord])
    }, [tableInterface, updateRecords])

    return (
        <App subsystem={MNU_SUBSYSTEM} menu={MNU_MENU} submenu={MNU_SUMMENU}
            breadcrumb={[{ label: NAME_MENU }, { label: MOD_TITLE }]}
            afterLogin={forceUpdate}
            buttons={buttons}
            helpId={MODE_HELP_ID}>
            <ModuleHeader
                title={MOD_TITLE}
                onSearch={value => {
                    tableInterface.requestParams.search = value ? value : undefined;
                    tableInterface.refreshData();
                }}
                buttons={buttons}
            />
            <FilterPanelExt onChange={(fc) => setFilters(fc)} initValues={initFilters}>
                {buildFilters()}
            </FilterPanelExt>
            <DataTable className="mod-main-table"
                uri={{
                    forSelect: URI_FOR_GET_LIST
                }}
                columns={COLUMNS}
                exportNameFile='audit'
                defaultFilters={initFilters}
                autoRefresh={AUTO_REFRESH}
                editCallBack={(record) => callForm(record.id)}
                interface={tableInterface}
                onSelectedChange={() => forceUpdate()}
                onAfterRefresh={() => setUpdateRecords([])}
                updateRecords={updateRecords}
                selectable={false}
                /*
                recordMenu={(record) => recordMenu({
                    topLayer,
                    setTopLayer,
                    form,
                    tableInterface,
                    idName: ENTITY.charAt(0).toLowerCase() + ENTITY.slice(1) + "Id",
                    'destroyDialog': (dlgId) => {
                        // нужно через timeout так как после вызова destroyDialog следуют обращения к state
                        setTimeout(() => { setTopLayer([...topLayer.filter(c => c.props.id != dlgId)]) }, 100)
                    }
                }, record)}
                */
                transformerData={transformerData}
            />
            <EditForm
                id={EDIT_FORM_ID}
                title={"Запись лога"}
                visible={formVisible}
                form={form}
                width={FORM_WIDTH}
                editorContext={editorContext}
                afterSave={(response) => {
                  setFormVisible(false);
                  if (response) {
                    if (!editorContext.id) {
                      afterAdd(response)
                    } else {
                      afterEdit(response)
                    }
                  }
                }}
                afterCancel={() => {
                    setFormVisible(false);
                }}
                idName="id"
            >
                {buildForm(form)}
            </EditForm>
            {topLayer.map(item => item)}
        </App>
    )
}
export default withRouter(Audit);